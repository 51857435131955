<template>
  <DashboardLayout>
    <div class="mt-5 container">
      <div class="card ml-auto bg-success">
        <div class="card-body text-white">
          <h3><i class="fas fa-check-circle"></i> Payment successful.</h3>
          <p>
            You will receive Tamil Data Decentralization in your wallet shortly. You will also
            receive an email notification upon successful transfer.
          </p>
          <p>Payment ID: {{ sessionId }} <br /></p>

          <br />
          <p>
            Please email if you encounter any issues.
            <a
              href="mailto:support@TamilData.org"
              target="_blank"
              class="no-decoration"
            >
              support@TamilData.org
            </a>
          </p>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

export default {
  name: "CoinPaymentSuccess",
  data: () => ({
    sessionId: "",
    email: "",
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    this.sessionId = this.$route.query.session_id;

    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
    }

    this.getPaymentInfo();
  },
  methods: {
    getPaymentInfo() {
      let loader = this.$loading.show();

      var sessionId = this.$route.query.session_id;

      const dataBody = {
        sessionId: sessionId,
        email: this.email,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .post(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/credit-card",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
<style scoped>
.text-white {
  color: aliceblue;
}
.text-center {
  align-items: center;
  text-align: center;
}
.no-decoration {
  text-decoration: none;
  color: rgb(184, 204, 224);
}

.bg-success {
  background-color: #0f9d58 !important;
}
</style>
