<template>
  <DashboardLayout>
    <div class="container">
      <div class="alert alert-secondary ml-5 mr-5" role="alert">
        {{ walletAddress }}
      </div>
    </div>
    <div class="row">
      <div
        class="col-sm-12 col-md-4 col-lg-4 m-2"
        v-for="(job, index) in acceptJobs"
        :key="index"
      >
        <div class="card">
          <div class="card-body">
            <span class="jobTitle">
              <strong>Job Title</strong>
              <br />
              {{ job.jobName }}
            </span>
            <hr />
            <span class="jobDueDate">
              <strong>Due Date</strong>
              <br />
              {{ job.dueDate }}
            </span>
            <br />
            <br />
            <button
              class="btn btn-success btn-block"
              v-if="job.jobStatus == 'p'"
              @click="completeAction(job.id, 'c')"
            >
              Complete
            </button>
            <button class="btn btn-success btn-block" v-else disabled>
              Completed
            </button>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

export default {
  name: "view-applied-jobs",
  data() {
    return {
      email: "",
      allAppiledJobs: [],
      acceptJobs: [],
      jobs: [],
      walletAddress: "",
    };
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getAllAppiledJob();
      this.getAllJobs();
      this.getWalletInfo();
    }
  },
  components: {
    DashboardLayout,
  },
  methods: {
    getAllJobs() {
      let loader = this.$loading.show();
      // GET request using axios with async/await
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs/?postedBy=all",
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.jobs = response.data;
        })
        .catch(function(error) {
          //console.log(error);
          loader.hide();
        })
        .finally(() => {
          this.syncJobs();
          loader.hide();
        });
    },
    getAllAppiledJob() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs/job-applications/?jobId=all",
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.allAppiledJobs = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          this.syncJobs();
          loader.hide();
        });
    },
    syncJobs() {
      const jobs = this.jobs;
      const appliedJobs = this.allAppiledJobs;
      for (let i = 0; i < appliedJobs.length; i++) {
        var selectedJobId = appliedJobs[i]["jobId"];
        $.each(jobs, function(index, item) {
          var jobId = item["id"];
          if (selectedJobId == jobId) {
            appliedJobs[i]["jobName"] = item["jobTitle"];
            appliedJobs[i]["dueDate"] = item["dueDate"];
          }
        });
      }
      this.allAppiledJobs = appliedJobs;
      this.filteredJobs();
    },
    filteredJobs() {
      const allAppliedJobs = this.allAppiledJobs;
      var email = this.email;
      const acceptJobs = Array();
      $.each(allAppliedJobs, function(index, item) {
        var appliedEmail = item["email"];
        var acceptStatus = item["acceptStatus"];
        if (email == appliedEmail && acceptStatus == "y") {
          acceptJobs.push(item);
        }
      });
      //console.log(acceptJobs);
      this.acceptJobs = acceptJobs;
    },
    completeAction(id) {
      let loader = this.$loading.show();
      var jobStatus = this.email + "|" + this.walletAddress;
      const dataBody = {
        jobApplicationId: id,
        jobStatus: jobStatus,
      };
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      const dataBodyJson = JSON.stringify(dataBody);
      //console.log(dataBodyJson);
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/job-completion-status",
          dataBodyJson,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.getAllAppiledJob();
          loader.hide();
        });
    },
    getWalletInfo() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // GET wallet info using axios
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.walletAddress = response.data[0].publicKey;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.card {
  height: 100%;
}
</style>
