<template>
  <DashboardLayout>
    <div class="container">
      <h2 class="mb-3">
        Use this function to invite users to contribute to Tamil language
        enrichment and development
      </h2>

      <div class="text-left mb-2">
        <button
          class="btn btn-secondary"
          data-toggle="modal"
          data-target="#addInvitationModal"
        >
          Create Invitation
        </button>
      </div>
      <div class="card">
        <div class="card-body table-responsive">
          <table class="table table-hovarable" id="tblInvitation">
            <thead>
              <tr>
                <th>Sent Date Time</th>
                <th>Email</th>
                <th>Contribution Type</th>
                <th>Country</th>
                <th>Invite Message</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(invitation, index) in allInvitation" :key="index">
                <td>{{ invitation.inviteDate }}</td>
                <td>{{ invitation.email }}</td>
                <td>{{ invitation.contributionType }}</td>
                <td>{{ invitation.country }}</td>
                <td>{{ invitation.inviteMessage }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="addInvitationModal"
      tabindex="-1"
      aria-labelledby="addInvitationModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Invitation</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-left">
            <div class="form-group">
              <label for="exampleInputEmail1">Email :</label>
              <input
                type="email"
                class="form-control"
                aria-describedby="Email"
                v-model="invitationDetails.email"
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">Contribution Type :</label>
              <select
                class="form-select"
                aria-describedby="Contribution Type"
                v-model="invitationDetails.contributionType"
              >
                <option value="" hidden>Choose Contribution Type</option>
                <option value="Contributor">Contributor</option>
                <option value="Possible Partnership">
                  Possible Partnership
                </option>
                <option value="Developer">Developer</option>
                <option value="Social Media Influencer">
                  Social Media Influencer
                </option>
                <option value="Tamil Media">Tamil Media</option>
                <option value="Youtuber">Youtuber</option>
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">Country :</label>
              <input
                type="text"
                class="form-control"
                aria-describedby="Country"
                v-model="invitationDetails.country"
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">Social Media Links :</label>
              <textarea
                class="form-control"
                aria-describedby="Social Media Links"
                v-model="invitationDetails.socialMediaLinks"
              ></textarea>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">Message :</label>
              <textarea
                class="form-control"
                aria-describedby="Message"
                v-model="invitationDetails.inviteMessage"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="btnCloseModal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="createInvitation"
            >
              Send Invitation
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "accounts",
  data: () => ({
    email: "",
    allInvitation: [],
    invitationDetails: {
      email: "",
      contributionType: "",
      country: "",
      socialMediaLinks: "",
      inviteMessage: "",
    },
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    this.email = localStorage.email;

    this.getAllInvitation();
  },
  methods: {
    createInvitation() {
      const invitationDetails = this.invitationDetails;

      //Validation
      if (invitationDetails.email == "") {
        Swal.fire({
          title: "Warning",
          text: "Email is must needed.",
          icon: "error",
        });

        return false;
      }

      if (invitationDetails.contributionType == "") {
        Swal.fire({
          title: "Warning",
          text: "Contribution Type is must needed.",
          icon: "error",
        });

        return false;
      }

      if (invitationDetails.country == "") {
        Swal.fire({
          title: "Warning",
          text: "Country is must needed.",
          icon: "error",
        });

        return false;
      }

      if (invitationDetails.inviteMessage == "") {
        Swal.fire({
          title: "Warning",
          text: "Invite Message is must needed.",
          icon: "error",
        });

        return false;
      }
      //Validation

      $("#btnCloseModal").trigger("click");

      var refUrl = "https://app.TamilData.org/ref/" + window.btoa(this.email);

      var today = new Date();
      invitationDetails.inviteDate = today.toLocaleDateString("en-US");
      invitationDetails.invitedByEmail = this.email;
      invitationDetails.refUrl = refUrl;

      let loader = this.$loading.show();

      const dataBody = invitationDetails;
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .post(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/invite",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);

          Swal.fire({
            title: "Success",
            icon: "success",
          }).then((result) => {
            let loader = this.$loading.show();

            location.reload();
          });
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getAllInvitation() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/invite/?invitedBy=all",
          {
            headers: headers,
          }
        )
        .then((response) => {
          this.allInvitation = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          $("#tblInvitation").DataTable();
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
#tblInvitation {
  width: 100%;
}
#tblInvitation td,
th {
  vertical-align: middle;
  text-align: left;
}
</style>
