<template>
  <DashboardLayout>
    <div class="container">
      <h2 class="mb-3">Tamil Crypto Data Decentralization Node status</h2>
      <div class="row justify-content-center">
        <div class="col-12 col-md-6">
          <div class="card" v-if="nodeData.node == 1">
            <div class="card-body">
              <h1 class="display-1 text-success">
                <i class="fas fa-check-circle"></i>
              </h1>
              <h2>Node Information</h2>
              <hr />
              <p><strong>Register Date:</strong> {{ nodeData.registerDate }}</p>
              <p><strong>Number of Files:</strong> {{ nodeData.fileCount }}</p>
              <p>
                <strong>Total File Size:</strong>
                {{ nodeData.totalFileSize }} MB
              </p>
              <p><strong>Total Up Time:</strong> {{ nodeData.totalUpTime }}</p>
            </div>
          </div>
          <div class="card" v-else>
            <div class="card-body">
              <h1 class="display-1 text-danger">
                <i class="fas fa-times-circle"></i>
              </h1>
              <h2>Node information not found.</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

export default {
  name: "accounts",
  data: () => ({
    email: "",
    nodeData: {},
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    this.email = localStorage.email;

    this.nodeStatus();
  },
  methods: {
    nodeStatus() {
      let loader = this.$loading.show();
      var email = this.email;
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      //email = "kavashgar@gmail.com";
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/node-stats/?email=" +
            email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          const nodeData = response.data;

          if (nodeData.length > 0) {
            const node = nodeData[0];

            var fileCount = node.numFiles;
            var registerDate = node.registerDate;
            var totalFileSize = bytesToMegabytes(node.totalFileSize);
            var totalUpTime = minutesToHours(node.totalUpTime);

            this.nodeData = {
              registerDate: registerDate,
              fileCount: fileCount,
              totalFileSize: totalFileSize,
              totalUpTime: totalUpTime,
              node: 1,
            };
          } else {
            this.nodeData = {
              registerDate: "",
              fileCount: 0,
              totalFileSize: 0,
              totalUpTime: 0,
              node: 0,
            };
          }
        })
        .catch(function(error) {
          //console.log(error);
          this.nodeData = {
            registerDate: "",
            fileCount: 0,
            totalFileSize: 0,
            totalUpTime: 0,
            node: 0,
          };
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};

function bytesToMegabytes(bytes) {
  return (bytes / (1024 * 1024)).toFixed(2);
}

function minutesToHours(minutes) {
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = minutes % 60;

  if (hours > 0) {
    return hours + " Hrs " + remainingMinutes.toFixed() + " Min";
  } else {
    return remainingMinutes.toFixed() + " Min";
  }
}
</script>
