<template>
  <DashboardLayout>
    <div class="row mt-4">
      <!--Col-1-->
      <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <div class="card text-left">
          <div class="card-header">
            <img
              :src="jobOwnerPicture"
              id="job-owner-pic"
              alt="Profile Picture"
            />
            &nbsp;
            <h5 style="display: inline">{{ jobOwnerName }}</h5>
          </div>
          <div class="card-body">
            <span class="jobBodyText">
              வேலை தலைப்பு <br />
              Job Title
            </span>
            <p class="jobDesc">
              {{ jobTitle }}
            </p>

            <hr />

            <span class="jobBodyText">
              வேலை விவரம் <br />
              Job Description
            </span>
            <p class="jobDesc">
              {{ jobDesc }}
            </p>

            <hr />

            <span class="jobBodyText">
              விதிமுறைகளும் நிபந்தனைகளும் <br />
              Terms & Conditions
            </span>
            <p class="jobTerms">
              {{ jobTerms }}
            </p>
          </div>
        </div>
      </div>
      <!--Col-1-->

      <!--Col-2-->
      <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <div class="card text-left bg-warning-light-nope">
          <div class="card-header">
            <span class="f-12">Reward :</span>
            <span class="f-12">{{ jobReward }}</span>
            <img src="@/assets/logo.png" alt="TAMIL" width="25" />
          </div>
          <div class="card-body">
            <!--Carousel-->
            <Carousel>
              <Slide v-for="image in jobImages" :key="image.id">
                <div class="carousel__item">
                  <img :src="image.path" class="d-block w-100" />
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
            <!--Carousel-->

            <p class="pt-2">
              <strong>Delivery Date : </strong> {{ jobDueDate }}
            </p>

            <p class="pt-2">
              <strong>Wallet Address : </strong>
            </p>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                id="txtWalletAddress"
                v-model="walletAddress"
                readonly
              />
              <a class="input-group-text" id="btnWalletAddCopy">Copy</a>
            </div>
          </div>
          <div class="card-footer">
            <!--Success Msg-->
            <div v-show="successMsg" class="mb-3 mt-2">
              <div class="alert alert-success fade show" role="alert">
                <i class="fas fa-check-circle"></i> &nbsp;
                <strong>வெற்றி!</strong> வேலை விண்ணப்பம் சமர்ப்பிக்கப்பட்டது.
                <br />
                <br />

                <i class="fas fa-check-circle"></i> &nbsp;
                <strong>Success!</strong> job application has been submitted.
              </div>
            </div>
            <!--Success Msg-->

            <button
              class="btn btn-block btn-danger"
              @click="applyJob"
              v-if="applied === false"
            >
              Apply
            </button>
            <button class="btn btn-block btn-danger" v-else disabled>
              Applied
            </button>
            <a
              :href="'https://t.me/' + telegramUserName"
              class="btn btn-block btn-warning"
              target="_blank"
            >
              Chat
            </a>
          </div>
        </div>
      </div>
      <!--Col-2-->
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";
import defaultProfilePic from "../assets/images/sample-profile.png";
import defaultJobPic from "../assets/images/job-pic.jpeg";

import { Carousel, Slide, Navigation } from "vue3-carousel";
import $ from "jquery";

export default {
  name: "upload-media",
  data() {
    return {
      email: "",
      updateMsg: false,
      successMsg: false,
      jobImages: [],
      jobId: "",
      jobTitle: "",
      jobDesc: "",
      jobTerms: "",
      jobDueDate: "",
      jobReward: "",
      jobOwnerEmail: "",
      jobOwnerPicture: defaultProfilePic,
      jobOwnerName: "",
      walletAddress: "",
      defaultJobPic: defaultJobPic,
      telegramUserName: "",
      applied: false,
      allAppiledJobs: [],
    };
  },
  components: {
    DashboardLayout,
    Carousel,
    Slide,
    Navigation,
  },
  mounted() {
    //Get Query Details
    var query = this.$route.query;
    this.jobId = query.jobId;
    //console.log(query);
    //Get Query Details

    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getAllAppiledJob();
      this.getJobInfo();
    }
  },
  methods: {
    getJobInfo() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      // GET request using axios with async/await
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs/job-details/?jobId=" +
            this.jobId,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.jobTitle = response.data[0].jobTitle;
          this.jobDesc = response.data[0].jobDescription;
          this.jobTerms = response.data[0].terms;
          this.jobDueDate = response.data[0].dueDate;
          this.jobReward = response.data[0].reward;
          this.jobOwnerEmail = response.data[0].postedBy;
          //img array load
          const arrayLength = response.data.length;
          for (let i = 1; i < arrayLength; i++) {
            this.jobImages.push(response.data[i]);
          }
          //console.log(this.jobImages);
          //img array load
          this.getUserInfo();
          this.getWalletInfo();
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getUserInfo() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/update-profile/?email=" +
            this.jobOwnerEmail,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.jobOwnerName = response.data[0].fullName;
          this.telegramUserName = response.data[0].telegramUserName;

          //Check Have Upload Profile Picture
          if (
            response.data[0].profileImage &&
            response.data[0].profileImage != "-"
          ) {
            this.jobOwnerPicture = response.data[0].profileImage;
          }
          //Check Have Upload Profile Picture
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getWalletInfo() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // GET wallet info using axios
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet/?email=" +
            this.jobOwnerEmail,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.walletAddress = response.data[0].publicKey;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    applyJob() {
      if (localStorage.email === undefined) {
        this.$router.push("/login");
      } else {
        this.email = localStorage.email;
        let loader = this.$loading.show();
        var today = new Date();
        const dataBody = {
          registerDate: today.toLocaleDateString("en-US"),
          email: this.email,
          jobId: this.jobId,
        };
        const headers = {
          Authorization: "Bearer " + localStorage.token,
        };
        // using JSON.stringify( to convert dataBody object to json
        axios
          .post(
            " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs/job-applications",
            dataBody,
            {
              headers: headers,
            }
          )
          .then((response) => {
            //console.log(response);
            this.successMsg = true;
          })
          .catch((error) => {
            //console.log(error);
            this.successMsg = false;
          })
          .finally(() => {
            this.getAllAppiledJob();
            loader.hide();
          });
      }
    },
    getAllAppiledJob() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs/job-applications/?jobId=all",
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.allAppiledJobs = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          this.jobSync();
          loader.hide();
        });
    },
    jobSync() {
      const jobId = this.jobId;
      const email = this.email;
      const appliedJobs = this.allAppiledJobs;
      var inline = this;
      $.each(appliedJobs, function(index, item) {
        var appliedEmail = item["email"];
        var appliedJobId = item["jobId"];
        if (appliedEmail == email && appliedJobId == jobId) {
          inline.applied = true;
        }
      });
    },
  },
};

$("body").on("click", "#btnWalletAddCopy", function() {
  console.log("copy");
  $("#txtWalletAddress").select();
  document.execCommand("copy");
});
</script>

<style scoped>
#job-owner-pic {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid #ddd;
  padding: 5px;
}
.text-left {
  text-align: left;
}
#btnWalletAddCopy {
  cursor: pointer;
}
.bg-warning-light {
  background-color: #f39071;
}
.input-group-text {
  background-color: rgb(172, 172, 158);
}
.f-12 {
  font-weight: bold;
  font-size: large;
}
.jobBodyText {
  font-weight: bold;
}
</style>
