<template>
  <DashboardLayout>
    <div class="mt-5">
      <p>
        உங்கள் சேமிப்பானை செயற்படுத்துவதற்கு ஆக குறைந்தது 2 XLM இருத்தல்
        வேண்டும் . கீழுள்ள காணொளியில் எப்படி XLM வாங்குவது மற்றும் எப்படி
        செயற்படுத்துவது பற்றிய விளக்கம் கொடுக்கப்பட்டுள்ளது.
      </p>
      <p>
        You must have at least 2 XLM in your wallet to activate. Below video
        explains activation steps.
      </p>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          class="embed-responsive-item"
          src="https://www.youtube.com/embed/VTdsmTQ-FJw?rel=0"
          allowfullscreen
        >
        </iframe>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
export default {
  name: "active-wallet",
  data: () => ({
    email: "",
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
    }
  },
};
</script>
