<template>
  <!--Topbar-->
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 shadow">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown no-arrow d-none d-md-block">
        <router-link class="nav-link dropdown-toggle" to="/message">
          <i class="fas fa-comment-dots text-warning fa-lg"></i>
        </router-link>
      </li>

      <div class="topbar-divider d-none d-md-block"></div>

      <li class="nav-item dropdown no-arrow d-none d-md-block">
        <router-link class="nav-link dropdown-toggle" to="/profile">
          <span class="mr-2 d-none d-lg-inline text-gray-600 small text-center">
            {{ fullName }}
          </span>
          <i class="fas fa-user text-warning fa-lg"></i>
        </router-link>
      </li>

      <div class="topbar-divider d-none d-md-block"></div>

      <li class="nav-item dropdown no-arrow d-none d-md-block">
        <a
          class="nav-link dropdown-toggle"
          href="https://classroom.google.com/c/NjkyNjYxNzAyOTIy?cjc=skjtxlk"
          target="_blank"
        >
          <i class="fas fa-graduation-cap text-warning fa-lg"></i>
        </a>
      </li>

      <div class="topbar-divider d-none d-md-block"></div>

      <li class="nav-item dropdown no-arrow d-none d-md-block">
        <router-link
          class="btn btn-warning mt-3 text-white"
          to="/download-node"
        >
          Download
          <i class="fas fa-cloud-download-alt"></i>
        </router-link>
      </li>

      <div class="topbar-divider d-none d-md-block"></div>

      <li class="nav-item dropdown no-arrow d-none d-md-block">
        <router-link class="btn btn-warning mt-3 text-white" to="/my-rewards">
          My Rewards
          <i class="fas fa-gifts"></i>
        </router-link>
      </li>

      <!--
      <div class="topbar-divider d-none d-sm-block"></div>

      <li class="nav-item dropdown no-arrow">
        <router-link class="btn btn-warning mt-3 text-white" to="/buy-coin">
          Buy Crypto
          <i class="fa fa-shopping-cart"></i>
        </router-link>
      </li>
      -->

      <div class="topbar-divider d-none d-md-block"></div>

      <!-- Nav Item - Logout-->
      <li class="nav-item dropdown no-arrow">
        <button
          class="btn btn-warning mt-md-3 text-white"
          @click="signOut"
          id="btnLogout"
        >
          Log Out
          <i class="fas fa-sign-out-alt"></i>
        </button>
      </li>
    </ul>
  </nav>
  <!--Topbar-->
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "TopBar",
  data() {
    return {
      fullName: "",
    };
  },
  mounted() {
    this.fullName = localStorage.fullName;
  },
  methods: {
    async signOut() {
      try {
        await Auth.signOut();
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExp");
        localStorage.removeItem("fullName");
        localStorage.removeItem("mobile");
        localStorage.removeItem("telegram");

        this.$router.push("/login");
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
  },
};
</script>
