import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import "nprogress/nprogress.css";

import axios from "axios";
import { Auth } from "aws-amplify";

import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";

Amplify.configure(aws_exports);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

let app = createApp(App)
  .use(VueLoading)
  .use(store)
  .use(router);

app.mount("#app");

axios.defaults.headers.common["Content-Type"] = "application/json";
