<template>
  <DashboardLayout>
    <div class="container">
      <div class="row">
        <!-- Col 1 -->
        <div class="col-sm-12 col-md-6">
          <div class="card mb-2">
            <div class="card-header">
              <h5>Apply to join our exclusive network of influencers:</h5>
              Social media influencers can create a profile through this form in
              order to be contacted when promotion opportunities arise.
            </div>
            <div class="card-body">
              <input type="hidden" v-model="affiliateId" />

              <div class="form-floating mb-3">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="selectedPlatform"
                >
                  <option
                    v-for="platform in platforms"
                    v-bind:key="platform.id"
                    :value="platform.name"
                  >
                    {{ platform.name }}
                  </option>
                </select>
                <label for="Social Media Platform">Social Media Platform</label>
              </div>

              <div class="form-floating mb-3">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="selectedGender"
                >
                  <option
                    v-for="gender in genders"
                    v-bind:key="gender.id"
                    :value="gender.name"
                  >
                    {{ gender.name }}
                  </option>
                </select>
                <label for="Gender">Gender</label>
              </div>

              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="profileLink"
                  v-model="profileLink"
                  placeholder="Enter Profile Link..."
                />
                <label for="profileLink">Profile Link</label>
              </div>

              <div class="form-floating mb-3">
                <input
                  type="number"
                  class="form-control"
                  id="noOfFollowers"
                  v-model="noOfFollowers"
                  placeholder="Enter Number Of Followers..."
                />
                <label for="noOfFollowers"> Number Of Followers </label>
              </div>

              <div class="form-floating mb-3">
                <textarea
                  style="height: 100px"
                  class="form-control"
                  id="describe"
                  v-model="describe"
                  placeholder="Enter describe..."
                ></textarea>
                <label for="describe"
                  >Describe in few words how it can help TamilData.org</label
                >
              </div>

              <!--Success Msg-->
              <div v-show="updateMsg" class="mb-3">
                <div class="alert alert-success fade show" role="alert">
                  <i class="fas fa-check-circle"></i> &nbsp;
                  <strong>வெற்றி!</strong> சமூக ஊடக சுயவிவரம் சேர்க்கப்பட்டது.

                  <br />
                  <br />

                  <i class="fas fa-check-circle"></i> &nbsp;
                  <strong>Success!</strong> Social media profile has been added.
                </div>
              </div>
              <!--Success Msg-->

              <div class="mb-3" v-show="!updateBtn">
                <button
                  class="btn btn-danger btn-block"
                  type="button"
                  @click="addProfile"
                >
                  Add
                </button>
              </div>

              <div class="mb-3" v-show="updateBtn">
                <button
                  class="btn btn-danger btn-block"
                  type="button"
                  @click="updateProfile"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Col 1 -->

        <!-- Col 2 -->
        <div class="col-sm-12 col-md-6">
          <div class="card mb-2">
            <div class="card-header">
              <strong>My Social Profiles</strong>
              &nbsp;
              <a
                href="https://drive.google.com/drive/folders/1xFMg_VNWS8rk6UR4DViwYgcQvsbFfyyc"
                target="_blank"
                class="btn btn-danger btn-sm"
              >
                <i class="fas fa-images"></i> Media Kit
              </a>
            </div>
            <div class="card-body">
              <div class="row">
                <div
                  v-for="socialProfile in socialProfiles"
                  :key="socialProfile.postedBy"
                  class="col-sm-12 col-md-6 col-lg-6 col-xl-6"
                >
                  <div class="card bg-light card-border mb-2">
                    <div class="card-header">
                      <span class="text-bold job-title">
                        {{ socialProfile.socialMedia }}
                      </span>
                    </div>
                    <div class="card-body">
                      <span class="text-bold">Description</span>
                      <p class="card-text">{{ socialProfile.profileDesc }}</p>

                      <span class="text-bold">Gender</span>
                      <p class="card-text">{{ socialProfile.gender }}</p>

                      <span class="text-bold">Number Of Follwers</span>
                      <p class="card-text">{{ socialProfile.numFollowers }}</p>

                      <span class="text-bold">Social Link</span>
                      <p class="card-text">{{ socialProfile.socialLink }}</p>
                    </div>
                    <div class="card-footer">
                      <button
                        class="btn btn-sm btn-danger mt-1"
                        @click="
                          editAffiliateInfo(
                            socialProfile.id,
                            socialProfile.socialMedia,
                            socialProfile.profileDesc,
                            socialProfile.gender,
                            socialProfile.numFollowers,
                            socialProfile.socialLink
                          )
                        "
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Col 2 -->
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//import { Auth } from "aws-amplify";

export default {
  name: "affiliate-register",
  data() {
    return {
      email: "",
      updateMsg: false,
      describe: "",
      affiliateId: "",
      noOfFollowers: "",
      profileLink: "",
      selectedPlatform: "Facebook",
      platforms: [
        { id: 1, name: "Facebook" },
        { id: 2, name: "Twitter" },
        { id: 3, name: "Instagram" },
      ],
      selectedGender: "Male",
      genders: [
        { id: 1, name: "Male" },
        { id: 2, name: "Female" },
      ],
      socialProfiles: [],
      updateBtn: false,
    };
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getSocialProfiles();
    }
  },
  components: {
    DashboardLayout,
  },
  methods: {
    addProfile: function() {
      let loader = this.$loading.show();
      var today = new Date();

      const dataBody = {
        registerDate: today.toLocaleDateString("en-US"),
        email: this.email,
        socialMedia: this.selectedPlatform,
        gender: this.selectedGender,
        socialLink: this.profileLink,
        numFollowers: this.noOfFollowers,
        profileDesc: this.describe,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // using JSON.stringify( to convert dataBody object to json
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/affiliate",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.updateMsg = true;

          this.describe = "";
          this.noOfFollowers = "";
          this.profileLink = "";
          this.selectedGender = "Male";
          this.selectedPlatform = "Facebook";
        })
        .catch((error) => {
          //console.log(error);
          this.updateMsg = false;
        })
        .finally(() => {
          loader.hide();
          this.getSocialProfiles();
        });
    },
    getSocialProfiles() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // GET request using axios with async/await
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/affiliate/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          this.socialProfiles = response.data;
          //console.log(response.data);
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    editAffiliateInfo(id, soMedia, proDesc, gender, noFollowers, soLink) {
      this.affiliateId = id;
      this.describe = proDesc;
      this.noOfFollowers = noFollowers;
      this.profileLink = soLink;
      this.selectedGender = gender;
      this.selectedPlatform = soMedia;
      this.updateBtn = true;
    },
    updateProfile() {
      let loader = this.$loading.show();
      var today = new Date();

      const dataBody = {
        id: this.affiliateId,
        registerDate: today.toLocaleDateString("en-US"),
        email: this.email,
        socialMedia: this.selectedPlatform,
        gender: this.selectedGender,
        socialLink: this.profileLink,
        numFollowers: this.noOfFollowers,
        profileDesc: this.describe,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // using JSON.stringify( to convert dataBody object to json
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/affiliate/update-affiliate",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.updateMsg = true;

          this.describe = "";
          this.noOfFollowers = "";
          this.profileLink = "";
          this.selectedGender = "Male";
          this.selectedPlatform = "Facebook";
          this.updateBtn = false;
        })
        .catch((error) => {
          //console.log(error);
          this.updateMsg = false;
        })
        .finally(() => {
          loader.hide();
          this.getSocialProfiles();
        });
    },
  },
};
</script>

<style scoped>
.text-bold {
  font-weight: bold;
  font-size: 15px;
}
.job-title {
  font-size: 18px;
}
.card-text {
  font-size: 14px;
}
.card-border {
  border: 1px solid #ff9900;
}
</style>
