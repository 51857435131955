<template>
  <DashboardLayout>
    <div class="container">
      <h2 class="mb-3">
        My Validations
      </h2>

      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-hovarable" id="tblValidateAssetes">
              <thead>
                <tr>
                  <th>Validate Date</th>
                  <th>File ID</th>
                  <th>Category</th>
                  <th>Sub Category</th>
                  <th>Files</th>
                  <th>Reason</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="asset in validateAssetes" :key="asset.id">
                  <td>{{ asset.registerDate }}</td>
                  <td>{{ asset.cid }}</td>
                  <td>{{ asset.mainCategory }}</td>
                  <td>{{ asset.subCategory }}</td>

                  <td>
                    <a
                      class="btn btn-secondary btn-sm"
                      :href="
                        'https://s3.ap-southeast-1.amazonaws.com/static.TamilData.org/' +
                          asset.cid +
                          '.pdf'
                      "
                      target="_blank"
                    >
                      View File
                    </a>
                  </td>

                  <td>{{ asset.reason }}</td>

                  <td>
                    <span
                      class="badge badge-pill badge-success"
                      v-if="asset.status == 'Approve'"
                    >
                      Approved
                    </span>
                    <span
                      class="badge badge-pill badge-danger"
                      v-if="asset.status == 'Reject'"
                    >
                      Rejected
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "wallet",
  data: () => ({
    email: "",
    validateAssetes: [],
    ipfsData: [],
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    this.email = localStorage.email;

    this.getCategoryData();
    this.getValidateAssetes();
  },
  methods: {
    getValidateAssetes() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/validator/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          const ipfsData = JSON.parse(localStorage.getItem("ipfsData"));
          const assests = response.data;
          const finalAssests = [];

          $.each(assests, function(key, value) {
            var categoryId = value.catId;
            var subCategoryId = value.subCatId;

            const category = getCategoryName(
              ipfsData,
              categoryId,
              subCategoryId
            );

            value["mainCategory"] = category["mainCategory"];
            value["subCategory"] = category["subCategory"];

            finalAssests.push(value);
          });

          this.validateAssetes = finalAssests;
          //console.log(this.validateAssetes);
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          $("#tblValidateAssetes").DataTable();
          loader.hide();
        });
    },
    getCategoryData() {
      $.getJSON("ipfs.json", function(json) {
        localStorage.setItem("ipfsData", JSON.stringify(json));
      });
    },
  },
};

function getCategoryName(jsonData, mainCategoryId, subCategoryId) {
  let mainCategoryText = "";
  let subCategoryText = "";
  for (let mainCategory of jsonData) {
    if (mainCategory.id == mainCategoryId) {
      mainCategoryText = mainCategory.text;
      mainCategoryText = mainCategoryText.split("|")[0];

      for (let subCategory of mainCategory.nodes) {
        if (subCategory.id == subCategoryId) {
          subCategoryText = subCategory.text;
          break;
        }
      }

      break;
    }
  }

  return {
    mainCategory: mainCategoryText,
    subCategory: subCategoryText,
  };
}
</script>

<style scoped>
#tblValidateAssetes {
  width: 100%;
}
#tblValidateAssetes td {
  vertical-align: middle;
}
.img-coin {
  width: 25px;
}
</style>
