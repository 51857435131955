<template>
  <DashboardLayout>
    <div class="containerrr">
      <!--
      <stripe-checkout
        ref="checkoutRef"
        mode="payment"
        :pk="publishableKey"
        :line-items="lineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"
        @loading="(v) => (loading = v)"
      />
      -->

      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="card mx-md-4 my-1">
            <img
              src="../assets/logo-rotate2.png"
              alt="Coin Image"
              class="image"
            />
            <p class="number-h1 mb-0 pb-0">$2</p>
            <p class="number-h1">
              <img
                src="../assets/zha.png"
                alt="Coin Image"
                class="coin-image"
              />
              10
            </p>
            <p class="h1">Save with cofidence</p>
            <p class="p">
              Eliminate the stress of saving money with our innovative solution.
              We understand that saving is something hard.
            </p>

            <button
              class="btn btn-warning btn-block text-white"
              @click="buyToken(2)"
            >
              Buy Now <i class="fa fa-shopping-cart"></i>
            </button>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="card mx-md-4 my-1">
            <img
              src="../assets/logo-rotate2.png"
              alt="Coin Image"
              class="image"
            />
            <p class="number-h1 mb-0 pb-0">$5</p>
            <p class="number-h1">
              <img
                src="../assets/zha.png"
                alt="Coin Image"
                class="coin-image"
              />
              25
            </p>
            <p class="h1">Save with cofidence</p>
            <p class="p">
              Eliminate the stress of saving money with our innovative solution.
              We understand that saving is something hard.
            </p>

            <button
              class="btn btn-warning btn-block text-white"
              @click="buyToken(5)"
            >
              Buy Now <i class="fa fa-shopping-cart"></i>
            </button>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="card mx-md-4 my-1">
            <img
              src="../assets/logo-rotate2.png"
              alt="Coin Image"
              class="image"
            />
            <input
              type="number"
              class="form-control mb-0 pb-0"
              placeholder="Enter Amount ($)"
              v-model="paymentUSD"
              @keyup="calcTamilData.org"
            />
            <p class="number-h1">
              <img
                src="../assets/zha.png"
                alt="Coin Image"
                class="coin-image"
              />
              {{ tamilCoinCount }}
            </p>
            <p class="h1">Save with cofidence</p>
            <p class="p">
              Eliminate the stress of saving money with our innovative solution.
              We understand that saving is something hard.
            </p>

            <button
              class="btn btn-warning btn-block text-white"
              @click="buyToken(paymentUSD)"
            >
              Buy Now <i class="fa fa-shopping-cart"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="row mt-5 mx-2">
        <div class="alert alert-success" role="alert">
          <p class="strong">
           TAMIL Data Decentralization will be deposited to the wallet address below.
          </p>
          <hr />
          <p class="mb-0">
            {{ publicKey }}
          </p>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "buyCoins",
  components: {
    DashboardLayout,
    StripeCheckout,
  },
  data: () => ({
    email: "",
    publicKey: "",
    tamilCoinCount: 0,
    paymentUSD: "",

    publishableKey: "pk_test_kRbh41wzomtVx4m4g4ENGqrL",
    successURL:
      "http://localhost:8081/coin-payment-success?session_id={CHECKOUT_SESSION_ID}",
    cancelURL:
      "https://app.TamilData.org/payment-failed?session_id={CHECKOUT_SESSION_ID}",
    loading: false,

    lineItems: [
      {
        price: "price_1OvZxvJSdx9WLyfKfr6E6e7T",
        quantity: 1,
      },
    ],
  }),
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getWalletInfo();
    }
  },
  methods: {
    getWalletInfo() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.publicKey = response.data[0].publicKey;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    buyToken(token) {
      if (token < 2) {
        Swal.fire({
          icon: "error",
          title: "Minimum $2 Needed.",
          showConfirmButton: true,
        });
        return false;
      }

      let loader = this.$loading.show();

      this.lineItems = [
        {
          price: "price_1OvZxvJSdx9WLyfKfr6E6e7T",
          quantity: token,
        },
      ];

      this.$refs.checkoutRef.redirectToCheckout();
    },
    calcTamilData() {
      var paymentUSD = this.paymentUSD;
      this.tamilCoinCount = paymentUSD / 0.2;
    },
  },
};
</script>

<style scoped>
.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  padding: 30px;
  gap: 10px;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.card > .image {
  width: 100px;
  height: 100;
  transition: all 0.1s ease-in-out;
}

.card > .number-h1 {
  font-size: 30px;
  font-weight: bold;
  color: #e57e25;
}

.card > .h1 {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}

.card > .p {
  color: #9e9da1;
  font-size: 14px;
  text-align: center;
}

.form-control {
  margin: 0px 0px 16px;
  text-align: center;
}

.coin-image {
  width: 30px;
  height: auto;
  border: 1px solid #e57e25;
  border-radius: 50%;
  padding: 3px;
}
</style>
