<template>
  <MarketplaceLayout>
    <div class="input-group mb-3">
      <span class="input-group-text" id="basic-addon1">
        <i class="fas fa-search"></i>
      </span>
      <input
        type="text"
        class="form-control"
        v-model="searchText"
        placeholder="Type to search..."
      />
    </div>

    <!--Success Msg-->
    <div v-show="successMsg" class="mb-3">
      <div class="alert alert-success fade show" role="alert">
        <i class="fas fa-check-circle"></i> &nbsp; <strong>வெற்றி!</strong> வேலை
        விண்ணப்பம் சமர்ப்பிக்கப்பட்டது.
        <br />
        <br />

        <i class="fas fa-check-circle"></i> &nbsp; <strong>Success!</strong> job
        application has been submitted.
      </div>
    </div>
    <!--Success Msg-->

    <!--Alert Msg-->
    <div v-show="alertMsg" class="mb-3">
      <div class="alert alert-warning fade show" role="alert">
        <i class="fas fa-check-circle"></i> &nbsp; <strong>Sorry!</strong> no
        jobs in this job sub Category.
      </div>
    </div>
    <!--Alert Msg-->

    <div class="row">
      <div
        v-for="job in filteredJobs"
        :key="job.id"
        class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2"
      >
        <div class="card bg-light card-border mb-2">
          <div class="card-header">
            <span class="text-bold job-title">
              {{ job.jobTitle }}
            </span>
          </div>
          <div class="card-body">
            <!--Carousel-->
            <Carousel>
              <Slide v-for="slide in 10" :key="slide">
                <div class="carousel__item">
                  <img
                    src="../assets/images/job-pic.jpeg"
                    class="d-block w-100"
                  />
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
            <!--Carousel-->

            <span class="text-bold">Description</span>
            <p class="card-text">{{ job.jobDescription }}</p>

            <p>
              <strong>{{ job.reward }}</strong>
              &nbsp;
              <img src="@/assets/logo.png" alt="TAMIL" width="40" />
            </p>

            <router-link
              class="btn btn-light btn-circle"
              to="/login"
              title="Login to view users Telegram link"
            >
              <i class="fab fa-telegram fa-2x"></i>
            </router-link>
          </div>
          <div class="card-footer">
            <button
              class="btn btn-sm btn-block btn-warning"
              @click="applyJob(job.id)"
            >
              Apply
            </button>
            <router-link
              class="btn btn-sm btn-warning btn-block"
              :to="{
                path: '/job-details',
                query: { jobId: job.id },
              }"
            >
              View
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </MarketplaceLayout>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import $ from "jquery";
import axios from "axios";
import MarketplaceLayout from "../layouts/MarketplaceLayout";

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
    MarketplaceLayout,
  },
  name: "marketplace-jobs",
  data() {
    return {
      jobs: [],
      jobSubCatId: "0",
      searchText: "",
      images: [],
      email: "",
      successMsg: false,
      alertMsg: false,
    };
  },
  computed: {
    filteredJobs() {
      return this.jobs.filter((job) =>
        job.jobTitle.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  },
  mounted() {
    var query = this.$route.query;
    if (Object.keys(query).length > 0) {
      //console.log(query);
      this.jobSubCatId = query.jobSubCatId;
    }
    this.getJobInfo();
  },
  methods: {
    applyJob(jobId) {
      if (localStorage.email === undefined) {
        this.$router.push("/login");
      } else {
        this.email = localStorage.email;
        let loader = this.$loading.show();
        var today = new Date();
        const dataBody = {
          registerDate: today.toLocaleDateString("en-US"),
          email: this.email,
          jobId: jobId,
        };
        const headers = {
          Authorization: "Bearer " + localStorage.token,
        };
        // using JSON.stringify( to convert dataBody object to json
        axios
          .post(
            " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs/job-applications",
            dataBody,
            {
              headers: headers,
            }
          )
          .then((response) => {
            //console.log(response);
            this.successMsg = true;
          })
          .catch((error) => {
            //console.log(error);
            this.successMsg = false;
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
    getJobInfo() {
      let loader = this.$loading.show();
      var self = this;
      var filteredJobArray = [];
      var selectedJobSubCat = this.jobSubCatId;
      $.getJSON(
        "https://s3.ap-southeast-1.amazonaws.com/static.TamilData.org/jobs.json",
        function(json) {
          //console.log(json);
          json.forEach(function(item, index) {
            if (item.jobSubCatId == selectedJobSubCat) {
              filteredJobArray.push(item);
            }
          });
          self.jobs = filteredJobArray;
          if (filteredJobArray.length > 0) {
            self.alertMsg = false;
          } else {
            self.alertMsg = true;
          }
        }
      ).fail(function(e) {
        console.log("Json Fail" + e);
      });
      loader.hide();
    },
  },
};
</script>

<style scoped>
.text-bold {
  font-weight: bold;
  font-size: 15px;
}
.job-title {
  font-size: 18px;
}
.card-text {
  font-size: 14px;
}
.input-group-text {
  background-color: rgba(179, 179, 179, 0.993);
}
.card-border {
  border: 1px solid #ff9900;
}
.card {
  height: 100%;
}
</style>
