<template>
  <DashboardLayout>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="form-group">
                <label for="">Title</label>
                <input type="text" class="form-control" v-model="title" />
              </div>
              <div class="form-group">
                <label for="">Description</label>
                <textarea class="form-control" v-model="description" rows="7" />
              </div>
              <div class="form-group">
                <label for="">Display URL</label>
                <input type="text" class="form-control" v-model="displayUrl" />
              </div>
              <div class="form-group">
                <label for="">Target URL</label>
                <input type="text" class="form-control" v-model="targetUrl" />
              </div>
              <div class="form-group">
                <label for="">End Date</label>
                <input type="date" class="form-control" v-model="endDate" />
              </div>
              <div class="form-group">
                <label for="">Target Country</label>
                <select class="form-control form-select" v-model="country">
                  <option
                    v-for="country in countries"
                    v-bind:key="country.id"
                    :value="country.name"
                  >
                    {{ country.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="">Contact No</label>
                <input type="number" class="form-control" v-model="contactNo" />
              </div>

              <!--Success Msg-->
              <div v-show="successMsg">
                <div class="alert alert-success m-2" role="alert">
                  <i class="fas fa-check-circle"></i> &nbsp;
                  <strong>Success!</strong> ad has been posted.
                </div>
              </div>
              <!--Success Msg-->

              <div class="form-group">
                <button
                  class="btn btn-danger btn-block"
                  type="button"
                  @click="postAds"
                >
                  Post
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="mobile">
            <div class="phone">
              <div class="phone-mirror">
                <div class="topWrapper">
                  <div class="camera"></div>
                  <div class="line-rec"></div>
                </div>
                <div class="mobile-body p-2">
                  <br />
                  <br />
                  <div class="card">
                    <div class="card-body">
                      <p class="adUrl">
                        <strong>Ad . </strong>
                        <span>
                          {{ displayUrl }}
                        </span>
                      </p>
                      <h3 class="titleAds">{{ title }}</h3>
                      <p class="adDesc">{{ description }}</p>

                      <hr />

                      <h5 class="contactAds">Contact Us</h5>
                      <p class="coNoAds">
                        <i class="fas fa-phone-alt"></i> {{ contactNo }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

export default {
  name: "data-dex",
  data() {
    return {
      email: "",
      title: "",
      description: "",
      displayUrl: "",
      targetUrl: "",
      endDate: "",
      country: "Singapore",
      contactNo: "",
      successMsg: false,
      countries: [
        { id: 1, name: "India" },
        { id: 2, name: "Srilanka" },
        { id: 3, name: "Malaysia" },
        { id: 4, name: "United States" },
        { id: 5, name: "France" },
        { id: 6, name: "Australia" },
        { id: 7, name: "Switzerland" },
        { id: 8, name: "Singapore" },
        { id: 9, name: "Denmark" },
        { id: 10, name: "United Arab Emirates" },
        { id: 11, name: "Italy" },
        { id: 12, name: "Germany" },
        { id: 13, name: "United Kingdom" },
        { id: 14, name: "Canada" },
        { id: 15, name: "Others" },
      ],
    };
  },
  components: {
    DashboardLayout,
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
    }
  },
  methods: {
    postAds() {
      let loader = this.$loading.show();

      var today = new Date();
      const dataBody = {
        dateCreated: today.toLocaleDateString("en-US"),
        email: this.email,
        title: this.title,
        displayUrl: this.displayUrl,
        targetUrl: this.targetUrl,
        adDesc: this.description,
        endDate: this.endDate,
        targetCountry: this.country,
        contactNo: this.contactNo,
      };

      //console.log(dataBody);

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/ads",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.successMsg = true;
        })
        .catch((error) => {
          //console.log(error);
          this.successMsg = false;
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.card-body {
  text-align: left;
  width: 100%;
}
.adUrl {
  color: rgb(82, 79, 79);
}
.titleAds {
  color: blue;
}
.adDesc {
  text-align: justify;
}
.contactAds {
  color: blue;
}
.coNoAds {
  color: rgb(82, 79, 79);
}
.mobile {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.phone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 331px;
  height: 100vh;
  background: #e74a3b;
  border-radius: 30px;
  padding: 10px;
}
.phone-mirror {
  position: relative;
  width: 328px;
  height: 600px;
  background-color: rgb(179, 174, 174);
  border-radius: 30px;
}
.topWrapper {
  position: relative;
  width: 130px;
  height: 20px;
  margin: 0px auto 0 auto;
  float: left;
  left: 30%;
  background-color: rgb(138, 135, 135);
  border-radius: 0 0 45px 45px;
}
.line-rec {
  position: relative;
  width: 60px;
  height: 6px;
  margin: 0px auto 0 auto;
  float: left;
  left: 20%;
  top: 4px;
  background-color: rgb(83, 83, 82);
  border-radius: 5px;
  border: 1px solid rgb(214, 214, 214);
}
.camera {
  position: relative;
  float: left;
  margin-right: 8px;
  left: 18%;
  width: 11px;
  height: 11px;
  top: 1px;
  background-color: rgb(83, 83, 82);
  border-radius: 30px;
  border: 1px solid rgb(214, 214, 214);
}

.mobile-body {
  position: relative;
  color: rgb(26, 25, 25);
}
</style>
