<template>
  <DashboardLayout>
    <div class="container">
      <h2 class="mb-3"></h2>

      <div class="text-left mb-2">
        <button
          class="btn btn-secondary"
          data-toggle="modal"
          data-target="#addPartnerModal"
        >
          Add Partner
        </button>
      </div>
      <div class="card shadow">
        <div class="card-body table-responsive">
          <table class="table table-hovarable" id="tblPartners">
            <thead>
              <tr>
                <th></th>
                <th>Date Added</th>
                <th>Name/Email</th>
                <th>City/Country</th>
                <th>Partner Type</th>
                <th>Engagement</th>
                <th>Lat/Long</th>
                <th>Added By</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(partner, index) in allPartners" :key="index">
                <td>
                  <img
                    :src="getPartnerProPic(partner.partnerImg)"
                    class="partner-image-common"
                    alt="Profile Picture"
                  />
                </td>
                <td>{{ partner.registerDate }}</td>
                <td>
                  {{ partner.name }}
                  <br />
                  <small>{{ partner.email }}</small>
                </td>
                <td>
                  {{ partner.city }}
                  <br />
                  <small>{{ partner.country }}</small>
                </td>
                <td>{{ partner.partnerType }}</td>
                <td>{{ partner.engagement }}</td>
                <td>
                  {{ partner.lat }} <br />
                  {{ partner.long }}
                </td>
                <td>{{ partner.addedBy }}</td>
                <td>
                  <div class="dropdown">
                    <button
                      type="button"
                      class="btn btn-secondary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <div class="dropdown-menu">
                      <button
                        class="dropdown-item"
                        @click="editPartner(partner)"
                      >
                        Edit
                      </button>
                      <button class="dropdown-item">
                        Enable / Disable
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="addPartnerModal"
      tabindex="-1"
      aria-labelledby="addPartnerModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Partner</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-left">
            <div class="form-group">
              <label>Name :</label>
              <input
                type="text"
                class="form-control"
                v-model="partnerDetails.name"
              />
            </div>

            <div class="form-group">
              <label>Country :</label>
              <input
                type="text"
                class="form-control"
                v-model="partnerDetails.country"
              />
            </div>

            <div class="form-group">
              <label>City :</label>
              <input
                type="text"
                class="form-control"
                v-model="partnerDetails.city"
              />
            </div>

            <div class="form-group">
              <label>Address :</label>
              <textarea
                class="form-control"
                aria-describedby="Message"
                v-model="partnerDetails.address"
              ></textarea>
            </div>

            <div class="form-group">
              <label>Postal code :</label>
              <input
                type="number"
                class="form-control"
                v-model="partnerDetails.postalCode"
              />
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Latitude :</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="partnerDetails.lat"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Longitude :</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="partnerDetails.long"
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Google Page Url :</label>
              <input
                type="text"
                class="form-control"
                v-model="partnerDetails.googlePageUrl"
              />
            </div>

            <div class="form-group">
              <label>Contact Person :</label>
              <input
                type="text"
                class="form-control"
                v-model="partnerDetails.contactPerson"
              />
            </div>

            <div class="form-group">
              <label>Mobile :</label>
              <input
                type="number"
                class="form-control"
                v-model="partnerDetails.mobile"
              />
            </div>

            <div class="form-group">
              <label>Email :</label>
              <input
                type="email"
                class="form-control"
                v-model="partnerDetails.email"
              />
            </div>

            <div class="form-group">
              <label>Website :</label>
              <input
                type="text"
                class="form-control"
                v-model="partnerDetails.website"
              />
            </div>

            <div class="form-group">
              <label>Type :</label>
              <select class="form-select" v-model="partnerDetails.partnerType">
                <option value="" hidden>Choose Type</option>
                <option value="Merchant">Merchant</option>
                <option value="Association">Association</option>
              </select>
            </div>

            <div class="form-group">
              <label>Social Media Links :</label>
              <input
                type="text"
                class="form-control"
                v-model="partnerDetails.facebookPage"
              />
            </div>

            <div class="form-group">
              <label>Engagement Option :</label>
              <textarea
                class="form-control"
                v-model="partnerDetails.engagement"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="btnCloseAddModal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary" @click="addPartner">
              Add Partner
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->

    <!-- Update Modal -->
    <button
      class="hidden"
      data-toggle="modal"
      data-target="#updatePartnerModal"
      id="btnUpdateModalToggle"
    >
      Update Partner
    </button>
    <div
      class="modal fade"
      id="updatePartnerModal"
      tabindex="-1"
      aria-labelledby="updatePartnerModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Partner</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-left">
            <div class="text-center my-2">
              <img
                :src="editPartnerDetails.partnerImg"
                class="partner-image"
                alt="Profile Picture"
                @click="triggerOnPartnerPicChange"
                id="imgPartnerImageUpload"
              />
              <input
                type="hidden"
                id="imgPartnerImageUploadPath"
                :value="editPartnerDetails.partnerImgPath"
              />
              <input
                id="filePartnerImageUpload"
                class="hidden"
                type="file"
                accept="image/*"
                @change="onPartnerPicChange"
              />
            </div>

            <div class="form-group">
              <label>Name :</label>
              <input
                type="text"
                class="form-control"
                v-model="editPartnerDetails.name"
              />
            </div>

            <div class="form-group">
              <label>Country :</label>
              <input
                type="text"
                class="form-control"
                v-model="editPartnerDetails.country"
              />
            </div>

            <div class="form-group">
              <label>City :</label>
              <input
                type="text"
                class="form-control"
                v-model="editPartnerDetails.city"
              />
            </div>

            <div class="form-group">
              <label>Address :</label>
              <textarea
                class="form-control"
                aria-describedby="Message"
                v-model="editPartnerDetails.address"
              ></textarea>
            </div>

            <div class="form-group">
              <label>Postal code :</label>
              <input
                type="number"
                class="form-control"
                v-model="editPartnerDetails.postalCode"
              />
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Latitude :</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="editPartnerDetails.lat"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Longitude :</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="editPartnerDetails.long"
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Google Page Url :</label>
              <input
                type="text"
                class="form-control"
                v-model="editPartnerDetails.googlePageUrl"
              />
            </div>

            <div class="form-group">
              <label>Contact Person :</label>
              <input
                type="text"
                class="form-control"
                v-model="editPartnerDetails.contactPerson"
              />
            </div>

            <div class="form-group">
              <label>Mobile :</label>
              <input
                type="number"
                class="form-control"
                v-model="editPartnerDetails.mobile"
              />
            </div>

            <div class="form-group">
              <label>Email :</label>
              <input
                type="email"
                class="form-control"
                v-model="editPartnerDetails.email"
              />
            </div>

            <div class="form-group">
              <label>Website :</label>
              <input
                type="text"
                class="form-control"
                v-model="editPartnerDetails.website"
              />
            </div>

            <div class="form-group">
              <label>Type :</label>
              <select
                class="form-select"
                v-model="editPartnerDetails.partnerType"
              >
                <option value="" hidden>Choose Type</option>
                <option value="Merchant">Merchant</option>
                <option value="Association">Association</option>
              </select>
            </div>

            <div class="form-group">
              <label>Social Media Links :</label>
              <input
                type="text"
                class="form-control"
                v-model="editPartnerDetails.facebookPage"
              />
            </div>

            <div class="form-group">
              <label>Engagement Option :</label>
              <textarea
                class="form-control"
                v-model="editPartnerDetails.engagement"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="btnCloseUpdateModal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="updatePartner"
            >
              Update Partner
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Update Modal-->
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

import { v4 as uuidv4 } from "uuid";

import defaultPartnerPic from "../assets/images/no-image.jpg";

export default {
  name: "accounts",
  data: () => ({
    email: "",
    allPartners: [],
    partnerDetails: {
      name: "",
      country: "",
      city: "",
      postalCode: "",
      contactPerson: "",
      mobile: "",
      email: "",
      website: "",
      facebookPage: "",
      address: "",
      engagement: "",
      partnerType: "",
    },
    editPartnerDetails: {},
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    this.email = localStorage.email;

    this.getAllPatners();
  },
  methods: {
    addPartner() {
      const partnerDetails = this.partnerDetails;

      //Validation
      if (partnerDetails.name == "") {
        Swal.fire({
          title: "Warning",
          text: "Name is must needed.",
          icon: "error",
        });

        return false;
      }

      if (partnerDetails.email == "") {
        Swal.fire({
          title: "Warning",
          text: "Email is must needed.",
          icon: "error",
        });

        return false;
      }
      //Validation

      $("#btnCloseAddModal").trigger("click");

      var partitionKey = uuidv4()
        .replace(/-/g, "")
        .substring(0, 32);

      var today = new Date();
      partnerDetails.registerDate = today.toLocaleDateString("en-US");
      partnerDetails.addedBy = this.email;
      partnerDetails.id = partitionKey;

      let loader = this.$loading.show();

      const dataBody = partnerDetails;
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .post(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/partners",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);

          Swal.fire({
            title: "Success",
            icon: "success",
          }).then((result) => {
            let loader = this.$loading.show();

            location.reload();
          });
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getAllPatners() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/partners/?addedBy=all",
          {
            headers: headers,
          }
        )
        .then((response) => {
          ////console.log(response);
          this.allPartners = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          $("#tblPartners").DataTable();
          loader.hide();
        });
    },
    editPartner(partnerData) {
      const editedItem = { ...partnerData };

      //Check Have Upload Profile Picture
      if (editedItem.partnerImg && editedItem.partnerImg != "") {
        editedItem.partnerImgPath = editedItem.partnerImg;
        editedItem.partnerImg = editedItem.partnerImg + "?v=" + Math.random();
      } else {
        editedItem.partnerImgPath = "";
        editedItem.partnerImg = defaultPartnerPic;
      }
      //Check Have Upload Profile Picture

      this.editPartnerDetails = editedItem;
      $("#btnUpdateModalToggle").trigger("click");
    },
    updatePartner() {
      const partnerDetails = this.editPartnerDetails;

      var partnerProfile = $("#imgPartnerImageUploadPath").val();
      partnerDetails.partnerImg = partnerProfile;

      //Validation
      if (partnerDetails.name == "") {
        Swal.fire({
          title: "Warning",
          text: "Name is must needed.",
          icon: "error",
        });

        return false;
      }

      if (partnerDetails.email == "") {
        Swal.fire({
          title: "Warning",
          text: "Email is must needed.",
          icon: "error",
        });

        return false;
      }
      //Validation

      $("#btnCloseUpdateModal").trigger("click");

      let loader = this.$loading.show();

      const dataBody = partnerDetails;
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .post(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/partners",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);

          Swal.fire({
            title: "Success",
            icon: "success",
          }).then((result) => {
            let loader = this.$loading.show();

            location.reload();
          });
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getPartnerProPic(partnerImg) {
      //console.log();
      //Check Have Upload Profile Picture
      if (partnerImg && partnerImg != "") {
        return partnerImg + "?v=" + Math.random();
      } else {
        return defaultPartnerPic;
      }
      //Check Have Upload Profile Picture
    },
    onPartnerPicChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let loader = this.$loading.show();
      let reader = new FileReader();
      reader.onload = (e) => {
        if (!e.target.result.includes("data:image/")) {
          return alert("Wrong file type - Image only.");
        }
        if (e.target.result.length > 1000000) {
          return alert("Image is loo large - 1Mb maximum");
        }

        $("#imgPartnerImageUpload").attr("src", e.target.result);

        this.uploadImage(e.target.result);
      };
      reader.readAsDataURL(file);
      loader.hide();
    },
    async uploadImage(imgData) {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      const response = await axios({
        method: "GET",
        url:
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/upload-media",
        headers: headers,
      });

      let binary = atob(imgData.split(",")[1]);
      let array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      let blobData = new Blob([new Uint8Array(array)], { type: "image/jpeg" });

      const result = await fetch(response.data.uploadURL, {
        method: "PUT",
        body: blobData,
      });

      if (result.status == 200) {
        var fileName = response.data.photoFilename;

        var path =
          "https://s3.ap-southeast-1.amazonaws.com/static.TamilData.org/" +
          fileName;

        $("#imgPartnerImageUpload").attr("src", path);
        $("#imgPartnerImageUploadPath").val(path);
      }
      loader.hide();
    },
    triggerOnPartnerPicChange() {
      $("#filePartnerImageUpload").trigger("click");
    },
  },
};
</script>

<style scoped>
#tblPartners {
  width: 100%;
}

#tblPartners td,
th {
  vertical-align: middle;
  text-align: left;
}

.hidden {
  display: none;
}

.partner-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #ddd;
  padding: 5px;
  cursor: pointer;
}

.partner-image-common {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #ddd;
  padding: 5px;
}
</style>
