<template>
  <DashboardLayout>
    <div class="row mt-4">
      <!-- Col 1 -->
      <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4"></div>
      <!-- Col 1 -->

      <!-- Col 2 -->
      <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <!--input Div-->
        <div class="form-floating mb-3">
          <textarea
            class="form-control"
            id="projectStatus"
            v-model="projectStatus"
            placeholder="Enter Your project Status..."
            style="height: 100px"
          />
          <label for="projectStatus"> Status </label>
        </div>

        <div class="mb-3 float-right">
          <button class="btn btn-danger" type="button" @click="postStatus">
            Post
          </button>
        </div>
        <!--input Div-->

        <!--Success Msg-->
        <div v-show="successMsg" class="mb-3">
          <div class="alert alert-success fade show" role="alert">
            <i class="fas fa-check-circle"></i> &nbsp;
            <strong>வெற்றி!</strong> செயற்திட்ட நிலை பதிவேற்றப்பட்டது.
            <br />
            <br />

            <i class="fas fa-check-circle"></i> &nbsp;
            <strong>Success!</strong> Project status has been uploaded.
          </div>
        </div>
        <!--Success Msg-->

        <!--Uploaded Status-->
        <div class="mt-2 mb-2 postedStatus">
          <div
            v-for="status in myStatuses"
            :key="status.id"
            class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1"
          >
            <strong>{{ status.registerDate }}</strong>
            <p>
              {{ status.projectStatus }}
            </p>
          </div>
        </div>
        <!--Uploaded Status-->
      </div>
      <!-- Col 2 -->
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//import { Auth } from "aws-amplify";

export default {
  name: "my-projects",
  data() {
    return {
      email: "",
      successMsg: false,
      projectStatus: "",
      projectId: "",
      myStatuses: [],
    };
  },
  mounted() {
    var query = this.$route.query;
    if (Object.keys(query).length > 0) {
      //console.log(query);
      this.projectId = query.projectId;
    }

    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getAllPosts();
    }
  },
  components: {
    DashboardLayout,
  },
  methods: {
    postStatus() {
      let loader = this.$loading.show();
      var today = new Date();

      const dataBody = {
        registerDate: today.toLocaleDateString("en-US"),
        email: this.email,
        projectId: this.projectId,
        projectStatus: this.projectStatus,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // using JSON.stringify( to convert dataBody object to json
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/add-project/project-status",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.successMsg = true;
          this.projectStatus = "";
        })
        .catch((error) => {
          //console.log(error);
          this.successMsg = false;
        })
        .finally(() => {
          this.getAllPosts();
          loader.hide();
        });
    },
    getAllPosts() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      //console.log(this.projectId);
      // GET request using axios with async/await
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/add-project/project-status/?projectId=" +
            this.projectId,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.myStatuses = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.float-right {
  text-align: right;
}
.postedStatus {
  text-align: left;
}
</style>
