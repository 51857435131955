<template>
  <DashboardLayout>
    <div class="container">
      <h2 class="mb-3">
        Validate Assets - Showing only files pending validation
      </h2>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4 col-sm-12 mb-2">
              <select class="form-control form-select" v-model="categoryId">
                <option value="0" hidden>Please select an Category</option>
                <option
                  v-for="(option, index) in mainClassifications"
                  :key="index"
                  :value="option.id"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="col-md-2 col-sm-12 mb-2">
              <button
                class="btn btn-success btn-block"
                @click="getValidateAssetes"
              >
                Get Assets
              </button>
            </div>
          </div>

          <hr />

          <div class="table-responsive">
            <table
              class="table table-hovarable text-left"
              id="tblValidateAssetes"
            >
              <thead>
                <tr>
                  <th>Uploaded Date</th>
                  <th>File ID</th>
                  <th>Sub Category</th>
                  <th>Files</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="asset in validateAssetes" :key="asset.id">
                  <td>{{ asset.registerDate }}</td>
                  <td>
                    {{ asset.cid }}
                    <button
                      class="btn btn-outline-primary btn-block btn-sm"
                      @click="viewCopyRights(asset.metadata, asset.copyRight)"
                    >
                      View Metadata
                    </button>
                  </td>
                  <td>{{ asset.subCategory }}</td>
                  <td>
                    <a
                      class="btn btn-secondary btn-block btn-sm mb-2"
                      :href="
                        'https://s3.ap-southeast-1.amazonaws.com/static.TamilData.org/' +
                          asset.cid +
                          '.pdf'
                      "
                      target="_blank"
                    >
                      View File
                    </a>

                    <button
                      class="btn btn-warning text-white btn-block btn-sm"
                      @click="viewLog(asset.validationData)"
                    >
                      View Log
                    </button>
                  </td>
                  <td>
                    <button
                      class="btn btn-success btn-block btn-sm"
                      @click="assetAction(asset.cid, asset.email, 'Approve')"
                      v-if="asset.validationStatus == 'Pending'"
                    >
                      Approve
                    </button>
                    <button
                      class="btn btn-success btn-block btn-sm"
                      v-else-if="asset.validationStatus == 'Approve'"
                      disabled
                    >
                      <i class="fas fa-check-circle"></i> Approved
                    </button>

                    <button
                      class="btn btn-danger btn-block btn-sm"
                      @click="assetAction(asset.cid, asset.email, 'Reject')"
                      v-if="asset.validationStatus == 'Pending'"
                    >
                      Reject
                    </button>
                    <button
                      class="btn btn-danger btn-block btn-sm"
                      v-else-if="asset.validationStatus == 'Reject'"
                      disabled
                    >
                      <i class="fas fa-times-circle"></i> Rejected
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <button
      class="hidden"
      data-toggle="modal"
      data-target="#validationLogModal"
      id="btnTriggerValidationLogModal"
    ></button>
    <div
      class="modal fade"
      id="validationLogModal"
      tabindex="-1"
      aria-labelledby="validationLogModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-right">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="table-responsive">
              <table class="table table-striped text-left" style="width: 100%;">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Validate By</th>
                    <th>Verdict</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(validation, index) in validationLogData"
                    :key="index"
                  >
                    <td>{{ validation.registerDate }}</td>
                    <td>{{ validation.email }}</td>
                    <td>
                      <span
                        class="badge badge-pill badge-success"
                        v-if="validation.status == 'Approve'"
                      >
                        Approved
                      </span>
                      <span
                        class="badge badge-pill badge-danger"
                        v-if="validation.status == 'Reject'"
                      >
                        Rejected
                      </span>
                    </td>
                    <td>{{ validation.reason }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="text-center">
                    <td colspan="2" style="width: 50%;">
                      <div class="alert alert-success" role="alert">
                        <h4>
                          {{ verdictCount("Approve") }}
                          <br />
                          Approvals
                        </h4>
                      </div>
                    </td>
                    <td colspan="2" style="width: 50%;">
                      <div class="alert alert-danger" role="alert">
                        <h4>
                          {{ verdictCount("Reject") }}
                          <br />
                          Rejections
                        </h4>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "wallet",
  data: () => ({
    email: "",
    validateAssetes: [],
    ipfsData: [],
    categoryId: 0,
    mainClassifications: [],
    validationLogData: [],
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    this.email = localStorage.email;

    this.getCategoryData();
    this.getMainCategoryData();
    this.getValidateAssetes();
  },
  methods: {
    getValidateAssetes() {
      let loader = this.$loading.show();
      var datatable = $("#tblValidateAssetes").DataTable();
      datatable.destroy();

      var categoryId = this.categoryId;
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/assets/by-category/?catId=" +
            categoryId +
            "&email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          const ipfsData = JSON.parse(localStorage.getItem("ipfsData"));
          const assests = response.data;
          const finalAssests = [];

          $.each(assests, function(key, value) {
            var validationStatus = value.validationStatus;

            if (validationStatus == "Pending") {
              var categoryId = value.catId;
              var subCategoryId = value.subCatId;

              const category = getCategoryName(
                ipfsData,
                categoryId,
                subCategoryId
              );

              value["mainCategory"] = category["mainCategory"];
              value["subCategory"] = category["subCategory"];

              finalAssests.push(value);
            }
          });

          this.validateAssetes = finalAssests;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          $("#tblValidateAssetes").DataTable();
          loader.hide();
        });
    },
    assetAction(cId, uploaderEmail, status) {
      if (status == "Approve") {
        var title = "Reason for approval:";
        var placeholder =
          "When approving a document, please ensure that it has been validated correctly and matches the specified category and sub-category. All details should be accurate. You will be rewarded for providing a proper justification.";
        var htmlData =
          "<div class='alert alert-success p-1 m-0 mb-1 hoverable action-status'>Content aligned with category and subcategory</div>\
        <div class='alert alert-success p-1 m-0 mb-1 hoverable action-status'>Copyright information provided</div>\
        <div class='alert alert-success p-1 m-0 mb-1 hoverable action-status'>Content adheres to publisher guidelines</div>";
      } else {
        var title = "Reason for rejection:";
        var placeholder =
          "When rejecting a document, please provide a clear reason for the rejection. State reasons such as a category or subcategory mismatch, copyright issues, etc. Ensure that the rejection justification is presented in correct English";
        var htmlData =
          "<div class='alert alert-danger p-1 m-0 mb-1 hoverable action-status'>Tamil text not typed, scanned version of book</div>\
        <div class='alert alert-danger p-1 m-0 mb-1 hoverable action-status'>Adult mature content</div>\
        <div class='alert alert-danger p-1 m-0 mb-1 hoverable action-status'>Copyright info not provided</div>\
        <div class='alert alert-danger p-1 m-0 mb-1 hoverable action-status'>Violation of publishing rule</div>";
      }

      Swal.fire({
        title: title,
        html: htmlData,
        input: "textarea",
        inputPlaceholder: placeholder,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        showLoaderOnConfirm: false,
        allowOutsideClick: false,
        preConfirm: (text) => {
          if (!text) {
            Swal.showValidationMessage("Reason cannot be empty");
          }
          return text;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          var reason = result.value;

          //API Call to Asset Action
          let loader = this.$loading.show();

          var today = new Date();
          const dataBody = {
            registerDate: today.toLocaleDateString("en-US"),
            email: this.email,
            cid: cId,
            uploaderEmail: uploaderEmail,
            status: status,
            reason: reason,
          };

          const headers = {
            Authorization: "Bearer " + localStorage.token,
          };

          axios
            .post(
              "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/validator",
              dataBody,
              {
                headers: headers,
              }
            )
            .then((response) => {
              //console.log(response);

              Swal.fire({
                title: "Success",
                icon: "success",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.getValidateAssetes();
                }
              });
            })
            .catch((error) => {
              //console.log(error);
            })
            .finally(() => {
              loader.hide();
            });
          //API Call to Asset Action
        }
      });
    },
    getCategoryData() {
      $.getJSON("ipfs.json", function(json) {
        localStorage.setItem("ipfsData", JSON.stringify(json));
      });
    },
    getMainCategoryData() {
      const mainCategoriesData = [];
      const jsonData = JSON.parse(localStorage.getItem("ipfsData"));

      for (let mainCategory of jsonData) {
        mainCategoriesData.push({
          id: mainCategory.id,
          text: mainCategory.text.split("|")[0].trim(),
        });
      }

      this.mainClassifications = mainCategoriesData;
    },
    viewLog(validationData) {
      this.validationLogData = validationData;
      $("#btnTriggerValidationLogModal").trigger("click");
    },
    verdictCount(verdict) {
      const count = this.validationLogData.filter(
        (item) => item.status == verdict
      ).length;
      return count;
    },
    viewCopyRights(metadata, copyRight) {
      if (copyRight) {
        var copyRightText = copyRight;
      } else {
        var copyRightText = "-";
      }

      if (metadata) {
        var metadataArray = JSON.parse(metadata);
      } else {
        var metadataArray = {};
      }

      var htmlData = `
        <div class="mb-2">
          <strong>Copyright Status :</strong>
          <span>${copyRightText}</span>
        </div>`;

      $.each(metadataArray, function(key, value) {
        htmlData += `
        <div class="mb-2">
          <strong>${key} :</strong>
          <span>${value}</span>
        </div>`;
      });

      htmlData = `<div class='text-start m-3 align-middle'>${htmlData}</div>`;

      Swal.fire({
        title: "Metadata Information",
        text: "That thing is still around?",
        html: htmlData,
        showCloseButton: true,
        showConfirmButton: false,
      });
    },
  },
};

function getCategoryName(jsonData, mainCategoryId, subCategoryId) {
  let mainCategoryText = "";
  let subCategoryText = "";
  for (let mainCategory of jsonData) {
    if (mainCategory.id == mainCategoryId) {
      mainCategoryText = mainCategory.text;
      mainCategoryText = mainCategoryText.split("|")[0];

      for (let subCategory of mainCategory.nodes) {
        if (subCategory.id == subCategoryId) {
          subCategoryText = subCategory.text;
          break;
        }
      }

      break;
    }
  }

  return {
    mainCategory: mainCategoryText,
    subCategory: subCategoryText,
  };
}

$("body").on("click", ".action-status", function() {
  var htmlData = $(this).html();
  $(".action-status").remove();
  $("#swal2-textarea").val(htmlData);
});
</script>

<style scoped>
#tblValidateAssetes {
  width: 100%;
}
#tblValidateAssetes td {
  vertical-align: middle;
}
.img-coin {
  width: 25px;
}

.hidden {
  display: none;
}
</style>
