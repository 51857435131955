<template>
  <DashboardLayout>
    <div class="mt-5 container">
      <div class="card ml-auto bg-success">
        <div class="card-body text-white">
          <h3><i class="fas fa-check-circle"></i> Success !</h3>
          <p>
            We have received your order.
            <br />
            Your order will be delivered to the address mentioned in profile.
            <br />
          </p>
          Please update your address in profile section.
          <router-link to="profile" class="no-decoration">
            Update Address
          </router-link>

          <br />
          <br />
          <p>
            Support:
            <a
              href="mailto:support@TamilData.org"
              target="_blank"
              class="no-decoration"
            >
              support@TamilData.org
            </a>
            <br />
            Telegram Group:
            <a
              href="https://t.me/TamilData.org"
              target="_blank"
              class="no-decoration"
            >
              https://t.me/TamilData.org
            </a>
          </p>
          <hr />
          <p>Currently we only deliver to Singapore/Malaysia.</p>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";

export default {
  name: "paymentSuccess",
  data: () => ({
    session_id: "",
    email: "",
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    this.session_id = this.$route.query.session_id;
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
    }
  },
};
</script>
<style scoped>
.text-white {
  color: aliceblue;
}
.text-center {
  align-items: center;
  text-align: center;
}
.no-decoration {
  text-decoration: none;
  color: rgb(184, 204, 224);
}
</style>