<template>
  <div class="container-fluid">
    <img
      src="@/assets/images/login-logo.png"
      alt="Logo"
      class="login-logo mt-3"
    />
    <h4 class="mt-2 mb-3">
      🙏 உங்கள் வரவு நல்வரவாகட்டும். <br />
      Welcome all Tamil language lovers from all over the world.
    </h4>

    <div class="row">
      <!--Col 1-->
      <div class="col-sm-12 col-md-4 mb-3">
        <!--Sign In Card-->
        <div class="card" v-show="viewCard == 'signin'">
          <div class="card-header">
            <h4>Sign In</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="signIn">
              <div class="form-floating mb-3">
                <input
                  type="email"
                  class="form-control"
                  v-model="loginEmail"
                  placeholder="Email address..."
                  autocomplete="off"
                  required
                />
                <label for="floatingInput">Email address</label>
              </div>

              <div class="form-floating mb-3">
                <input
                  type="password"
                  class="form-control"
                  v-model="loginPassword"
                  placeholder="password..."
                  autocomplete="off"
                  required
                />
                <label for="floatingPassword">Password</label>
              </div>

              <button class="btn btn-warning text-white w-100">
                Login
              </button>
            </form>

            <br />
            <br />
            <a href="#" @click="forgotpassword">
              கடவுச்சொல்லை மறந்து விட்டீர்களா? <br />
              Forgot Password?
            </a>

            <br />
            <br />
            <router-link to="/signup">
              புதிதாக கணக்கு ஆரம்பிக்க இங்கே இணைப்பை அழுத்தவும்.
              <br />
              <br />
              Click here to create new account
            </router-link>
          </div>
        </div>
        <!--Sign In Card-->

        <!--Confirmation Card-->
        <div class="card" v-show="viewCard == 'signin-confirmation'">
          <div class="card-header">
            <h4>Sign In Confirmation</h4>
          </div>
          <div class="card-body">
            <div class="alert alert-warning" role="alert">
              உங்கள் கணக்கு உறுதிப்படுத்தல் நிலுவையில் உள்ளது. தயவுசெய்து உங்கள்
              மின்னஞ்சலைச் சரிபார்த்து உறுதிப்படுத்தல் குறியீட்டை உள்ளிடவும்.
              <br />
              <br />

              Your account is pending confirmation. Please check your email and
              enter confirmation code.
            </div>

            <form @submit.prevent="confirmSignUp">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="loginConformationCode"
                  placeholder="Enter Confirmation Code..."
                  required
                />
                <label for="floatingInput">Confirmation Code</label>
              </div>

              <div class="mt-3">
                <button class="btn btn-warning text-white w-100">
                  Confirm
                </button>

                <button
                  class="btn btn-danger w-100 mt-2"
                  @click="resendConfirmationCode"
                >
                  Resend
                </button>

                <button
                  class="btn btn-secondary w-100 mt-2"
                  @click="backToSignin()"
                >
                  Back to Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <!--Confirmation Card-->

        <!--Forgot Password Card-->
        <div class="card" v-show="viewCard == 'forgotpassword'">
          <div class="card-header">
            <h5>
              கடவுச்சொல்லை மீட்டமைக்க <br />
              Reset Password
            </h5>
          </div>
          <div class="card-body">
            <form @submit.prevent="getForgotPasswordCode">
              <div class="form-floating mb-3">
                <input
                  type="email"
                  class="form-control"
                  v-model="forgotPasswordEmail"
                  placeholder="Enter your email..."
                  required
                />
                <label for="floatingInput">Enter your email</label>
              </div>

              <div class="form-action">
                <button class="btn btn-warning text-white w-100">
                  உறுதிப்படுத்தல் குறியீட்டைப் பெறுங்கள் <br />
                  Get Confirmation Code
                </button>

                <button
                  class="btn btn-secondary w-100 mt-2"
                  @click="backToSignin()"
                >
                  Back to Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <!--Forgot Password Card-->

        <!--Forgot Password Change Card-->
        <div class="card" v-show="viewCard == 'forgotpasswordReset'">
          <div class="card-header">
            <h5>
              கடவுச்சொல்லை மீட்டமைக்க <br />
              Reset Password
            </h5>
          </div>
          <div class="card-body">
            <form @submit.prevent="changePassword">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="forgotPasswordconfirmationCode"
                  placeholder="Enter Confirmation Code..."
                  required
                />
                <label for="floatingInput">Confirmation Code</label>
              </div>

              <div class="form-floating mb-3">
                <input
                  type="password"
                  class="form-control"
                  v-model="newPassword"
                  placeholder="Enter New Password..."
                  required
                />
                <label for="floatingInput">
                  New Password <small>(Minimum 8 characters)</small>
                </label>
              </div>

              <div class="form-action">
                <button class="btn btn-warning text-white w-100">
                  கடவுச்சொல்லை மீட்டமைக்க <br />
                  Reset Password
                </button>

                <button
                  class="btn btn-secondary w-100 mt-2"
                  @click="backToSignin()"
                >
                  Back to Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <!--Forgot Password Change Card-->
      </div>
      <!--Col 1-->

      <!-- Col 2 -->
      <div class="col-sm-12 col-md-8 mb-3">
        <div class="card mb-3">
          <div class="card-header">
            <h5>
              📈 தமிழ் தகவல் பரவலாக்கம் 📈 <br />
             TAMIL Data Decentralization
            </h5>
          </div>
          <div class="card-body card-justify">
            <p>
              செம்மொழிகளுள் ஒன்றாகிய தமிழ்மொழி, எக்காலத்திற்கும் உகந்த மொழியாக
              வளர்ந்துள்ளது. இதே தனித் தன்மையில், எதிர்காலத்திலும் தொடர்ந்து
              வளர்ந்து, சிறப்பும் செழிப்பும் நிறைந்த மொழியாகத் திகழ வேண்டும்
              என்பதையும் கருத்தில் கொண்டு தமிழ் மொழியின் மேம்பாட்டிற்கும்
              வளர்ச்சிக்கும் உறுதுணையாக விளங்கி, சிறப்பாகச் செயல்படும் தமிழ்
              அமைப்புகளையும், தனிநபர்களையும், தமிழ் ஆர்வலர்களையும் ஒன்று
              திரட்டி,மொழி வளர்ச்சிக்கு வலுவூட்டும் நடவடிக்கைகளைத் தமிழ்ச்
              சமூகத்துடன் ஒன்றிணைந்து தமிழ் மொழியை கட்டச்சங்கிலி (Blockchain)
              தொழினுட்பத்திலும் இடம் பெற்று மிளிரச் செய்வதே TamilData.org
              செயல்திட்டமாகும்.
            </p>

   

            <br /><br />

            <div class="text-center">
              <h5>Manifesto for Tamil Data Decentralization Project</h5>
            </div>

            <p>
              Tamil Data Decentralization is a revolutionary initiative harnessing the power of
              decentralized Blockchain technology to upload and validate digital
              assets in the Tamil language. Tamil, one of the oldest living
              classical languages, is not only the origin of many modern
              languages but also possesses unique characteristics that classify
              it as a classical language. With the aim of preserving and
              promoting Tamil heritage and culture, TamilData.org empowers
              worldwide Tamil communities and language enthusiasts to
              participate in a global movement to safeguard the integrity of the
              Tamil language.
            </p>

            <h5>Mission:</h5>
            <p>
              Our mission is twofold: to protect and preserve the Tamil language
              and to empower global Tamil communities through decentralized
              technology. By leveraging blockchain and decentralized networks,
              TamilData.org aims to create a secure and immutable platform where
              digital assets in Tamil can be uploaded, validated, and rewarded,
              ensuring their authenticity and longevity.
            </p>

            <h5>Vision:</h5>
            <p>
              We envision a future where the Tamil language thrives in the
              digital age, where its rich heritage and cultural significance are
              celebrated and preserved for generations to come. Through the
              TamilData.org project, we strive to foster a global community of
              Tamil enthusiasts and language advocates who are committed to
              safeguarding Tamil's legacy and promoting its continued relevance
              in the modern world.
            </p>

            <h5>Key Principles:</h5>
            <ol>
              <li>
                <strong>Decentralization:</strong> TamilData.org operates on a
                decentralized peer-to-peer network, ensuring transparency,
                censorship resistance, and inclusivity.
              </li>
              <li>
                <strong>Preservation:</strong> We are dedicated to preserving
                the integrity and authenticity of the Tamil language by creating
                a secure platform for the upload and validation of digital
                assets.
              </li>
              <li>
                <strong>Inclusivity:</strong> TamilData.org welcomes participation
                from Tamils and Tamil lovers worldwide, fostering a sense of
                community and collaboration in the protection and promotion of
                the Tamil language.
              </li>
              <li>
                <strong>Rewards:</strong> Contributors to the TamilData.org network
                are rewarded with Tamil Data Decentralization Tokens for their valuable
                contributions, incentivizing active participation and
                engagement.
              </li>
              <li>
                <strong>Accessibility:</strong> TamilData.org is designed to be
                accessible to all, providing a user-friendly interface and
                support for a wide range of devices and platforms.
              </li>
            </ol>

            <h5>How it Works:</h5>
            <ol>
              <li>
                <strong>Upload:</strong> Users can upload digital assets such as
                documents, articles, audio recordings, videos, and more, in the
                Tamil language to the TamilData.org platform.
              </li>
              <li>
                <strong>Validation:</strong> Uploaded assets undergo a
                validation process to ensure their accuracy and authenticity,
                leveraging the collective wisdom and expertise of the TamilData.org
                community.
              </li>
              <li>
                <strong>Rewards:</strong> Contributors who actively participate
                in the validation process and contribute to the network's growth
                are rewarded with Tamil Data Decentralization Tokens, which can be redeemed for
                various incentives and rewards.
              </li>
              <li>
                <strong>Community Engagement:</strong> TamilData.org fosters
                community engagement through forums, discussions, and
                collaborative projects, encouraging knowledge sharing and
                collaboration among members.
              </li>
            </ol>

            <h5>Join the Movement:</h5>
            <p>
              TamilData.org invites all Tamils, Tamil enthusiasts, and language
              advocates to join us in our mission to preserve and promote the
              Tamil language. Together, we can harness the power of
              decentralized technology to safeguard Tamil's legacy for future
              generations and ensure its continued relevance in the digital age.
            </p>

            <p>
              <strong
                >Together, Let's Preserve Tamil Heritage with
                TamilData.org!</strong
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Col 2 -->
    </div>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import axios from "axios";

export default {
  name: "AuthWithSlots",
  data() {
    return {
      loginEmail: "",
      loginPassword: "",
      loginConformationCode: "",

      forgotPasswordEmail: "",
      forgotPasswordconfirmationCode: "",
      newPassword: "",

      referralId: "",
      viewCard: "signin",
    };
  },
  mounted() {
    var redirect = this.$route.query.redirect;
    if (redirect) {
      this.referralId = redirect.split("/")[2];
      if (redirect.split("/")[1] == "ref") {
        localStorage.setItem("refId", this.referralId);
      }
    }

    if (localStorage.refId !== undefined) {
      this.referralId = localStorage.refId;
    }
  },
  methods: {
    async signIn() {
      let loader = this.$loading.show();
      try {
        const user = await Auth.signIn(this.loginEmail, this.loginPassword);
        loader.hide();

        //Set localstorage user data
        localStorage.setItem("token", user.signInUserSession.idToken.jwtToken);
        localStorage.setItem(
          "tokenExp",
          user.signInUserSession.idToken.payload.exp
        );
        localStorage.setItem("email", user.attributes.email);
        this.getUserInfo(user.attributes.email);
        //Set localstorage user data

        if (localStorage.refId !== undefined) {
          this.$router.push("/ref/" + this.referralId);
        } else {
          this.$router.push("/dashboard");
        }
      } catch (error) {
        loader.hide();

        if (error.code == "UserNotFoundException") {
          Swal.fire({
            html:
              "<strong>தவறான உள்நுழைத்தல் !</strong> <br> மின்னஞ்சலினை சரிபார்க்கவும் <br> <br> <strong>Login Failed!</strong> <br> Please check your email.",
            icon: "error",
          });
          return false;
        }

        if (error.code == "NotAuthorizedException") {
          Swal.fire({
            html:
              "<strong>தவறான உள்நுழைத்தல் !</strong> <br> கடவுச்சொல்லினை சரிபார்க்கவும் <br /> <br /> <strong>Login Failed!</strong> <br> Please check your password.",
            icon: "error",
          });
          return false;
        }

        if (error.code == "UserNotConfirmedException") {
          this.viewCard = "signin-confirmation";
          return false;
        }
      }
    },
    async confirmSignUp() {
      let loader = this.$loading.show();
      try {
        await Auth.confirmSignUp(this.loginEmail, this.loginConformationCode);
        loader.hide();

        Swal.fire({
          html:
            "<strong>வாழ்த்துக்கள்!</strong> <br> உங்கள் கணக்கு செயல்படுத்தப்பட்டது. இப்போது நீங்கள் உங்கள் கணக்கில் உள்நுழையலாம் <br> <br>  <strong>Success!</strong> <br> your account has been acctivated. Now you can Login your account",
          icon: "success",
        }).then((result) => {
          let loader = this.$loading.show();
          location.reload();
        });
      } catch (error) {
        loader.hide();

        if (error.code == "CodeMismatchException") {
          Swal.fire({
            html:
              "தவறான உறுதிப்படுத்தல் குறியீடு!</strong> <br> சரியான உறுதிப்படுத்தல் குறியீட்டை உள்ளிடவும் <br> <br> <strong>Invalid confirmation code!</strong> <br> Please enter correct confirmation code.",
            icon: "error",
          });
          return false;
        }

        Swal.fire({
          html: error,
          icon: "error",
        });
      }
    },
    async resendConfirmationCode() {
      let loader = this.$loading.show();
      try {
        await Auth.resendSignUp(this.loginEmail);

        this.loginConformationCode = "";
        loader.hide();
        Swal.fire({
          html:
            "<strong>வெற்றி!</strong> <br> உறுதிப்படுத்தல் குறியீடு அனுப்பப்பட்டது. உங்கள் மின்னஞ்சலைச் சரிபார்க்கவும். <br> <br> <strong>Success!</strong> <br> confirmation code has been sent. check you email.",
          icon: "success",
        });
      } catch (error) {
        loader.hide();
        Swal.fire({
          html: error,
          icon: "error",
        });
      }
    },
    getUserInfo(email) {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/update-profile/?email=" +
            email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          localStorage.setItem("fullName", response.data[0].fullName);
          localStorage.setItem("mobile", response.data[0].mobile);
          localStorage.setItem("telegram", response.data[0].telegramUserName);
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    forgotpassword() {
      this.viewCard = "forgotpassword";
    },
    getForgotPasswordCode() {
      let loader = this.$loading.show();
      Auth.forgotPassword(this.forgotPasswordEmail)
        .then((data) => {
          this.viewCard = "forgotpasswordReset";
        })
        .catch((error) => {
          if (error.code == "UserNotFoundException") {
            Swal.fire({
              html:
                "தவறான மின்னஞ்சல் !</strong> <br> மின்னஞ்சல் முகவரியினை சரிபார்க்கவும் <br> <br> <strong>Incorrect Email!</strong> <br> Please check your email.",
              icon: "error",
            });

            return false;
          }

          if (error.code == "LimitExceededException") {
            Swal.fire({
              html:
                "<strong>பல உள்நுழைவு முயற்சிகள் !</strong> <br> தயவுசெய்து சிறிது நேரத்தின் பின் முயற்சிக்கவும். <br> <br> <strong>Too many login attempts !</strong> <br> please try again later.",
              icon: "error",
            });

            return false;
          }

          Swal.fire({
            html: error,
            icon: "error",
          });
        })
        .finally(() => {
          loader.hide();
        });
    },
    changePassword() {
      let loader = this.$loading.show();
      Auth.forgotPasswordSubmit(
        this.forgotPasswordEmail,
        this.forgotPasswordconfirmationCode,
        this.newPassword
      )
        .then((data) => {
          Swal.fire({
            html:
              "வாழ்த்துக்கள்!</strong> <br> உங்கள் கடவுச்சொல் வெற்றிகரமாக  மாற்றப்பட்டுள்ளது <br><br> <strong>Congratulations!</strong> <br> Your password has been changed successfully.",
            icon: "success",
          }).then((result) => {
            let loader = this.$loading.show();
            location.reload();
          });
        })
        .catch((error) => {
          if (error.code == "CodeMismatchException") {
            Swal.fire({
              html:
                "<strong>தவறான உறுதிப்படுத்தல் குறியீடு!</strong> <br> சரியான உறுதிப்படுத்தல் குறியீட்டை உள்ளிடவும் <br> <br> <strong>Invalid confirmation code!</strong> <br> Please enter correct confirmation code.",
              icon: "error",
            });

            return false;
          }

          if (error.code == "InvalidPasswordException") {
            Swal.fire({
              html:
                "<strong>மன்னிக்கவும்!</strong> <br> கடவுச்சொல் குறைந்தது 8 எழுத்துகள் கொண்டதாக இருத்தல் வேண்டும் <br> <br> <strong>Sorry!</strong> <br> Minimum password length 8 characters.",
              icon: "error",
            });

            return false;
          }

          Swal.fire({
            html: error,
            icon: "error",
          });
        })
        .finally(() => {
          loader.hide();
        });
    },
    backToSignin() {
      this.viewCard = "signin";
    },
  },
};
</script>

<style scoped>
.card-justify {
  text-align: justify;
}

.login-logo {
  width: 100px;
  height: auto;
}
</style>
