<template>
  <DashboardLayout>
    <!--Error Msg-->
    <div v-if="walletStatus == 'not-found'">
      <div class="alert alert-danger fade show mb-2" role="alert">
        <i class="fas fa-exclamation-triangle"></i> &nbsp;
        <strong
          >உங்கள் தமிழ் மெய் நிகர் நாணய சேமிப்பான் இணைக்கப் படவில்லை.</strong
        >
        <br />
        <router-link class="router-link" to="/create-wallet">
          சேமிப்பான் உருவாக்க/இணைக்க இங்கே அழுத்தவும்
        </router-link>
        <br />
        <br />
        <i class="fas fa-exclamation-triangle"></i> &nbsp;
        <strong>Wallet not found.</strong>
        <br />
        <router-link class="router-link" to="/create-wallet">
          Please create/update your wallet
        </router-link>
      </div>
    </div>

    <div
      v-if="walletStatus == 'not-activated' || walletStatus == 'not-approved'"
    >
      <div class="alert alert-danger fade show mb-2" role="alert">
        <i class="fas fa-exclamation-triangle"></i> &nbsp;
        <strong>Wallet not Activated.</strong>
        <br />
        <router-link class="router-link" to="/create-wallet">
          Please Activate your wallet
        </router-link>
      </div>
    </div>
    <!--Error Msg-->

    <div class="input-group mb-3">
      <span class="input-group-text" id="basic-addon1">
        <i class="fas fa-search"></i>
      </span>
      <input
        type="text"
        class="form-control"
        v-model="searchText"
        placeholder="Type to search..."
      />
    </div>

    <div class="row">
      <div
        v-for="(job, index) in filteredJobs"
        :key="index"
        class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2"
      >
        <div class="card bg-light mb-2">
          <div class="card-header">
            <span class="text-bold job-title">
              {{ job.jobTitle }}
            </span>
          </div>
          <div class="card-body">
            <span class="text-bold">வேலை விவரம் / Description</span>
            <p class="card-text">{{ job.jobDescription }}</p>

            <span class="text-bold">விதிமுறை / Terms</span>
            <p class="card-text">{{ job.terms }}</p>

            <span class="text-bold">இந்த வேலைக்குரிய ஊதியம் / Reward</span>
            <p class="card-text">
              {{ job.reward }}
              <img src="@/assets/logo.png" alt="TAMIL" width="20" />
            </p>

            <span class="text-bold">இறுதி தேதி / Due Date</span>
            <p class="card-text">{{ dateFormat(job.dueDate) }}</p>
          </div>
          <div class="card-footer">
            <div v-if="jobApplyStatus">
              <button
                class="btn btn-sm btn-block btn-secondary"
                v-if="job.applyButton == false"
                disabled
              >
                Own Job
              </button>
              <button
                class="btn btn-sm btn-block btn-danger"
                @click="applyJob(job.id)"
                v-else-if="job.appiled == false"
              >
                Apply
              </button>
              <button
                class="btn btn-sm btn-block btn-secondary"
                v-else
                disabled
              >
                Applied
              </button>

              <router-link
                class="btn btn-sm btn-block btn-warning text-white"
                :to="{
                  path: '/job-details',
                  query: { jobId: job.id },
                }"
              >
                View
              </router-link>

              <div class="btn-group mt-2 w-100" v-if="job.voted">
                <button
                  :class="{
                    'btn btn-danger w-50': job.voteType == 'Down',
                    'btn btn-outline-danger w-50': job.voteType != 'Down',
                  }"
                  disabled
                >
                  <i class="fas fa-thumbs-down"></i> {{ job.votes.downVotes }}
                </button>
                <button
                  :class="{
                    'btn btn-success w-50': job.voteType == 'Up',
                    'btn btn-outline-success w-50': job.voteType != 'Up',
                  }"
                  disabled
                >
                  <i class="fas fa-thumbs-up"></i> {{ job.votes.upVotes }}
                </button>
              </div>
              <div class="btn-group mt-2 w-100" v-else>
                <button
                  type="button"
                  class="btn btn-outline-danger w-50"
                  @click="voteJob(job.id, 'Down')"
                >
                  <i class="fas fa-thumbs-down"></i> {{ job.votes.downVotes }}
                </button>
                <button
                  type="button"
                  class="btn btn-outline-success w-50"
                  @click="voteJob(job.id, 'Up')"
                >
                  <i class="fas fa-thumbs-up"></i> {{ job.votes.upVotes }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "my-projects",
  data() {
    return {
      accessToken: "",
      email: "",
      jobs: [],
      allAppiledJobs: [],
      searchText: "",
      walletStatus: "",
      jobApplyStatus: "",
    };
  },
  computed: {
    filteredJobs() {
      return this.jobs.filter((job) =>
        job.jobTitle.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  },
  mounted() {
    this.email = localStorage.email;

    this.getWalletInfo();
    this.getAllJobs();
  },
  components: {
    DashboardLayout,
  },
  methods: {
    dateFormat(date) {
      return (
        date.split("-")[2] + "-" + date.split("-")[1] + "-" + date.split("-")[0]
      );
    },
    getAllJobs() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs/?postedBy=all",
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.jobs = response.data;
        })
        .catch(function(error) {
          //console.log(error);
          loader.hide();
        })
        .finally(() => {
          loader.hide();
          this.getAllAppiledJob();
        });
    },
    getAllAppiledJob() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs/job-applications/?jobId=all",
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.allAppiledJobs = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
          this.syncJobs();
        });
    },
    syncJobs() {
      const jobs = this.jobs;
      const appliedJobs = this.allAppiledJobs;
      const email = this.email;

      const filteredJobs = Array();
      $.each(jobs, function(index, item) {
        //Check Own Jobs
        if (email == item["postedBy"]) {
          item["applyButton"] = false;
        } else {
          item["applyButton"] = true;
        }
        //Check Own Jobs

        //Check Applied Jobs
        var matchingJobs = appliedJobs.filter(function(appliedItem) {
          return appliedItem.jobId == item.id && appliedItem.email == email;
        });

        if (matchingJobs.length > 0) {
          item["appiled"] = true;
        } else {
          item["appiled"] = false;
        }
        //Check Applied Jobs

        //Check Votes
        const votes = item.votes.votes;
        var matchingVotes = votes.filter(function(item) {
          return item.email == email;
        });

        if (matchingVotes.length > 0) {
          item["voted"] = true;
          item["voteType"] = matchingVotes[0]["vote"];
        } else {
          item["voted"] = false;
          item["voteType"] = "";
        }
        //Check Votes

        filteredJobs.push(item);
      });

      this.jobs = filteredJobs;
    },
    applyJob(jobId) {
      let loader = this.$loading.show();

      var today = new Date();
      const dataBody = {
        registerDate: today.toLocaleDateString("en-US"),
        email: this.email,
        jobId: jobId,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs/job-applications",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          var htmlMsg =
            "வேலை  விண்ணப்பம் சமர்ப்பிக்கப்பட்டது. <br /> <br /> Job application has been submitted.";

          Swal.fire({
            title: "Success",
            icon: "success",
            html: htmlMsg,
          }).then((result) => {
            if (result.isConfirmed) {
              this.getAllAppiledJob();
            }
          });
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getWalletInfo() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // GET wallet info using axios
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          const wallets = response.data;
          if (wallets.length > 0) {
            var activationStatus = wallets[0].activationStatus;
            var walletStatus = wallets[0].walletStatus;
            var requested = wallets[0].requested;

            if (walletStatus == "approved") {
              if (activationStatus == "pending") {
                this.jobApplyStatus = false;
                this.walletStatus = "not-activated";
              } else {
                this.jobApplyStatus = true;
                this.walletStatus = "activated";
              }
            } else {
              this.jobApplyStatus = false;
              this.walletStatus = "not-approved";
            }
          } else {
            this.jobApplyStatus = false;
            this.walletStatus = "not-found";
          }
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    voteJob(jobId, action) {
      let loader = this.$loading.show();

      var today = new Date();
      const dataBody = {
        registerDate: today.toLocaleDateString("en-US"),
        jobId: jobId,
        email: this.email,
        vote: action,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .post(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs/votes",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          var htmlMsg = "Job vote has been submitted.";

          Swal.fire({
            title: "Success",
            icon: "success",
            html: htmlMsg,
          }).then((result) => {
            if (result.isConfirmed) {
              this.getAllJobs();
            }
          });
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.text-bold {
  font-weight: bold;
  font-size: 15px;
}
.job-title {
  font-size: 18px;
}
.card-text {
  font-size: 14px;
}
.input-group-text {
  background-color: rgba(179, 179, 179, 0.993);
}
.card-border {
  border: 1px solid #ff9900;
}
.btn-warning {
  color: white;
}
.card {
  height: 100%;
}
.router-link {
  text-decoration: none;
  font-weight: bold;
}

.btn:disabled {
  opacity: 1 !important;
}
</style>
