<template>
  <div>
    <DashboardLayout>
      <div class="row">
        <div class="col-12">
          <a
            href="https://stellarterm.com/exchange/TAMIL-GCLWZL54VOAT3XU4AQCB5QEZSE326CHB2R4PROLRGTYIOVQMNH7YUFSL/XLM-native"
            target="__blank"
          >
            <img
              src="@/assets/images/dashboard/stellarterm.png"
              class="img-fluid shadow mb-2"
              alt="stellarterm"
            />
          </a>
        </div>
        <div class="col-12 mt-3 mb-3">
          <a
            href="/TamilData.org-purchase-steps-stellarterm-exchange.pdf"
            target="_blank"
            class="btn btn-warning btn-rounded mb-2"
          >
            <i class="fas fa-external-link-alt"></i> Purchase guide Stellarterm
            exchange
          </a>
        </div>

        <div class="col-12">
          <a
            href="https://interstellar.exchange/app/#/trade/guest/TAMIL/GCLWZL54VOAT3XU4AQCB5QEZSE326CHB2R4PROLRGTYIOVQMNH7YUFSL/XLM/native"
            target="__blank"
          >
            <img
              src="@/assets/images/interstellar-exchange.jpg"
              class="img-fluid shadow mb-2"
              alt="interstellar"
            />
          </a>
        </div>

        <div class="col-12 mt-3 mb-3">
          <a
            href="/TamilData.org-purchase-steps-interstellar-exchange.pdf"
            target="_blank"
            class="btn btn-warning btn-rounded mb-2"
          >
            <i class="fas fa-external-link-alt"></i> Purchase guide Interstellar
            exchange
          </a>
        </div>
      </div>
    </DashboardLayout>
  </div>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";

export default {
  name: "buytoken",
  components: {
    DashboardLayout,
  },
};
</script>
