<template>
  <MarketplaceLayout>
    <h2>Job SubCategories</h2>

    <div class="row">
      <div
        class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2"
        v-for="(jobSubCat, index) in jobSubCategories"
        :key="index"
      >
        <router-link
          class="no-decoration"
          :to="{
            path: '/marketplace-jobs',
            query: { jobSubCatId: jobSubCat.jobSubCatId },
          }"
        >
          <div class="card">
            <div class="card-body">
              <img
                src="../assets/images/project-pic.jpg"
                class="d-block w-100"
              />
              <br />
              <h5>{{ jobSubCat.text }}</h5>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </MarketplaceLayout>
</template>

<script>
import MarketplaceLayout from "../layouts/MarketplaceLayout";
import $ from "jquery";

export default {
  components: {
    MarketplaceLayout,
  },
  name: "marketplace-jobsubcategory",
  data() {
    return {
      jobSubCategories: [],
      jobCatId: "0",
    };
  },
  mounted() {
    var query = this.$route.query;
    if (Object.keys(query).length > 0) {
      //console.log(query);
      this.jobCatId = query.jobCatId;
    }

    this.jobSubCatGetInfo();
  },
  methods: {
    jobSubCatGetInfo() {
      var self = this;
      var seletedJobCatId = this.jobCatId;
      $.getJSON("jobCategory.json", function(json) {
        //console.log(json);
        json.forEach(function(item, index) {
          if (item.jobCatId == seletedJobCatId) {
            //console.log(item.nodes);
            self.jobSubCategories = item.nodes;
          }
        });
      }).fail(function(e) {
        console.log("Json Fail" + e);
      });
    },
  },
};
</script>
<style scoped>
.no-decoration {
  color: rgba(53, 49, 49, 0.774);
  text-decoration: none;
}
.card {
  height: 100%;
}
</style>
