<template>
  <DashboardLayout>
    <div class="container">
      <h2 class="mb-3">Only showing wallets with pending approval status.</h2>
      <div class="card">
        <div class="card-body table-responsive">
          <table class="table table-hovarable text-left" id="tblApproveWallet">
            <thead>
              <tr>
                <th>Wallet Name</th>
                <th>Email</th>
                <th>Wallet Address</th>
                <th>Created Date</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="wallet in approveWallets" :key="wallet.publicKey">
                <td>{{ wallet.walletName }}</td>
                <td>{{ wallet.email }}</td>
                <td>
                  <a
                    :href="
                      'https://stellar.expert/explorer/public/account/' +
                        wallet.publicKey
                    "
                    target="_blank"
                  >
                    {{ wallet.publicKey }}
                  </a>
                </td>
                <td>{{ wallet.dateCreated }}</td>

                <td>
                  <button
                    class="btn btn-secondary btn-block btn-sm"
                    v-if="wallet.reason != ''"
                    @click="showRequest(wallet.reason)"
                  >
                    View Request
                  </button>

                  <button
                    class="btn btn-success btn-block btn-sm"
                    @click="approveWallet(wallet.publicKey, wallet.email)"
                  >
                    Approve
                  </button>
                </td>
                <td>
                  <button
                    class="btn btn-secondary btn-block btn-sm"
                    @click="nodeStatus(wallet.email)"
                  >
                    Node Status
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "wallet",
  data: () => ({
    email: "",
    approveWallets: [],
    publicKey: "",
    privateKey: "",
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    this.email = localStorage.email;

    this.getApproveWallets();
    this.getWalletInfo();
  },
  methods: {
    getApproveWallets() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet/?email=approve-pending",
          {
            headers: headers,
          }
        )
        .then((response) => {
          this.approveWallets = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          $("#tblApproveWallet").DataTable();
          loader.hide();
        });
    },
    approveWallet(recePublicKey, receEmail) {
      var senderPubKey = this.publicKey;
      var senderPriKey = this.privateKey;

      Swal.fire({
        title: "Enter The Reason.",
        html: `
        <div class='form-group'>
          <select id="select-user-type" class="form-select mb-2">
            <option value="Contributor">Contributor</option>
            <option value="Staff">Staff</option>
          </select>
          <input type='text' id="input-public-key" class="form-control mb-2" placeholder="Enter Public Key" value='${senderPubKey}'>
          <input type='password' id="input-private-key" class="form-control mb-2" placeholder="Enter Private Key" value='${senderPriKey}'>
          <textarea id="textarea-reason" class="form-control" placeholder="Enter The Reason"></textarea>
        </div>
        `,
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        focusConfirm: false,
        preConfirm: () => {
          const userType = document.getElementById("select-user-type").value;
          const publicKey = document.getElementById("input-public-key").value;
          const privateKey = document.getElementById("input-private-key").value;
          const reason = document.getElementById("textarea-reason").value;

          if (!reason) {
            Swal.showValidationMessage("Reason cannot be empty");
          }

          if (!privateKey) {
            Swal.showValidationMessage("Private Key cannot be empty");
          }

          if (!publicKey) {
            Swal.showValidationMessage("Public Key cannot be empty");
          }
          if (!userType) {
            Swal.showValidationMessage("User Type cannot be empty");
          }

          return {
            userType: userType,
            publicKey: publicKey,
            privateKey: privateKey,
            reason: reason,
          };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show();

          const { userType, publicKey, privateKey, reason } = result.value;

          const dataBody = {
            receiving_address: recePublicKey,
            receiver_email: receEmail,
            sender_email: this.email,
            memo: reason,
            source_secret_key: privateKey,
            source_public_key: publicKey,
          };

          const headers = {
            Authorization: "Bearer " + localStorage.token,
          };

          axios
            .get("https://horizon.stellar.org/accounts/" + publicKey, {
              headers: headers,
            })
            .then((response) => {
              const balances = response.data.balances;
              let nativeBalance = null;

              balances.forEach((item) => {
                if (item.asset_type === "native") {
                  nativeBalance = item.balance;
                }
              });

              if (nativeBalance > 1) {
                this.approveWalletConfirm(dataBody);
              } else {
                Swal.fire({
                  title: "Error",
                  text: "Balance isn't enough to approve.",
                  icon: "error",
                });
              }
            })
            .catch(function(error) {
              //console.log(error);
              Swal.fire({
                title: "Error",
                text: "Wallet Approved Failed.",
                icon: "error",
              });
            })
            .finally(() => {
              loader.hide();
            });
        }
      });
    },
    approveWalletConfirm(dataBody) {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .post(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/transfer-xlm",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);

          Swal.fire({
            title: "Success",
            text: "Wallet Approved Success.",
            icon: "success",
          });
        })
        .catch((error) => {
          //console.log(error);

          Swal.fire({
            title: "Error",
            text: "Wallet Approved Failed.",
            icon: "error",
          });
        })
        .finally(() => {
          loader.hide();
        });
    },
    getWalletInfo() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          this.publicKey = response.data[0].publicKey;
          //this.privateKey = response.data[0].privateKey;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    showRequest(requestReason) {
      Swal.fire({
        title: "Approval Request",
        text: requestReason,
      });
    },
    nodeStatus(email) {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      //email = "kavashgar@gmail.com";
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/node-stats/?email=" +
            email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          const nodeData = response.data;

          if (nodeData.length > 0) {
            const node = nodeData[0];

            var fileCount = node.numFiles;
            var registerDate = node.registerDate;
            var totalFileSize = bytesToMegabytes(node.totalFileSize);
            var totalUpTime = minutesToHours(node.totalUpTime);

            var nodeStatusText = `<h2>Node Information</h2>
                                  <p><strong>Register Date:</strong> ${registerDate}</p>
                                  <p><strong>Number of Files:</strong> ${fileCount}</p>
                                  <p><strong>Total File Size:</strong> ${totalFileSize}</p>
                                  <p><strong>Total Up Time:</strong> ${totalUpTime}</p>
                                    `;

            Swal.fire({
              html: nodeStatusText,
              icon: "success",
            });
          } else {
            Swal.fire({
              text: "Node Data Not Found",
              icon: "warning",
            });
          }
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};

function bytesToMegabytes(bytes) {
  return (bytes / (1024 * 1024)).toFixed(2);
}

function minutesToHours(minutes) {
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = minutes % 60;

  if (hours > 0) {
    return hours + " Hrs " + remainingMinutes.toFixed() + " Min";
  } else {
    return remainingMinutes.toFixed() + " Min";
  }
}
</script>

<style scoped>
#tblApproveWallet {
  width: 100%;
}
#tblApproveWallet td {
  vertical-align: middle;
}
</style>
