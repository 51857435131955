<template>
  <!-- Sidebar -->
  <ul
    class="
      navbar-nav
      bg-gradient-danger
      sidebar sidebar-dark
      accordion
      hidden-print
    "
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <a
      class="
        sidebar-brand
        d-flex
        align-items-center
        justify-content-center
        mt-4
        mb-3
      "
      href=""
    >
      <div class="sidebar-brand-icon">
        <router-link class="nav-link" to="/dashboard">
          <img
            alt="Brand"
            src="@/assets/logo.png"
            style="max-width: 100px; max-height: 100px"
            class="hidden-xs"
          />
        </router-link>
      </div>
      <div class="sidebar-brand-text mx-3"></div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0 mb-3 mt-3" />

    <li class="nav-item">
      <router-link
        class="nav-link"
        to="/dashboard"
        :class="activeClass('dashboard')"
      >
        <i class="fas fa-tachometer-alt"></i>
        Dashboard
      </router-link>
    </li>

    <li class="nav-item">
      <router-link
        class="nav-link"
        to="/profile"
        :class="activeClass('profile')"
      >
        <i class="far fa-id-badge"></i>
        Update Profile
      </router-link>
    </li>

    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseWallet"
        aria-expanded="true"
        aria-controls="collapseWallet"
        :class="
          activeClass('create-wallet', 'import-wallet', 'transfer-wallet')
        "
      >
        <i class="fas fa-wallet"></i> Manage Wallet <span class="caret"></span>
      </a>

      <div
        id="collapseWallet"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <router-link
            to="/create-wallet"
            class="collapse-item"
            :class="activeItemClass('create-wallet')"
          >
            View Wallet
          </router-link>

          <!--
          <router-link to="/import-wallet" class="collapse-item">
            Import Wallet
          </router-link>

          <router-link to="/pending-claims" class="collapse-item">
            Pending Claim
          </router-link>

          <router-link to="/transfer-wallet" class="collapse-item">
            Transfer
          </router-link>
          -->

          <a :href="transactionLink" target="_blank" class="collapse-item">
            Transactions
          </a>
        </div>
      </div>
    </li>

    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseDataDex"
        aria-expanded="true"
        aria-controls="collapseDataDex"
        :class="
          activeClass(
            'catalogue',
            'data-dex-uploads',
            'my-node-status',
            'download-node',
            'validate-assets',
            'my-validation'
          )
        "
      >
        <i class="fas fa-database"></i> Data Dex <span class="caret"></span>
      </a>

      <div
        id="collapseDataDex"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <router-link
            to="/catalogue"
            class="collapse-item"
            :class="activeItemClass('catalogue')"
          >
            Data Catalogue
          </router-link>

          <router-link
            to="/data-dex-uploads"
            class="collapse-item"
            :class="activeItemClass('data-dex-uploads')"
          >
            My Uploads
          </router-link>

          <router-link
            to="/my-node-status"
            class="collapse-item"
            :class="activeItemClass('my-node-status')"
          >
            My Node Status
          </router-link>

          <router-link
            to="/download-node"
            class="collapse-item"
            :class="activeItemClass('download-node')"
          >
            Download Node Runner
          </router-link>

          <router-link
            to="/validate-assets"
            class="collapse-item"
            :class="activeItemClass('validate-assets')"
          >
            Validate Assets
          </router-link>

          <router-link
            to="/my-validation"
            class="collapse-item"
            :class="activeItemClass('my-validation')"
          >
            My Validation
          </router-link>
        </div>
      </div>
    </li>

    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseViewJobs"
        aria-expanded="true"
        aria-controls="collapseViewJobs"
        :class="activeClass('view-jobs', 'view-applied-jobs', 'jobs')"
      >
        <i class="fas fa-briefcase"></i> Jobs <span class="caret"></span>
      </a>

      <div
        id="collapseViewJobs"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <router-link
            to="/jobs"
            class="collapse-item"
            :class="activeItemClass('jobs')"
          >
            Post Jobs
          </router-link>

          <router-link
            to="/view-jobs"
            class="collapse-item"
            :class="activeItemClass('view-jobs')"
          >
            View All Jobs
          </router-link>

          <!--
          <router-link to="/view-applied-jobs" class="collapse-item">
            View Appiled Jobs
          </router-link>
          -->
        </div>
      </div>
    </li>

    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseReferals"
        aria-expanded="true"
        aria-controls="collapseReferals"
        :class="
          activeClass('my-referrals', 'affiliate-register', 'social-posting')
        "
      >
        <i class="fas fa-bookmark"></i> Share <span class="caret"></span>
      </a>

      <div
        id="collapseReferals"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <router-link
            to="/my-referrals"
            class="collapse-item"
            :class="activeItemClass('my-referrals')"
          >
            My Referrals
          </router-link>

          <router-link
            to="/affiliate-register"
            class="collapse-item"
            :class="activeItemClass('affiliate-register')"
          >
            Affiliate
          </router-link>

          <router-link
            to="/social-posting"
            class="collapse-item"
            :class="activeItemClass('social-posting')"
          >
            Social Share
          </router-link>
        </div>
      </div>
    </li>

    <li class="nav-item" v-if="userData.admin == 'y'">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseAdmin"
        aria-expanded="true"
        aria-controls="collapseAdmin"
        :class="
          activeClass('approve-wallet', 'manage-accounts', 'manage-invitation')
        "
      >
        <i class="fas fa-user-cog"></i> Admin <span class="caret"></span>
      </a>

      <div
        id="collapseAdmin"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <router-link
            to="/approve-wallet"
            class="collapse-item"
            :class="activeItemClass('approve-wallet')"
          >
            Approve Wallet
          </router-link>

          <router-link
            to="/manage-accounts"
            class="collapse-item"
            :class="activeItemClass('manage-accounts')"
          >
            Manage Accounts
          </router-link>

          <router-link
            to="/manage-invitation"
            class="collapse-item"
            :class="activeItemClass('manage-invitation')"
          >
            Manage Invitation
          </router-link>

          <router-link
            to="/node-status-reward"
            class="collapse-item"
            :class="activeItemClass('node-status-reward')"
          >
            Node Status and Reward
          </router-link>

          <router-link
            to="/manage-partners"
            class="collapse-item"
            :class="activeItemClass('manage-partners')"
          >
            Manage Partners
          </router-link>

          <router-link
            to="/social-posts"
            class="collapse-item"
            :class="activeItemClass('social-posts')"
          >
            Social Posts
          </router-link>
        </div>
      </div>
    </li>

    <li class="nav-item">
      <router-link
        class="nav-link"
        to="/change-password"
        :class="activeClass('change-password')"
      >
        <i class="fas fa-key"></i>
        Change Password
      </router-link>

      <!-- Divider -->
      <hr class="sidebar-divider d-none d-md-block" />

      <!-- Sidebar Toggler (Sidebar) -->
      <div class="text-center d-none d-md-inline">
        <button class="rounded-circle border-0" id="sidebarToggle"></button>
      </div>
    </li>
  </ul>

  <!-- End of Sidebar -->
</template>

<script>
import axios from "axios";

export default {
  name: "sidebar",
  data() {
    return {
      email: "",
      transactionLink: "",
      userData: {},
    };
  },
  mounted() {
    this.email = localStorage.email;
    this.getWalletInfo();
    this.getInfo();
  },
  methods: {
    activeClass: function(...names) {
      for (let name of names) {
        if (name == this.$route.name) return "active";
      }
    },
    activeItemClass: function(...names) {
      for (let name of names) {
        if (name == this.$route.name) return "side-item-active";
      }
    },
    getWalletInfo() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          var walletAddress = response.data[0].publicKey;
          this.transactionLink = `https://stellar.expert/explorer/public/account/${walletAddress}`;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getInfo() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/update-profile/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          const userData = response.data;
          if (userData.length > 0) {
            this.userData = response.data[0];
          } else {
            this.userData = {
              admin: "n",
            };
          }
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.active {
  background-color: #ff9c01;
  border-color: #ff9c01;
  color: white !important;
  font-weight: bold;
}

.sidebar-dark .nav-item .active i {
  color: white !important;
  font-weight: bold;
}

.side-item-active {
  background-color: #b9b9b9;
  font-weight: bold;
}

hr {
  background-color: cornsilk;
}
</style>
