<template>
  <div>
    <MarketplaceLayout>
      <div class="dropdown">
        <button
          class="btn btn-warning btn-block dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Menu
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li class="dropdown-item">
            <router-link class="dropdown-item" to="/marketplace">
              Jobs
            </router-link>
          </li>
        </ul>
      </div>
    </MarketplaceLayout>
  </div>
</template>

<script>
import MarketplaceLayout from "../layouts/MarketplaceLayout";

export default {
  name: "wallet",
  data: () => ({
    data: "",
  }),
  components: {
    MarketplaceLayout,
  },
};
</script>
<style scoped>
.dropdown-menu {
  width: 100vw;
}
</style>