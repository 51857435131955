<template>
  <DashboardLayout>
    <div class="container">
      <div class="mt-5 mb-4">
        <form @submit.prevent="changePasswordFunction">
          <div class="card">
            <div class="card-header">
              <h5>
                கடவுச்சொல் மாற்றம்
                <br />
                Change Password
              </h5>
            </div>
            <div class="card-body">
              <div class="form-floating mb-3">
                <input
                  type="password"
                  class="form-control"
                  v-model="oldPassword"
                  placeholder="Enter Current Password..."
                  required
                />
                <label for="oldPassword">Current Password</label>
              </div>

              <div class="form-floating mb-3">
                <input
                  type="password"
                  class="form-control"
                  v-model="newPassword"
                  placeholder="Enter New Password..."
                  required
                />
                <label for="newPassword">New Password</label>
              </div>

              <!--Success Msg-->
              <div v-show="successMsg">
                <div class="alert alert-success fade show" role="alert">
                  <i class="fas fa-check-circle"></i> &nbsp;
                  <strong>வெற்றி!</strong> உங்கள் கடவுச்சொல் மாற்றப்பட்டது.
                  <br />
                  <br />
                  <i class="fas fa-check-circle"></i> &nbsp;
                  <strong>Success!</strong> Your password has been changed.
                </div>
              </div>
              <!--Success Msg-->

              <!--Error Msg-->
              <div class="mt-3" v-show="errorMsg">
                <div class="alert alert-warning fade show" role="alert">
                  <i class="fas fa-exclamation-triangle"></i> &nbsp;
                  <strong>மன்னிக்கவும்!</strong> நீங்கள் தவறான கடவுச்சொல்லை
                  உள்ளிட்டுள்ளீர்கள்.
                  <br />
                  <br />
                  <i class="fas fa-exclamation-triangle"></i> &nbsp;
                  <strong>Sorry!</strong> you have entered wrong password.
                </div>
              </div>
              <!--Error Msg-->

              <!--Error Msg-->
              <div class="mt-3" v-show="errorMsgLimited">
                <div class="alert alert-warning fade show" role="alert">
                  <i class="fas fa-exclamation-triangle"></i> &nbsp;
                  <strong>பல கடவுச்சொல் மாற்றுதல் முயற்சிகள் !</strong>
                  தயவுசெய்து சிறிது நேரத்தின் பின் முயற்சிக்கவும். <br />
                  <br />

                  <i class="fas fa-exclamation-triangle"></i> &nbsp;
                  <strong>Too many password change attempts !</strong> Please
                  try again later.
                </div>
              </div>
              <!--Error Msg-->

              <div class="mb-3">
                <button class="btn btn-warning btn-lg btn-block">
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import { Auth } from "aws-amplify";

export default {
  name: "profile",
  data() {
    return {
      email: "",
      successMsg: false,
      errorMsg: false,
      errorMsgLimited: false,
      newPassword: "",
      oldPassword: "",
    };
  },
  beforeCreate() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        (this.user = user), (this.email = user.attributes.email);
      })
      .catch(() => console.log("not signed in..."));
  },
  components: {
    DashboardLayout,
  },
  methods: {
    changePasswordFunction() {
      let loader = this.$loading.show();
      this.errorMsg = false;
      this.successMsg = false;
      this.errorMsgLimited = false;

      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, this.oldPassword, this.newPassword);
        })
        .then((data) => {
          //console.log(data);
          this.successMsg = true;
        })
        .catch((err) => {
          //console.log(err);
          if (err.code == "NotAuthorizedException") {
            this.errorMsg = true;
          }
          if (err.code == "LimitExceededException") {
            this.errorMsgLimited = true;
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.text-center {
  align-items: center;
}
</style>
