<template>
  <DashboardLayout>
    <div class="container">
      <div class="card">
        <div class="card-body" id="table-card">
          <table class="table table-hovarable" id="tblMyRewards">
            <thead>
              <tr>
                <th>Reward Date</th>
                <th>From Email</th>
                <th>Wallet Address</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="reward in myRewards" :key="reward.id">
                <td>{{ reward.trxDate }}</td>
                <td>{{ reward.email }}</td>
                <td>{{ reward.fromWallet }}</td>
                <td>{{ reward.amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "wallet",
  data: () => ({
    email: "",
    myRewards: [],
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    this.email = localStorage.email;

    this.getMyRewards();
  },
  methods: {
    getMyRewards() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/my-rewards/?email=dest&destEmail=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          this.myRewards = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          $("#tblMyRewards").DataTable();
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
#tblMyRewards {
  width: 100%;
}
#tblMyRewards td {
  vertical-align: middle;
}

#table-card {
  overflow-x: scroll;
}
</style>
