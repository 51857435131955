<template>
  <DashboardLayout>
    <div class="container">
      <div class="card">
        <div class="card-body text-center">
          <div class="mb-3 text-left">
            <label for="walletName">Wallet Name</label>
            <input
              type="text"
              class="form-control"
              id="walletName"
              placeholder="Enter wallet name"
              autocomplete="off"
              v-model="walletName"
            />
          </div>

          <div class="mb-3 text-left">
            <label for="walletName">Wallet Address</label>
            <input
              type="text"
              class="form-control"
              id="walletAddress"
              placeholder="Wallet Address"
              v-model="walletAddress"
              autocomplete="off"
            />
          </div>

          <div class="mb-3 hidden">
            <div class="text-left">
              <label for="privateKey"> Private Key (Optional) </label>
              <div class="form-group input-group">
                <input
                  type="password"
                  class="form-control"
                  id="privateKeyImport"
                  placeholder="Private Key"
                  v-model="privateKey"
                  autocomplete="off"
                />
                <div class="input-group-prepend">
                  <span
                    class="input-group-text required"
                    id="inputGroup-sizing-default"
                  >
                    <a href="#" type="button" id="togglePasswordImport">
                      <i class="far fa-eye"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <small> (Only required if you involve in transactions) </small>
            <br />
            <small>
              Note: Private Key will be stored in encrypted secure data store
            </small>
          </div>

          <div class="form-action mb-3">
            <button
              class="btn btn-warning"
              type="button"
              @click="addWallet"
              id="btnAddWallet"
            >
              Update Wallet
            </button>
          </div>

          <!--Success Msg-->
          <div v-show="walletAdded">
            <div class="alert alert-success fade show" role="alert">
              <i class="fas fa-check-circle"></i> &nbsp;
              <strong>வெற்றி!</strong> பணப்பை விவரங்கள் புதுப்பிக்கப்பட்டது.
              <br />
              <br />
              <i class="fas fa-check-circle"></i> &nbsp;
              <strong>Success!</strong> Wallet details has been updated.
            </div>
          </div>
          <!--Success Msg-->
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

export default {
  name: "wallet",
  data: () => ({
    walletName: "",
    email: "",
    publicKey: "",
    walletAddress: "",
    privateKey: "",
    dateCreated: "",
    walletAdded: false,
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getWalletInfo();
    }
  },
  methods: {
    addWallet() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      var today = new Date();
      axios
        .post(
          `https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/add-wallet`,
          {
            walletName: this.walletName,
            email: this.email,
            publicKey: this.walletAddress,
            privateKey: "-",
            dateCreated: today.toLocaleDateString("en-US"),
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.walletAdded = true;
        })
        .catch((error) => {
          //console.log(error);
          this.walletAdded = false;
        })
        .finally(() => {
          this.getWalletInfo();
          loader.hide();
        });
    },
    getWalletInfo() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // GET wallet info using axios
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.walletName = response.data[0].walletName;
          this.dateCreated = response.data[0].dateCreated;
          this.publicKey = response.data[0].publicKey;
          this.walletAddress = response.data[0].publicKey;
          this.privateKey = response.data[0].privateKey;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};

$("body").on("click", "#togglePasswordImport", function() {
  var type = $("#privateKeyImport").attr("type");
  if (type == "text") {
    $("#privateKeyImport").attr("type", "password");
    $("#togglePasswordImport").html("<i class='far fa-eye'></i>");
  } else {
    $("#privateKeyImport").attr("type", "text");
    $("#togglePasswordImport").html("<i class='far fa-eye-slash'></i>");
  }
});
</script>

<style scoped>
.card-body {
  text-align: left;
}
.form-group {
  text-align: left;
}
.btn-light {
  background-color: rgba(255, 255, 255, 0.664);
}
.text-right {
  text-align: right;
}
.break-text {
  word-wrap: break-word;
}
.note-wallet-text {
  color: red;
  font-weight: bold;
}

.text-left label {
  font-size: 15px;
  margin-left: 5px;
  color: rgb(105, 102, 102);
}
</style>
