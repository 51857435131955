<template>
  <DashboardLayout>
    <div class="row">
      <!-- Col 1 -->
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card mb-2">
          <div class="card-header">
            <strong>
              <span v-show="!updateBtn">New</span>
              <span v-show="updateBtn">Update</span>
              Job
            </strong>
          </div>
          <div class="card-body">
            <input type="hidden" v-model="jobId" />

            <div class="form-group text-left mb-3">
              <label for="jobTitle">Job Title / வேலை தலைப்பு</label>
              <input
                type="text"
                class="form-control"
                id="jobTitle"
                v-model="jobTitle"
                placeholder="Enter Your Job Title..."
                required
              />
            </div>

            <div class="form-group text-left mb-3">
              <label for="Job Category">Job Category / வேலை பிரிவு</label>
              <select
                class="form-select"
                aria-label="Default select example"
                id="txtJobMainCat"
                v-model="selectedJobCategory"
                required
                @change="jobCatChange"
              >
                <option value="0" hidden selected>Choose</option>
                <option
                  v-for="jobCategory in jobCategories"
                  v-bind:key="jobCategory.jobCatId"
                  :value="jobCategory.jobCatId"
                >
                  {{ jobCategory.text }}
                </option>
              </select>
            </div>

            <div class="form-group text-left mb-3">
              <label for="Job Category"
                >Job SubCategory / வேலை துணைப்பிரிவு</label
              >
              <select
                class="form-select"
                aria-label="Default select example"
                id="txtJobSubCat"
                v-model="selectedJobSubCategory"
                required
              >
                <option value="0" hidden selected>Choose</option>
                <option
                  v-for="jobSubCategory in jobSubCategories"
                  v-bind:key="jobSubCategory.jobSubCatId"
                  :value="jobSubCategory.jobSubCatId"
                >
                  {{ jobSubCategory.text }}
                </option>
              </select>
            </div>

            <div class="form-group text-left mb-3">
              <label for="jobDesc"> Job Description / வேலை விவரம் </label>
              <textarea
                class="form-control"
                id="jobDesc"
                v-model="jobDesc"
                placeholder="Enter Your Job Description..."
                style="height: 100px"
                required
              />
            </div>

            <div class="form-group text-left mb-3">
              <label for="jobTerms"> Terms / விதிமுறை </label>
              <textarea
                class="form-control"
                id="jobTerms"
                v-model="jobTerms"
                placeholder="Enter Your Job Terms..."
                style="height: 100px"
                required
              />
            </div>

            <div class="form-group text-left mb-3">
              <label for="reward">
                Reward / வெகுமதி
                <br />
                <small>
                  இந்த வேலைக்கான ஊதியம் (தமிழ் மெய் நிகர் நாணயங்கள்)
                </small>
              </label>
              <input
                type="text"
                class="form-control"
                id="reward"
                v-model="reward"
                placeholder="Enter Your Job Reward..."
                required
              />
            </div>

            <div class="form-group text-left mb-3">
              <label for="dueDate">Due Date / இறுதி தேதி</label>
              <input
                type="date"
                class="form-control"
                id="dueDate"
                v-model="dueDate"
                placeholder="Enter Your Job Due Date..."
                required
              />
            </div>

            <!--Success Msg-->
            <div v-show="updateMsg" class="mb-3">
              <div class="alert alert-success fade show" role="alert">
                <i class="fas fa-check-circle"></i> &nbsp;
                <strong>வெற்றி!</strong> வேலை விவரங்கள் பதிவேற்றப்பட்டன.

                <br />
                <br />

                <i class="fas fa-check-circle"></i> &nbsp;
                <strong>Success!</strong> Job details has been updated.
              </div>
            </div>
            <!--Success Msg-->

            <div class="mb-3" v-show="!updateBtn">
              <button
                class="btn btn-danger btn-block"
                type="button"
                @click="postJob"
              >
                Post
              </button>
            </div>

            <div class="mb-3" v-show="updateBtn">
              <button
                class="btn btn-danger btn-block"
                type="button"
                @click="updateJob"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Col 1 -->

      <!-- Col 2 -->
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card mb-2">
          <div class="card-header">
            <strong>My Jobs</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div v-for="myJob in myJobs" :key="myJob.postedBy" class="col-12">
                <div class="card card-jobs bg-light mb-2">
                  <div class="card-header">
                    <span class="text-bold job-title">
                      {{ myJob.jobTitle }}
                    </span>
                  </div>
                  <div class="card-body">
                    <span class="text-bold">Description</span>
                    <p class="card-text">{{ myJob.jobDescription }}</p>

                    <span class="text-bold">Terms</span>
                    <p class="card-text">{{ myJob.terms }}</p>

                    <span class="text-bold">Reward</span>
                    <p class="card-text">{{ myJob.reward }}</p>

                    <span class="text-bold">Due Date</span>
                    <p class="card-text">{{ myJob.dueDate }}</p>
                  </div>
                  <div class="card-footer">
                    <router-link
                      class="btn btn-sm btn-warning m-1"
                      :to="{
                        path: '/upload-media',
                        query: { mediaId: myJob.id, mediaType: 'jobs' },
                      }"
                    >
                      Upload Picture
                    </router-link>

                    <button
                      class="btn btn-sm btn-danger m-1"
                      @click="
                        editJobInfo(
                          myJob.id,
                          myJob.jobTitle,
                          myJob.jobDescription,
                          myJob.terms,
                          myJob.reward,
                          myJob.dueDate,
                          myJob.jobCatId,
                          myJob.jobSubCatId
                        )
                      "
                    >
                      Edit
                    </button>

                    <button
                      class="btn btn-sm btn-block btn-danger m-1"
                      data-toggle="modal"
                      data-target="#jobApplicationsModal"
                      :data-jobId="myJob.id"
                    >
                      Applications - {{ myJob.applications }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Col 2 -->
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="jobApplicationsModal"
      tabindex="-1"
      aria-labelledby="jobApplicationsModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Job Applications</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <table class="table table-striped" style="width: 100%">
                <thead>
                  <tr>
                    <th style="width: 30%">User Name</th>
                    <th style="width: 30%">Applied Date</th>
                    <th style="width: 20%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(application, index) in jobApplications"
                    :key="index"
                  >
                    <td>{{ application.email }}</td>
                    <td>
                      {{ application.registerDate }}
                    </td>
                    <td>
                      <div v-if="application.jobStatus == 'p'">
                        <div v-if="application.acceptStatus == 'n'">
                          <button
                            class="btn btn-success btn-sm m-1"
                            data-dismiss="modal"
                            @click="applicationAction(application.id, 'y')"
                          >
                            Accept
                          </button>
                          <button
                            class="btn btn-danger btn-sm m-1"
                            data-dismiss="modal"
                            @click="applicationAction(application.id, 'n')"
                          >
                            Reject
                          </button>
                        </div>
                        <div v-else>
                          <button class="btn btn-success btn-sm m-1" disabled>
                            Accepted
                          </button>
                        </div>
                      </div>
                      <div v-else-if="application.jobStatus == 'c'">
                        <button class="btn btn-success btn-sm m-1" disabled>
                          Paid
                        </button>
                      </div>
                      <div v-else>
                        <button
                          class="btn btn-success btn-sm btn-block"
                          data-toggle="modal"
                          data-target="#jobPaymentModal"
                          :data-status="application.jobStatus"
                          :data-amount="application.amount"
                          :data-id="application.id"
                        >
                          Pay
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="jobPaymentModal"
      tabindex="-1"
      aria-labelledby="jobPaymentModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-modal">
          <div class="modal-header">
            <h5 class="modal-title">Job Payment</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="privateKey">Enter PrivateKey</label>
              <input
                type="text"
                class="form-control bg-light-int"
                v-model="sourcePrivateKey"
              />
            </div>
            <div class="form-group">
              <button
                class="btn btn-secondary btn-sm btn-block"
                id="btnTranferPay"
                @click="jobPayClimable()"
              >
                Pay
              </button>
            </div>

            <!--Error Msg-->
            <div v-show="payErrorMsg">
              <div class="alert alert-danger fade show" role="alert">
                <i class="fas fa-exclamation-triangle"></i> &nbsp;
                <strong>
                  உங்கள் தமிழ் மெய் நிகர் நாணய பரிவர்த்தனை நடைபெறவில்லை :)
                  <br />
                  பின்வரும் காரணங்களை சரி பாருங்கள்
                </strong>
                <ol>
                  <li>உங்கள் கணக்கில் போதுமான மீதி இருக்கா என்று பாருங்கள்.</li>
                  <li>
                    உங்கள் wallet பரிவர்த்தனைக்காக activate செய்யப்பட்டுள்ளதா
                    என்று பாருங்கள்.
                    <br />
                    <a
                      href="https://www.youtube.com/watch?v=oMmiK8Cwj_4&list=PLXJAY0heh08uL-24KJmg3atnmWCUQ4z_6&index=3"
                      target="_blank"
                    >
                      உதவி காணொளி <i class="fab fa-youtube"></i>
                    </a>
                  </li>
                </ol>
                <i class="fas fa-exclamation-triangle"></i> &nbsp;
                <strong>Payment transaction failed.</strong>
              </div>
            </div>
            <!--Error Msg-->

            <!--Success Msg-->
            <div v-show="tranferSuccessMsg">
              <div class="alert alert-success fade show" role="alert">
                <i class="fas fa-check-circle"></i> &nbsp;
                <strong>Success!</strong> amount paid.
              </div>
            </div>
            <!--Success Msg-->
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";
import freighterApi from "@stellar/freighter-api";
import {
  isConnected,
  getPublicKey,
  signTransaction,
} from "@stellar/freighter-api";

export default {
  name: "my-projects",
  data() {
    return {
      jobId: "",
      email: "",
      updateMsg: false,
      jobTitle: "",
      jobDesc: "",
      jobTerms: "",
      reward: "",
      dueDate: "",
      sourcePrivateKey: "",
      myJobs: [],
      updateBtn: false,
      payErrorMsg: false,
      tranferSuccessMsg: false,
      selectedJobCategory: "0",
      jobCategories: [],
      selectedJobSubCategory: "0",
      jobSubCategories: [],
      allAppiledJobs: [],
      jobApplications: [],
    };
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getAllAppiledJob();
      this.getMyJobs();
      this.jobCatGet();
      this.getWalletInfo();
    }

    var inline = this;
    //Job Application Modal
    $("#jobApplicationsModal").on("shown.bs.modal", function(e) {
      var jobId = $(e.relatedTarget).attr("data-jobId");
      inline.getApllications(jobId);
    });

    //Job Payment Modal
    $("#jobPaymentModal").on("shown.bs.modal", function(e) {
      var status = $(e.relatedTarget).attr("data-status");
      var amount = $(e.relatedTarget).attr("data-amount");
      var id = $(e.relatedTarget).attr("data-id");
      $("#btnTranferPay").attr("data-status", status);
      $("#btnTranferPay").attr("data-amount", amount);
      $("#btnTranferPay").attr("data-id", id);
    });

    //Job Payment Modal close
    $("#jobPaymentModal").on("hidden.bs.modal", function(e) {
      inline.payErrorMsg = false;
      var paid = $("#btnTranferPay").attr("data-paid");
      if (paid == 1) {
        location.reload();
      }
    });
  },
  components: {
    DashboardLayout,
  },
  methods: {
    postJob() {
      let loader = this.$loading.show();
      var today = new Date();

      const dataBody = {
        registerDate: today.toLocaleDateString("en-US"),
        postedBy: this.email,
        jobTitle: this.jobTitle,
        jobCatId: this.selectedJobCategory,
        jobSubCatId: this.selectedJobSubCategory,
        jobDescription: this.jobDesc,
        terms: this.jobTerms,
        reward: this.reward,
        dueDate: this.dueDate,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.updateMsg = true;

          this.jobTitle = "";
          this.jobDesc = "";
          this.jobTerms = "";
          this.reward = "";
          this.dueDate = "";
          this.selectedJobSubCategory = "0";
          this.selectedJobCategory = "0";
        })
        .catch((error) => {
          //console.log(error.response);
          this.updateMsg = false;
        })
        .finally(() => {
          loader.hide();
          this.getMyJobs();
        });
    },
    getMyJobs() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      // GET request using axios with async/await
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs/?postedBy=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.myJobs = response.data;
          this.syncAppliedCount();
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getAllAppiledJob() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs/job-applications/?jobId=all",
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.allAppiledJobs = response.data;
          this.syncAppliedCount();
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    editJobInfo(id, title, desc, terms, reward, dueDate, Cat, SubCat) {
      let loader = this.$loading.show();
      this.jobId = id;
      this.jobTitle = title;
      this.jobDesc = desc;
      this.jobTerms = terms;
      this.reward = reward;
      this.dueDate = dueDate;
      this.selectedJobCategory = Cat;
      this.selectedJobSubCategory = SubCat;
      this.updateBtn = true;
      loader.hide();
    },
    updateJob() {
      let loader = this.$loading.show();
      var today = new Date();

      const dataBody = {
        id: this.jobId,
        registerDate: today.toLocaleDateString("en-US"),
        postedBy: this.email,
        jobTitle: this.jobTitle,
        jobCatId: this.selectedJobCategory,
        jobSubCatId: this.selectedJobSubCategory,
        jobDescription: this.jobDesc,
        terms: this.jobTerms,
        reward: this.reward,
        dueDate: this.dueDate,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // using JSON.stringify( to convert dataBody object to json
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/jobs/update-jobs",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.updateMsg = true;
          this.jobId = "";
          this.jobTitle = "";
          this.jobDesc = "";
          this.jobTerms = "";
          this.reward = "";
          this.dueDate = "";
          this.selectedJobSubCategory = "0";
          this.selectedJobCategory = "0";
          this.updateBtn = false;
        })
        .catch((error) => {
          //console.log(error.response);
          this.updateMsg = false;
        })
        .finally(() => {
          loader.hide();
          this.getMyJobs();
        });
    },
    jobCatGet() {
      var self = this;
      var allSubCatArry = [];
      $.getJSON("jobCategory.json", function(json) {
        //console.log(json);
        self.jobCategories = json;

        json.forEach(function(item, index) {
          allSubCatArry.push(...item.nodes);
        });
        self.jobSubCategories = allSubCatArry;
      }).fail(function(e) {
        console.log("Json Fail" + e);
      });
    },
    jobCatChange() {
      //console.log(this.selectedJobCategory);
      var seletedJobCatId = this.selectedJobCategory;
      var self = this;

      this.jobCategories.forEach(function(item, index) {
        if (item.jobCatId == seletedJobCatId) {
          //console.log(item.nodes);
          self.jobSubCategories = item.nodes;
        }
      });
    },
    syncAppliedCount() {
      const allAppliedJobs = this.allAppiledJobs;
      const myAllJobs = this.myJobs;
      //console.log(allAppliedJobs);
      //console.log(myAllJobs);
      for (let i = 0; i < myAllJobs.length; i++) {
        var jobId = myAllJobs[i]["id"];
        let applications = 0;
        $.each(allAppliedJobs, function(index, item) {
          var appliedJobId = item["jobId"];
          if (jobId == appliedJobId) {
            applications += 1;
          }
        });
        myAllJobs[i]["applications"] = applications;
      }
      this.myJobs = myAllJobs;
    },
    getApllications(jobId) {
      let loader = this.$loading.show();
      const allAppliedJobs = this.allAppiledJobs;
      const applications = Array();
      $.each(allAppliedJobs, function(index, item) {
        var appliedJobId = item["jobId"];
        if (jobId == appliedJobId) {
          applications.push(item);
        }
      });

      const myAllJobs = this.myJobs;
      for (let i = 0; i < applications.length; i++) {
        var jobId = applications[i]["jobId"];
        $.each(myAllJobs, function(index, item) {
          var baseId = item["id"];
          if (jobId == baseId) {
            applications[i]["amount"] = item["reward"];
          }
        });
      }

      this.jobApplications = applications;
      loader.hide();
    },
    applicationAction(id, action) {
      let loader = this.$loading.show();
      const dataBody = {
        applicationId: id,
        jobApplicationStatusUpdate: action,
      };
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      const dataBodyJson = JSON.stringify(dataBody);
      //console.log(dataBodyJson);
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/apply-status",
          dataBodyJson,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.getAllAppiledJob();
          loader.hide();
        });
    },
    jobPay(jobStatus) {
      if (isConnected()) {
        console.log("User has Freighter!");

        const retrievePublicKey = async () => {
          let publicKey = "";
          let error = "";

          try {
            publicKey = await getPublicKey();
          } catch (e) {
            error = e;
          }

          if (error) {
            return error;
          }

          return publicKey;
        };

        const result = retrievePublicKey();
        //console.log(result);
      }
    },
    jobPayClimable() {
      let loader = this.$loading.show();
      this.payErrorMsg = false;

      var status = $("#btnTranferPay").attr("data-status");
      const jobDetails = status.split("|");
      var amount = $("#btnTranferPay").attr("data-amount");
      var id = $("#btnTranferPay").attr("data-id");
      var rewardType = "job";
      const dataBody = {
        sourceAcPrivateKey: this.sourcePrivateKey,
        claimmantPublicKey: jobDetails[1],
        destinationEmail: jobDetails[0],
        amount: amount,
        rewardType: rewardType,
      };
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      const dataBodyJson = JSON.stringify(dataBody);
      //console.log(dataBodyJson);
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/claimmable-balance/cognito",
          dataBodyJson,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.paidAction(id);
        })
        .catch((error) => {
          //console.log(error);
          this.payErrorMsg = true;
        })
        .finally(() => {
          loader.hide();
        });
    },
    paidAction(id) {
      let loader = this.$loading.show();
      var jobStatus = "c";
      const dataBody = {
        jobApplicationId: id,
        jobStatus: jobStatus,
      };
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      const dataBodyJson = JSON.stringify(dataBody);
      //console.log(dataBodyJson);
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/job-completion-status",
          dataBodyJson,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.tranferSuccessMsg = true;
          $("#btnTranferPay").attr("disabled");
          $("#btnTranferPay").attr("data-paid", "1");
          loader.hide();
        });
    },
    getWalletInfo() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // GET wallet info using axios
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //this.sourcePrivateKey = response.data[0].privateKey;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.text-bold {
  font-weight: bold;
  font-size: 15px;
}
.job-title {
  font-size: 18px;
}
.card-text {
  font-size: 14px;
}
.btn {
  color: white;
}
.btn-round {
  border-radius: 50%;
}
.table td {
  vertical-align: middle;
}
.card-jobs {
  height: 100%;
}
.fregihter-img {
  width: 20px;
}
.border-modal {
  border: 2px solid #dc3545;
}
.close {
  color: white;
}
.bg-light-int {
  background-color: #cdd2d7;
}
.alert a {
  text-decoration: none;
  font-weight: bold;
}
.text-left label {
  font-size: 15px;
  margin-left: 5px;
  color: rgb(133, 130, 130);
}
</style>
