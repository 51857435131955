<template>
  <div class="mb-5">
    <div class="row profile-header">
      <div class="col-md-2">
        <br />
        <div class="menu">
          <MarketplaceMenuDesktop class="d-none d-sm-block" />
          <MarketplaceMenuMobile class="d-block d-sm-none" />
        </div>
        <!-- menu div end -->
      </div>

      <!-- component loading are --->
      <div class="col-md-10 mt-3">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import MarketplaceMenuDesktop from "@/components/MarketplaceMenuDesktop";
import MarketplaceMenuMobile from "@/components/MarketplaceMenuMobile";

export default {
  name: "home",
  components: {
    MarketplaceMenuDesktop,
    MarketplaceMenuMobile,
  },
};
</script>