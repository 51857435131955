<template>
  <DashboardLayout>
    <div class="container">
      <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1">
          <i class="fas fa-search"></i>
        </span>
        <input
          type="text"
          class="form-control"
          v-model="searchText"
          placeholder="Type to search..."
        />
      </div>

      <!--Success Msg-->
      <div v-show="successMsg" class="mb-3">
        <div class="alert alert-success fade show" role="alert">
          <i class="fas fa-check-circle"></i> &nbsp; <strong>வெற்றி!</strong>
          வாக்களிக்கப் பட்டுவிட்டது .
          <br />
          <br />

          <i class="fas fa-check-circle"></i> &nbsp; <strong>Success!</strong>
          project has been voted.
        </div>
      </div>
      <!--Success Msg-->

      <div class="row">
        <div
          v-for="project in filteredProjects"
          :key="project.id"
          class="col-sm-12 col-md-4 col-lg-4 col-xl-4"
        >
          <div class="card bg-light card-border mb-2">
            <div class="card-header">
              <span class="text-bold job-title">
                {{ project.projectName }}
              </span>
            </div>
            <div class="card-body">
              <!--Carousel-->
              <Carousel>
                <Slide v-for="slide in 10" :key="slide">
                  <div class="carousel__item">
                    <img
                      src="../assets/images/project-pic.jpg"
                      class="d-block w-100"
                    />
                  </div>
                </Slide>
                <template #addons>
                  <Navigation />
                </template>
              </Carousel>
              <!--Carousel-->

              <span class="text-bold">Description</span>
              <p class="card-text">{{ project.projectDesc }}</p>

              <button
                class="btn btn-light btn-circle"
                @click="voteProject(project.id)"
                :disabled="canVote === false"
                :id="'btn' + project.id"
              >
                <img src="../assets/images/ballot.png" width="30" />
              </button>
            </div>
            <div class="card-footer">
              <router-link
                class="btn btn-sm btn-warning btn-block"
                :to="{
                  path: '/project-details',
                  query: { projectId: project.id },
                }"
              >
                View
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import axios from "axios";
import DashboardLayout from "../layouts/DashboardLayout";

export default {
  name: "marketplace-jobs",
  data() {
    return {
      projects: [],
      searchText: "",
      images: [],
      email: "",
      walletAddress: "",
      WalletBalance: 0,
      successMsg: false,
      canVote: true,
    };
  },
  computed: {
    filteredProjects() {
      return this.projects.filter((project) =>
        project.projectName
          .toLowerCase()
          .includes(this.searchText.toLowerCase())
      );
    },
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    DashboardLayout,
  },
  mounted() {
    //jQuery Get JSON
    let loader = this.$loading.show();
    var self = this;
    $.getJSON(
      "https://s3.ap-southeast-1.amazonaws.com/static.TamilData.org/projects.json",
      function(json) {
        //console.log(json);
        self.projects = json;
      }
    ).fail(function(e) {
      console.log("Json Fail" + e);
    });
    loader.hide();
    //jQuery Get JSON

    //Balance check and disable vote btn
    if (this.getWalletBalance() > 9) {
      this.canVote = true;
    } else {
      this.canVote = true;
    }
    //Balance check and disable vote btn
  },
  methods: {
    voteProject(projectId) {
      if (localStorage.email === undefined) {
        this.$router.push("/login");
      } else {
        //Submit Voiting
        let loader = this.$loading.show();
        this.email = localStorage.email;
        var today = new Date();
        const dataBody = {
          registerDate: today.toLocaleDateString("en-US"),
          email: this.email,
          projectId: projectId,
          walletAddress: this.walletAddress,
        };
        const headers = {
          Authorization: "Bearer " + localStorage.token,
        };
        axios
          .post(
            " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/voting",
            dataBody,
            {
              headers: headers,
            }
          )
          .then((response) => {
            //console.log(response);
            this.successMsg = true;
          })
          .catch((error) => {
            //console.log(error);
            this.successMsg = false;
          })
          .finally(() => {
            loader.hide();
          });
      }
      //Submit Voiting

      //Get Total Voting
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/voting/?projectId=" +
            projectId,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          $("#btn" + projectId).html(
            "<h2>" + Object.keys(response.data).length + "</h2>"
          );
          $("#btn" + projectId).attr("disabled", "disabled");
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
      //Get Total Voting
    },
    getWalletBalance() {
      if (localStorage.email === undefined) {
        return 10;
      } else {
        this.email = localStorage.email;
        //Get Public Address
        let loader = this.$loading.show();
        const headers = {
          Authorization: "Bearer " + localStorage.token,
        };
        axios
          .get(
            "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet/?email=" +
              this.email,
            {
              headers: headers,
            }
          )
          .then((response) => {
            //console.log(response);
            this.walletAddress = response.data[0].publicKey;
          })
          .catch(function(error) {
            //console.log(error);
          });
        //Get Public Address

        //Get Balance
        var address = this.walletAddress;
        axios
          .get("https://horizon.stellar.org/accounts/" + address, {
            headers: headers,
          })
          .then((response) => {
            //console.log(response);
            const balances = response.data.balances;
            balances.forEach((entry) => {
              if (entry.asset_code == "TAMIL") {
                //console.log(parseFloat(entry.balance).toFixed(2));
                this.WalletBalance = parseFloat(entry.balance).toFixed(2);
              }
            });
          })
          .catch(function(error) {
            //console.log(error);
          })
          .finally(() => {
            loader.hide();
          });
        //Get Balance

        //Return Balance
        return this.WalletBalance;
      }
    },
  },
};
</script>

<style scoped>
.text-bold {
  font-weight: bold;
  font-size: 15px;
}
.job-title {
  font-size: 18px;
}
.card-text {
  font-size: 14px;
}
.input-group-text {
  background-color: rgba(179, 179, 179, 0.993);
}
.card-border {
  border: 1px solid #ff9900;
}
.btn-circle {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  padding: 0px;
}
</style>
