<template>
  <!--Market place job cat-->
  <MarketplaceJobcategory />
</template>

<script>
import MarketplaceJobcategory from "@/components/MarketplaceJobcategory";

export default {
  components: {
    MarketplaceJobcategory,
  },
};
</script>
