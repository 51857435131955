<template>
  <div class="row">
    <!--item-->
    <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3">
      <div class="card h-100">
        <img src="../assets/images/shop/item-1.jpeg" alt="Item-1" />
        <div class="card-body">
          <h5>Tamil Crypto Polo T-Shirt</h5>
          <p>Currently delivering to Singapore and Malaysia only</p>
        </div>
        <div class="card-footer">
          <h5>SGD 25.00</h5>
        </div>
        <div class="card-footer">
          <stripe-checkout
            ref="checkoutRefItem1"
            mode="payment"
            :pk="publishableKey"
            :line-items="lineItem1"
            :success-url="successURL"
            :cancel-url="cancelURL"
            @loading="(v) => (loading = v)"
          />
          <button
            class="btn btn-warning"
            @click="submitCheckOutItem1"
            v-show="logined"
          >
            <i class="fa fa-shopping-cart"></i> Buy Now
          </button>

          <router-link to="/login" class="btn btn-warning" v-show="!logined">
            Login
          </router-link>
        </div>
      </div>
    </div>
    <!--item-->

    <!--item-->
    <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3">
      <div class="card h-100">
        <img src="../assets/images/shop/item-2.jpeg" alt="Item-2" />
        <div class="card-body">
          <h5>Tamil Crypto Polo T-Shirt</h5>
          <p>Currently delivering to Singapore and Malaysia only</p>
        </div>
        <div class="card-footer">
          <h5>SGD 25.00</h5>
        </div>
        <div class="card-footer">
          <stripe-checkout
            ref="checkoutRefItem2"
            mode="payment"
            :pk="publishableKey"
            :line-items="lineItem2"
            :success-url="successURL"
            :cancel-url="cancelURL"
            @loading="(v) => (loading = v)"
          />
          <button
            class="btn btn-warning"
            @click="submitCheckOutItem2"
            v-show="logined"
          >
            <i class="fa fa-shopping-cart"></i> Buy Now
          </button>

          <router-link to="/login" class="btn btn-warning" v-show="!logined">
            Login
          </router-link>
        </div>
      </div>
    </div>
    <!--item-->

    <!--item-->
    <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3">
      <div class="card h-100">
        <img src="../assets/images/shop/item-3.jpeg" alt="Item-3" />
        <div class="card-body">
          <h5>Tamil Crypto Polo T-Shirt</h5>
          <p>Currently delivering to Singapore and Malaysia only</p>
        </div>
        <div class="card-footer">
          <h5>SGD 20.00</h5>
        </div>
        <div class="card-footer">
          <stripe-checkout
            ref="checkoutRefItem3"
            mode="payment"
            :pk="publishableKey"
            :line-items="lineItem3"
            :success-url="successURL"
            :cancel-url="cancelURL"
            @loading="(v) => (loading = v)"
          />
          <button
            class="btn btn-warning"
            @click="submitCheckOutItem3"
            v-show="logined"
          >
            <i class="fa fa-shopping-cart"></i> Buy Now
          </button>

          <router-link to="/login" class="btn btn-warning" v-show="!logined">
            Login
          </router-link>
        </div>
      </div>
    </div>
    <!--item-->
  </div>
</template>

<script>
//import DashboardLayout from "../layouts/DashboardLayout";

import { StripeCheckout } from "@vue-stripe/vue-stripe";

//publishableKey: "pk_test_kRbh41wzomtVx4m4g4ENGqrL", //TEST KEY
//price: "price_1JYidOJSdx9WLyfKPyyIfvfB", //TEST PRICE

export default {
  name: "shop",
  data: () => ({
    email: "",
    logined: false,
    publishableKey: "pk_live_3tZFHL4BOEdiSAuQWPyMm0Oa",
    lineItem1: [
      {
        price: "price_1JYiUxJSdx9WLyfKZBWWyclz",
        quantity: 1,
      },
    ],
    lineItem2: [
      {
        price: "price_1JYiY6JSdx9WLyfK1y6QVNBY",
        quantity: 1,
      },
    ],
    lineItem3: [
      {
        price: "price_1JYiZBJSdx9WLyfKt2KPovk6",
        quantity: 1,
      },
    ],
    successURL: "https://TamilData.org/payment-success",
    cancelURL: "https://TamilData.org/payment-failed",
    loading: false,
  }),
  components: {
    //DashboardLayout,
    StripeCheckout,
  },
  mounted() {
    if (localStorage.email === undefined) {
      this.logined = false;
    } else {
      this.email = localStorage.email;
      this.logined = true;
    }
  },
  methods: {
    submitCheckOutItem1() {
      let loader = this.$loading.show();
      this.$refs.checkoutRefItem1.redirectToCheckout();
      loader.hide();
    },
    submitCheckOutItem2() {
      let loader = this.$loading.show();
      this.$refs.checkoutRefItem2.redirectToCheckout();
      loader.hide();
    },
    submitCheckOutItem3() {
      let loader = this.$loading.show();
      this.$refs.checkoutRefItem3.redirectToCheckout();
      loader.hide();
    },
  },
};
</script>


