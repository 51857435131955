<template>
  <DashboardLayout>
    <div class="container">
      <div class="row">
        <!-- Col 1 -->
        <div class="col">
          <div class="card">
            <div class="card-header">
              <strong>
                <span v-show="!updateBtn">New</span>
                <span v-show="updateBtn">Update</span>
                Project Proposal</strong
              >
            </div>
            <div class="card-body">
              <input type="hidden" v-model="projectId" />

              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="projectName"
                  v-model="projectName"
                  placeholder="Enter Your Project Name..."
                />
                <label for="projectName">Project Name</label>
              </div>

              <div class="form-floating mb-3">
                <textarea
                  class="form-control"
                  id="projectDesc"
                  v-model="projectDesc"
                  placeholder="Enter Your project Description..."
                  style="height: 100px"
                />
                <label for="projectDesc"> Project Description </label>
              </div>

              <div class="form-floating mb-3">
                <select class="form-select" v-model="selectedCountryTarget">
                  <option
                    v-for="country in countries"
                    v-bind:key="country.id"
                    :value="country.name"
                  >
                    {{ country.name }}
                  </option>
                </select>
                <label for="countryBirth">Target Country</label>
              </div>

              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="funding"
                  v-model="funding"
                  placeholder="Enter Your Project Name..."
                />
                <label for="funding">Funding Required (In TAMIL Token)</label>
              </div>

              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="proposalCid"
                  v-model="proposalCid"
                  placeholder="Enter Your Project Name..."
                />
                <label for="proposalCid">Proposal CID</label>
              </div>
            </div>

            <!--Success Msg-->
            <div v-show="updateMsg" class="mb-3">
              <div class="alert alert-success fade show" role="alert">
                <i class="fas fa-check-circle"></i> &nbsp;
                <strong>வெற்றி!</strong> செயற்திட்ட முன்மொழிவு பதிவேற்றப்பட்டது.
                <br />
                <br />

                <i class="fas fa-check-circle"></i> &nbsp;
                <strong>Success!</strong> Project proposal has been uploaded.
              </div>
            </div>
            <!--Success Msg-->

            <div class="mb-3" v-show="!updateBtn">
              <button
                class="btn btn-danger btn-block"
                type="button"
                @click="proposeProject"
              >
                Propose
              </button>
            </div>

            <div class="mb-3" v-show="updateBtn">
              <button
                class="btn btn-danger btn-block"
                type="button"
                @click="updateProject"
              >
                Update
              </button>
            </div>
          </div>
        </div>
        <!-- Col 1 -->

        <!-- Col 2 -->
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="card mb-2">
            <div class="card-header">
              <strong>My Projects</strong>
            </div>
            <div class="card-body">
              <div class="row">
                <div
                  v-for="myProject in myProjects"
                  :key="myProject.postedBy"
                  class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                >
                  <div class="card bg-light card-border mb-2">
                    <div class="card-header">
                      <span class="text-bold job-title">
                        {{ myProject.projectName }}
                      </span>
                    </div>
                    <div class="card-body">
                      <span class="text-bold">Description</span>
                      <p class="card-text">{{ myProject.projectDesc }}</p>

                      <span class="text-bold">Target Country</span>
                      <p class="card-text">{{ myProject.targetCountry }}</p>

                      <span class="text-bold">Funding</span>
                      <p class="card-text">{{ myProject.funding }}</p>
                    </div>
                    <div class="card-footer">
                      <button
                        class="btn btn-sm btn-warning mt-1"
                        @click="
                          editProjectInfo(
                            myProject.id,
                            myProject.projectName,
                            myProject.projectDesc,
                            myProject.targetCountry,
                            myProject.funding,
                            myProject.proposalCid
                          )
                        "
                      >
                        Edit
                      </button>

                      <router-link
                        class="btn btn-sm btn-warning mt-1"
                        :to="{
                          path: '/upload-media',
                          query: {
                            mediaId: myProject.id,
                            mediaType: 'project',
                          },
                        }"
                      >
                        Upload Picture
                      </router-link>

                      <router-link
                        class="btn btn-sm btn-warning mt-1"
                        :to="{
                          path: '/project-status',
                          query: { projectId: myProject.id },
                        }"
                      >
                        Post Status
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Col 2 -->
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//import { Auth } from "aws-amplify";

export default {
  name: "my-projects",
  data() {
    return {
      projectId: "",
      email: "",
      updateMsg: false,
      updateBtn: false,
      projectName: "",
      projectDesc: "",
      funding: "",
      proposalCid: "",
      selectedCountryTarget: "India",
      countries: [
        { id: 1, name: "India" },
        { id: 2, name: "Srilanka" },
        { id: 3, name: "Malaysia" },
        { id: 4, name: "United States" },
        { id: 5, name: "France" },
        { id: 6, name: "Australia" },
        { id: 7, name: "Switzerland" },
        { id: 8, name: "Singapore" },
        { id: 9, name: "Denmark" },
        { id: 10, name: "United Arab Emirates" },
        { id: 11, name: "Italy" },
        { id: 12, name: "Germany" },
        { id: 13, name: "United Kingdom" },
        { id: 14, name: "Canada" },
        { id: 15, name: "Others" },
      ],
      myProjects: [],
    };
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getProjectInfo();
    }
  },
  components: {
    DashboardLayout,
  },
  methods: {
    proposeProject: function() {
      let loader = this.$loading.show();
      var today = new Date();
      const dataBody = {
        registerDate: today.toLocaleDateString("en-US"),
        email: this.email,
        projectName: this.projectName,
        projectDesc: this.projectDesc,
        targetCountry: this.selectedCountryTarget,
        funding: this.funding,
        proposalCid: this.proposalCid,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // using JSON.stringify( to convert dataBody object to json
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/add-project",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.projectName = "";
          this.projectDesc = "";
          this.selectedCountryTarget = "India";
          this.funding = "";
          this.proposalCid = "";

          this.updateMsg = true;
        })
        .catch((error) => {
          //console.log(error);
          this.updateMsg = false;
        })
        .finally(() => {
          loader.hide();
          this.getProjectInfo();
        });
    },
    getProjectInfo() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      // GET request using axios with async/await
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/add-project/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.myProjects = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    editProjectInfo(id, name, desc, target, funding, cid) {
      let loader = this.$loading.show();
      this.projectId = id;
      this.projectName = name;
      this.projectDesc = desc;
      this.selectedCountryTarget = target;
      this.funding = funding;
      this.proposalCid = cid;
      this.updateBtn = true;
      loader.hide();
    },
    updateProject() {
      let loader = this.$loading.show();
      var today = new Date();
      const dataBody = {
        id: this.projectId,
        registerDate: today.toLocaleDateString("en-US"),
        email: this.email,
        projectName: this.projectName,
        projectDesc: this.projectDesc,
        targetCountry: this.selectedCountryTarget,
        funding: this.funding,
        proposalCid: this.proposalCid,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // using JSON.stringify( to convert dataBody object to json
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/add-project/update-project",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.projectId = "";
          this.projectName = "";
          this.projectDesc = "";
          this.selectedCountryTarget = "India";
          this.funding = "";
          this.proposalCid = "";
          this.updateBtn = false;

          this.updateMsg = true;
        })
        .catch((error) => {
          //console.log(error);
          this.updateMsg = false;
        })
        .finally(() => {
          loader.hide();
          this.getProjectInfo();
        });
    },
  },
};
</script>

<style scoped>
.text-bold {
  font-weight: bold;
  font-size: 15px;
}
.job-title {
  font-size: 18px;
}
.card-text {
  font-size: 14px;
}
.card-border {
  border: 1px solid #ff9900;
}
</style>
