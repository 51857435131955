<template>
  <div id="wrapper">
    <SideBar></SideBar>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopBar></TopBar>
        <div class="container-fluid">
          <!-- Page Content -->
          <div class="row">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import TopBar from "@/components/TopBar";
import { Auth } from "aws-amplify";

export default {
  name: "home",
  components: {
    SideBar,
    TopBar,
  },
  mounted() {
    this.checkExpTime();
  },
  methods: {
    checkExpTime() {
      if (localStorage.tokenExp !== undefined) {
        const unixTimestampExp = localStorage.tokenExp;
        const millisecondsExp = unixTimestampExp * 1000; //convert to Milliseconds
        const dateExp = new Date(millisecondsExp).toLocaleString();

        const unixTimestampNow = Date.now(); //In Milliseconds
        const dateNow = new Date(unixTimestampNow).toLocaleString();

        if (parseInt(unixTimestampNow) > parseInt(millisecondsExp)) {
          this.signOut();
        }
      } else {
        this.signOut();
      }
    },
    async signOut() {
      try {
        await Auth.signOut();
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExp");
        localStorage.removeItem("fullName");
        localStorage.removeItem("mobile");
        localStorage.removeItem("telegram");

        this.$router.push("/login");
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
  },
};
</script>
