<template>
  <DashboardLayout>
    <div class="container">
      <!-- New Wallet-->
      <div class="mb-3">
        <button
          class="btn btn-warning"
          type="button"
          @click="createWallet"
          id="btnCreateWallet"
        >
          <i class="fas fa-wallet"></i> Create NewTAMIL Data Decentralization Wallet
        </button>
      </div>
      <!-- New Wallet-->

      <!-- Row Start -->
      <div class="row">
        <!-- col 1 -->
        <div class="col-md-6">
          <div class="card bg-dark text-white">
            <img
              src="../assets/images/card-size.png"
              class="card-img"
              alt="..."
            />

            <div class="card-img-overlay">
              <h5 class="card-title" style="text-align: right">
               TAMIL Data Decentralization Wallet
              </h5>
              <p
                class="card-text"
                style="
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    font-size: 20px;
                    padding-right: 5px;
                  "
              >
                <a class="btn btn-sm btn-light" @click="getBalance">
                  <i class="fas fa-eye"></i> Show balance
                </a>
                <br />
                <span class="text-right">
                  TAMIL {{ balance }}
                  <br />
                  USD {{ balanceUSD }}
                </span>
              </p>
            </div>
          </div>

          <button
            class="btn btn-block btn-success my-2 hidden"
            id="btnActiveWallet"
            disabled
          >
            Wallet Activated
          </button>

          <button
            class="btn btn-block btn-secondary my-2 hidden"
            id="btnActivateWallet"
            @click="activateWallet"
          >
            Activate Wallet
          </button>

          <button
            class="btn btn-block btn-danger mt-2 hidden"
            id="btnApprovalPendingWallet"
            disabled
          >
            Wallet is not approved.
          </button>

          <button
            class="btn btn-block btn-warning text-white mt-2 hidden"
            id="btnReqestApproval"
            @click="requestToApproval"
          >
            Request for approval
          </button>
        </div>
        <!-- col end -->

        <!-- col 2 -->
        <div class="col-md-6">
          <form>
            <div class="mb-3 text-left">
              <label for="walletName">Wallet Name</label>
              <input
                type="text"
                class="form-control"
                id="walletName"
                placeholder="Enter wallet name"
                autocomplete="off"
                v-model="walletName"
              />
            </div>

            <div class="mb-3 text-left">
              <label for="walletName">Wallet Address</label>
              <input
                type="text"
                class="form-control"
                id="walletAddress"
                placeholder="Wallet Address"
                v-model="walletAddress"
                autocomplete="off"
              />
            </div>

            <div class="mb-3 hidden">
              <div class="text-left">
                <label for="privateKey"> Private Key (Optional) </label>
                <div class="form-group input-group">
                  <input
                    type="password"
                    class="form-control"
                    id="privateKey"
                    placeholder="Private Key"
                    v-model="privateKey"
                    autocomplete="off"
                  />
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text required"
                      id="inputGroup-sizing-default"
                    >
                      <a href="#" type="button" id="togglePassword">
                        <i class="far fa-eye"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <small> (Not required unless involved in transactions.) </small>
              <br />
              <small>
                Note: Private Key will be stored in encrypted secure data store
              </small>
            </div>

            <div class="form-action mb-3">
              <button
                class="btn btn-warning"
                type="button"
                @click="addWallet"
                id="btnAddWallet"
              >
                <i class="fas fa-save"></i> Update Wallet
              </button>
            </div>
          </form>

          <div v-show="success === 'true'">
            <span>Wallet Address: {{ publicKey }}</span>
            <span>Wallet Key: {{ privateKey }}</span>
          </div>
        </div>
        <!-- col end -->
      </div>
      <!-- row end -->

      <!--Success Msg-->
      <div v-show="walletAdded">
        <div class="alert alert-success fade show" role="alert">
          <i class="fas fa-check-circle"></i> &nbsp;
          <strong>வெற்றி!</strong> பணப்பை விவரங்கள் புதுப்பிக்கப்பட்டது. <br />
          <br />
          <i class="fas fa-check-circle"></i> &nbsp;
          <strong>Success!</strong> Wallet details has been updated.
        </div>
      </div>
      <!--Success Msg-->

      <!-- details Card -->
      <div class="card text-white bg-dark my-2">
        <div class="card-body responsive-data">
          <span>1. Wallet Name: {{ walletName }}</span>
          <br />
          <span>2. Date Created: {{ dateCreated }}</span>
          <br />
          <span class="d-inline-block break-text">
            3. Wallet Address:
            <a
              :href="
                'https://stellar.expert/explorer/public/account/' + publicKey
              "
              target="_blank"
            >
              <span>{{ publicKey }}</span>
            </a>
          </span>
        </div>
      </div>
      <!-- details Card -->
    </div>
    <!-- end container div-->

    <!-- Modal -->
    <div
      class="modal fade"
      id="newWalletModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="newWalletModalLable"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              <i class="fas fa-check-circle"></i> &nbsp;
              <strong>Success!</strong> New wallet has been created.
              <hr />
              You can also click "Update Wallet" button to save wallet
              information
              <br />
              Wallet Address :
              <strong class="break-text">{{ walletAddress }}</strong>
              <br />
              Private Key : <strong class="break-text">{{ privateKey }}</strong>

              <br />
              <br />

              <span class="note-wallet-text pt-3">
                குறிப்பு: ரகசிய சேமிப்பானின் கடவுச்சொல்லை பிரதி எடுத்து
                பாதுகாப்பாக வைக்கவும்!
                <br />
                இந்த கடவுச்சொல் உங்களுக்கு ஒரு முறை மட்டுமே காட்டப்படும்.
                TamilData.org அதை சேமிக்காது மற்றும் இழந்தால் அதை மீட்டெடுக்க
                உங்களுக்கு உதவ முடியாது.
              </span>
              <br />
              <br />
              <span class="note-wallet-text pt-3">
                Note: Backup the secret key and keep it secure!
                <br />
                This secret key will only be shown to you once. Always keep this
                key safe and secure. TamilData.org does not save it and will
                not be able to help you recover it if lost.
              </span>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "wallet",
  data: () => ({
    walletName: "",
    email: "",
    publicKey: "",
    walletAddress: "",
    privateKey: "",
    dateCreated: "",
    success: false,
    walletAdded: false,
    balance: "00.00",
    balanceXLM: "00.00",
    balanceUSD: "00.00",
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getWalletInfo();
    }
  },
  methods: {
    createWallet() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .post(
          `https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet`,
          {},
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response.data);
          if (response.status == 200) {
            this.publicKey = response.data.publickey;
            this.privateKey = response.data.privatekey;
            this.walletName = "MyWallet";
            this.walletAddress = response.data.publickey;

            $("#newWalletModal").modal("show");
            this.addWallet();
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    addWallet() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      var today = new Date();
      axios
        .post(
          `https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/add-wallet`,
          {
            walletName: this.walletName,
            email: this.email,
            publicKey: this.walletAddress,
            privateKey: "-",
            dateCreated: today.toLocaleDateString("en-US"),
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.walletAdded = true;
        })
        .catch((error) => {
          //console.log(error);
          this.walletAdded = false;
        })
        .finally(() => {
          this.getWalletInfo();
          loader.hide();
        });
    },
    getWalletInfo() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // GET wallet info using axios
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          const wallets = response.data;

          if (wallets.length > 0) {
            this.walletName = wallets[0].walletName;
            this.dateCreated = wallets[0].dateCreated;
            this.publicKey = wallets[0].publicKey;
            this.walletAddress = wallets[0].publicKey;
            //this.privateKey = wallets[0].privateKey;

            var activationStatus = wallets[0].activationStatus;
            var walletStatus = wallets[0].walletStatus;
            var requested = wallets[0].requested;

            if (walletStatus == "approved") {
              if (activationStatus == "pending") {
                $("#btnActiveWallet").hide();
                $("#btnActivateWallet").show();
                $("#btnApprovalPendingWallet").hide();
                $("#btnReqestApproval").hide();
              } else {
                $("#btnActiveWallet").show();
                $("#btnActivateWallet").hide();
                $("#btnApprovalPendingWallet").hide();
                $("#btnReqestApproval").hide();
              }
            } else {
              if (requested == 0) {
                $("#btnActiveWallet").hide();
                $("#btnActivateWallet").hide();
                $("#btnApprovalPendingWallet").show();
                $("#btnReqestApproval").show();
              } else {
                $("#btnActiveWallet").hide();
                $("#btnActivateWallet").hide();
                $("#btnApprovalPendingWallet").show();
                $("#btnReqestApproval").hide();
              }
            }
          }

          if (this.walletAddress != "") {
            $("#btnCreateWallet").hide();
          }
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getBalance() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      var address = this.publicKey;
      axios
        .get("https://horizon.stellar.org/accounts/" + address, {
          headers: headers,
        })
        .then((response) => {
          //console.log(response);
          const balances = response.data.balances;
          balances.forEach((entry) => {
            if (entry.asset_code == "TAMIL") {
              //console.log(parseFloat(entry.balance).toFixed(2));
              this.balance = parseFloat(entry.balance).toFixed(2);
            }

            if (entry.asset_type == "native") {
              //console.log(parseFloat(entry.balance).toFixed(2));
              this.balanceXLM = parseFloat(entry.balance).toFixed(2);
            }
          });
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
          this.getBalanceUsd();
        });
    },
    getBalanceUsd() {
      let loader = this.$loading.show();
      var balance = this.balance;

      axios
        .get(
          "https://8uxify1xrj.execute-api.ap-southeast-1.amazonaws.com/prod/tamil-price-in-usdc"
        )
        .then((response) => {
          const convertedAmount = balance * response.data;
          this.balanceUSD = parseFloat(convertedAmount).toFixed(2);
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    activateWallet() {
      var walletPrivateKey = this.privateKey;

      if (!this.walletAddress || /^\s*$/.test(this.walletAddress)) {
        Swal.showValidationMessage(
          "A wallet address is needed. Kindly save your wallet address and try again."
        );
        return false;
      }

      Swal.fire({
        title: "Enter The Secret Key.",
        html: `
        <div class='form-group'>
          <input type='password' id="input-private-key" class="form-control mb-2" placeholder="Enter Private Key" value='${walletPrivateKey}'>
        </div>
        `,
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        focusConfirm: false,
        preConfirm: () => {
          const walletPrivateKey = document.getElementById("input-private-key")
            .value;

          if (!walletPrivateKey) {
            Swal.showValidationMessage("Private Key cannot be empty");
          }

          return {
            walletPrivateKey: walletPrivateKey,
          };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show();

          const walletEmail = this.email;
          const walletAddress = this.walletAddress;

          const { walletPrivateKey } = result.value;

          const dataBody = {
            account_public_key: walletAddress,
            account_secret_key: walletPrivateKey,
            email: walletEmail,
          };
          //console.log(dataBody);
          const headers = {
            Authorization: "Bearer " + localStorage.token,
          };

          axios
            .post(
              "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/activate-wallet",
              dataBody,
              {
                headers: headers,
              }
            )
            .then((response) => {
              //console.log(response);
              Swal.fire({
                title: "Success",
                text: "The wallet has been successfully activated.",
                icon: "success",
              }).then((result) => {
                location.reload();
              });
            })
            .catch(function(error) {
              //console.log(error);
              Swal.fire({
                title: "Error",
                text: "Wallet Activation Failed.",
                icon: "error",
              });
            })
            .finally(() => {
              loader.hide();
            });
        }
      });
    },
    requestToApproval() {
      if (!this.walletAddress || /^\s*$/.test(this.walletAddress)) {
        Swal.showValidationMessage(
          "A wallet address is needed. Kindly save your wallet address and try again."
        );
        return false;
      }

      Swal.fire({
        title: "Justify your request for approval",
        html: `
        <div class='form-group'>
          <textarea id="textarea-reason" class="form-control mb-2" rows='10' placeholder="What is your contribution to Tamil language development? For example, are you a Tamil teacher, researcher, media contributor, activist, nationalist, major graduate in Tamil language, or something else? Please include a link to your social media profile showcasing your contribution."></textarea>
        </div>
        `,
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        focusConfirm: false,
        preConfirm: () => {
          const reason = document.getElementById("textarea-reason").value;

          if (!reason) {
            Swal.showValidationMessage("Justification cannot be empty.");
          }

          return {
            reason: reason,
          };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show();

          const walletAddress = this.walletAddress;

          const { reason } = result.value;

          const dataBody = {
            wallet_address: walletAddress,
            request: reason,
          };

          const headers = {
            Authorization: "Bearer " + localStorage.token,
          };

          axios
            .post(
              "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/wallet-approval-request",
              dataBody,
              {
                headers: headers,
              }
            )
            .then((response) => {
              //console.log(response);
              Swal.fire({
                title: "Success",
                text: "Approval Requested.",
                icon: "success",
              }).then((result) => {
                location.reload();
              });
            })
            .catch(function(error) {
              //console.log(error);
              Swal.fire({
                title: "Error",
                text: "Approval Request Failed.",
                icon: "error",
              });
            })
            .finally(() => {
              loader.hide();
            });
        }
      });
    },
  },
};

$("body").on("click", "#togglePassword", function() {
  var type = $("#privateKey").attr("type");
  if (type == "text") {
    $("#privateKey").attr("type", "password");
    $("#togglePassword").html("<i class='far fa-eye'></i>");
  } else {
    $("#privateKey").attr("type", "text");
    $("#togglePassword").html("<i class='far fa-eye-slash'></i>");
  }
});
</script>

<style scoped>
.card-body {
  text-align: left;
}
.form-group {
  text-align: left;
}
.btn-light {
  background-color: rgba(255, 255, 255, 0.664);
}
.text-right {
  text-align: right;
}
.break-text {
  word-wrap: break-word;
}
.note-wallet-text {
  color: red;
  font-weight: bold;
}

.text-left label {
  font-size: 15px;
  margin-left: 5px;
  color: rgb(105, 102, 102);
}

.hidden {
  display: none;
}
</style>
