<template>
  <MarketplaceLayout>
    <h2>Job Categories</h2>
    <div class="row">
      <div
        class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2"
        v-for="(jobCat, index) in jobCategories"
        :key="index"
      >
        <router-link
          class="no-decoration"
          :to="{
            path: '/marketplace-jobsubcategory',
            query: { jobCatId: jobCat.jobCatId },
          }"
        >
          <div class="card">
            <div class="card-body">
              <img src="../assets/images/job-pic.jpeg" class="d-block w-100" />
              <br />
              <h5>{{ jobCat.text }}</h5>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </MarketplaceLayout>
</template>

<script>
import MarketplaceLayout from "../layouts/MarketplaceLayout";
import $ from "jquery";

export default {
  components: {
    MarketplaceLayout,
  },
  name: "marketplace-jobcategory",
  data() {
    return {
      jobCategories: [],
    };
  },
  mounted() {
    this.jobCatGetInfo();
  },
  methods: {
    jobCatGetInfo() {
      var self = this;
      $.getJSON("jobCategory.json", function(json) {
        //console.log(json);
        self.jobCategories = json;
      }).fail(function(e) {
        console.log("Json Fail" + e);
      });
    },
  },
};
</script>

<style scoped>
.no-decoration {
  color: rgba(53, 49, 49, 0.774);
  text-decoration: none;
}
.card {
  height: 100%;
}
</style>
