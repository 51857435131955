<template>
  <div>
  <DashboardLayout>
        Payment Failed
  </DashboardLayout>
  </div>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";

export default {
  name: "paymentFailed",
  components: {
    
    DashboardLayout
  }
};
</script>
