<template>
  <DashboardLayout>
    <div class="container">
      <div class="card shadow border-left-danger">
        <div class="card-body">
          <div class="form-group">
            <label for="">Select Node :</label>
            <select
              class="form-control form-select"
              v-model="nodeId"
              id="dropNodeSelect"
            >
              <option value="0">Choose</option>
              <option
                v-for="node in nodes"
                v-bind:key="node.id"
                :value="node.id"
                :data-catId="node.catId"
                :data-subCatId="node.subCatId"
              >
                {{ node.peerId }}
                ({{ node.catName }} - {{ node.subCatName }})
              </option>
            </select>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <div class="form-group">
                <label for="">Title :</label>
                <input type="text" class="form-control" v-model="title" />
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
              <div class="form-group">
                <label for="">IPFS Cid :</label>
                <input type="text" class="form-control" v-model="cId" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <div class="form-group">
                <label for="">Publish Year :</label>
                <input type="number" class="form-control" v-model="pubYear" />
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
              <div class="form-group">
                <label for="">Publisher Name :</label>
                <input type="text" class="form-control" v-model="pubName" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <div class="form-group">
                <label for="">Author Name :</label>
                <input type="text" class="form-control" v-model="auName" />
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
              <div class="form-group">
                <label for="">Country :</label>
                <select class="form-control form-select" v-model="country">
                  <option
                    v-for="country in countries"
                    v-bind:key="country.id"
                    :value="country.name"
                  >
                    {{ country.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <div class="form-group">
                <label for="">Tag :</label>
                <input type="text" class="form-control" v-model="tag" />
              </div>

              <div class="form-group">
                <label for="">Edition :</label>
                <input type="text" class="form-control" v-model="edition" />
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
              <div class="form-group">
                <label for="">Description :</label>
                <textarea
                  class="form-control"
                  v-model="desc"
                  rows="5"
                ></textarea>
              </div>
            </div>
          </div>

          <!--Success Msg-->
          <div v-show="updateMsg" class="mb-3">
            <div class="alert alert-success fade show" role="alert">
              <i class="fas fa-check-circle"></i> &nbsp;
              <strong>Success!</strong> assest info has been recorded
            </div>
          </div>
          <!--Success Msg-->

          <div class="form-group mt-2 mb-2">
            <button class="btn btn-danger btn-block" @click="saveDataFile">
              Save
            </button>
          </div>
        </div>
      </div>

      <div class="card shadow border-left-danger mt-3">
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Title</th>
                <th>Country</th>
                <th>Classifications</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(assest, index) in assests" :key="index">
                <td>{{ assest.title }}</td>
                <td>{{ assest.country }}</td>

                <td>{{ assest.catName }} -> {{ assest.subCatName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

export default {
  name: "data-file",
  data() {
    return {
      user: {},
      accessToken: "",
      email: "",
      updateMsg: false,
      assests: [],
      nodes: [],
      nodeId: 0,
      catId: 0,
      subCatId: 0,
      title: "",
      cId: "",
      pubYear: "",
      pubName: "",
      auName: "",
      country: "India",
      desc: "",
      tag: "",
      edition: "",
      countries: [
        { id: 1, name: "India" },
        { id: 2, name: "Srilanka" },
        { id: 3, name: "Malaysia" },
        { id: 4, name: "United States" },
        { id: 5, name: "France" },
        { id: 6, name: "Australia" },
        { id: 7, name: "Switzerland" },
        { id: 8, name: "Singapore" },
        { id: 9, name: "Denmark" },
        { id: 10, name: "United Arab Emirates" },
        { id: 11, name: "Italy" },
        { id: 12, name: "Germany" },
        { id: 13, name: "United Kingdom" },
        { id: 14, name: "Canada" },
        { id: 15, name: "Others" },
      ],
    };
  },
  components: {
    DashboardLayout,
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getNodeInfo();
      this.getAssestInfo();
    }

    this.$nextTick(() => {
      $.getJSON("ipfs.json", function(json) {
        addOptions(json); //IPFS SELECT OPTIONS
      });
    });
  },
  methods: {
    getNodeInfo() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .get(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/ipfs/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);

          for (let i = 0; i < response.data.length; i++) {
            var catId = response.data[i]["catId"];
            var subCatId = response.data[i]["subCatId"];
            const ipfsData = JSON.parse(localStorage.getItem("ipfsData"));
            for (let x = 0; x < ipfsData.length; x++) {
              var mainCatId = ipfsData[x]["id"];
              if (mainCatId == catId) {
                const ipfsSubCat = ipfsData[x]["nodes"];
                response.data[i]["catName"] = ipfsData[x]["text"].split("|")[0];

                for (let y = 1; y < ipfsSubCat.length; y++) {
                  if (subCatId == ipfsSubCat[y]["id"]) {
                    response.data[i]["subCatName"] = ipfsSubCat[y]["text"];
                  }
                }
              }
            }
          }

          this.nodes = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getAssestInfo() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .get(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/assets/by-user/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);

          for (let i = 0; i < response.data.length; i++) {
            var catId = response.data[i]["catId"];
            var subCatId = response.data[i]["subCatId"];
            const ipfsData = JSON.parse(localStorage.getItem("ipfsData"));
            for (let x = 0; x < ipfsData.length; x++) {
              var mainCatId = ipfsData[x]["id"];
              if (mainCatId == catId) {
                const ipfsSubCat = ipfsData[x]["nodes"];
                response.data[i]["catName"] = ipfsData[x]["text"].split("|")[0];

                for (let y = 1; y < ipfsSubCat.length; y++) {
                  if (subCatId == ipfsSubCat[y]["id"]) {
                    response.data[i]["subCatName"] = ipfsSubCat[y]["text"];
                  }
                }
              }
            }
          }
          this.assests = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    saveDataFile() {
      let loader = this.$loading.show();

      this.updating = true;
      var today = new Date();

      this.catId = $("#dropNodeSelect :selected").attr("data-catId");
      this.subCatId = $("#dropNodeSelect :selected").attr("data-subCatId");

      if (this.nodeId == "" || this.nodeId == 0) {
        loader.hide();
        $.alert({
          title: "Alert!",
          content: "Please select node",
          icon: "fa fa-exclamation-triangle",
          buttons: {
            okay: {
              text: "Okay",
              btnClass: "btn-red",
            },
          },
        });
        return false;
      }

      if (this.title == "") {
        loader.hide();
        $.alert({
          title: "Alert!",
          content: "Title must needed.",
          icon: "fa fa-exclamation-triangle",
          buttons: {
            okay: {
              text: "Okay",
              btnClass: "btn-red",
            },
          },
        });
        return false;
      }

      if (this.cId == "") {
        loader.hide();
        $.alert({
          title: "Alert!",
          content: "CId must needed.",
          icon: "fa fa-exclamation-triangle",
          buttons: {
            okay: {
              text: "Okay",
              btnClass: "btn-red",
            },
          },
        });
        return false;
      }

      const dataBody = {
        registerDate: today.toLocaleDateString("en-US"),
        email: this.email,
        cid: this.cId,
        catId: this.catId,
        subCatId: this.subCatId,
        nodeId: this.nodeId,
        title: this.title,
        author: this.auName,
        pubName: this.pubName,
        pubYear: this.pubYear,
        tags: this.tag,
        edition: this.edition,
        country: this.country,
        description: this.desc,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      // using JSON.stringify( to convert dataBody object to json
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/assets",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.updateMsg = true;
        })
        .catch((error) => {
          //console.log(error);
          this.updateMsg = false;
        })
        .finally(() => {
          loader.hide();
          this.getAssestInfo();
        });
    },
  },
};

//Options Added Function
function addOptions(ipfsData) {
  localStorage.setItem("ipfsData", JSON.stringify(ipfsData));
}
//Options Added Function
</script>

<style scoped>
.card-body {
  text-align: left;
}
.input-group-text {
  background-color: rgba(179, 179, 179, 0.993);
}

.break-text {
  word-wrap: break-word;
}

.no-decoration {
  color: brown;
}
</style>
