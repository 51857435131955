import { createRouter, createWebHistory } from "vue-router";

import NProgress from "nprogress";

import Dashboard from "@/views/Dashboard";
import Affiliates from "@/views/Affiliates";

import Shop from "@/views/Shop";
import MarketPlace from "@/views/MarketPlace";

import Callback from "@/views/Callback";
import ErrorPage from "@/views/Error";

import Contact from "@/components/Contact";

import BuyToken from "@/components/BuyToken";
import BuyCoins from "@/components/BuyCoins";

import SocialMediaPosting from "@/components/SocialMediaPosting";

import Wallet from "@/components/Wallet";
import PendingClaims from "@/components/PendingClaims";
import ImportWallet from "@/components/ImportWallet";
import TransferWallet from "@/components/TransferWallet";
import Profile from "@/components/Profile";
import MyReferrals from "@/components/MyReferrals";
import Ref from "@/components/Ref";
import PaymentSuccess from "@/components/PaymentSuccess";
import CoinPaymentSuccess from "@/components/CoinPaymentSuccess";

import PaymentFailed from "@/components/PaymentFailed";
import CoinSwap from "@/components/CoinSwap";
import MyProjects from "@/components/MyProjects";
import Login from "@/components/Login";
import Signup from "@/components/Signup";
import AffiliateRegister from "@/components/AffiliateRegister";
import UploadMedia from "@/components/UploadMedia";
import ProjectStatus from "@/components/ProjectStatus";
import CommunityProjects from "@/components/CommunityProjects";
import JobDeatils from "@/components/JobDeatils";
import ActivateWallet from "@/components/ActivateWallet";
import MarketplaceJobsubcategory from "@/components/MarketplaceJobsubcategory";
import MarketplaceJobs from "@/components/MarketplaceJobs";

import DataDexCatalogue from "@/components/DataDexCatalogue";
import DataFile from "@/components/DataFile";
import Jobs from "@/components/Jobs";
import ViewJobs from "@/components/ViewJobs";
import PublishAd from "@/components/PublishAd";
import MyAds from "@/components/MyAds";
import ViewAppliedJobs from "@/components/ViewAppliedJobs";
import ChangePassword from "@/components/ChangePassword";
import ValidateAssets from "@/views/ValidateAssets";
import MyValidation from "@/views/MyValidation";
import DownloadNode from "@/views/DownloadNode";
import ManageInvitation from "@/views/ManageInvitation";
import MyNodeStatus from "@/views/MyNodeStatus";
import NodeStatusReward from "@/views/NodeStatusReward";
import DataDexUploads from "@/views/DataDexUploads";
import SocialPosts from "@/views/SocialPosts";

import ApproveWallet from "@/views/ApproveWallet";
import ManageAccounts from "@/views/ManageAccounts";
import MyRewards from "@/views/MyRewards";
import ManagePartner from "@/views/ManagePartner";
import Message from "@/views/Message";

import { Auth } from "aws-amplify";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },

  {
    path: "/signup",
    name: "signup",
    component: Signup,
  },
  {
    path: "/auth",
    name: "auth",
    component: Login,
  },
  {
    path: "/social-posting",
    name: "social-posting",
    component: SocialMediaPosting,
    meta: { requiresAuth: true },
  },
  {
    path: "/upload-media",
    name: "upload-media",
    component: UploadMedia,
    meta: { requiresAuth: true },
  },
  {
    path: "/my-node-status",
    name: "my-node-status",
    component: MyNodeStatus,
    meta: { requiresAuth: true },
  },
  {
    path: "/project-status",
    name: "project-status",
    component: ProjectStatus,
    meta: { requiresAuth: true },
  },
  {
    path: "/support",
    name: "support",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/affiliate-register",
    name: "affiliate-register",
    component: AffiliateRegister,
    meta: { requiresAuth: true },
  },
  {
    path: "/job-details",
    name: "job-details",
    component: JobDeatils,
    meta: { requiresAuth: true },
  },
  {
    path: "/shop",
    name: "shop",
    component: Shop,
  },
  {
    path: "/affiliate",
    name: "affiliate",
    component: Affiliates,
  },

  {
    path: "/marketplace",
    name: "marketplace",
    component: MarketPlace,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/catalogue",
    name: "catalogue",
    component: DataDexCatalogue,
    meta: { requiresAuth: true },
  },
  {
    path: "/my-validation",
    name: "my-validation",
    component: MyValidation,
    meta: { requiresAuth: true },
  },
  {
    path: "/download-node",
    name: "download-node",
    component: DownloadNode,
    meta: { requiresAuth: true },
  },
  {
    path: "/data-file",
    name: "data-file",
    component: DataFile,
    meta: { requiresAuth: true },
  },
  {
    path: "/jobs",
    name: "jobs",
    component: Jobs,
    meta: { requiresAuth: true },
  },
  {
    path: "/view-jobs",
    name: "view-jobs",
    component: ViewJobs,
    meta: { requiresAuth: true },
  },
  {
    path: "/view-applied-jobs",
    name: "view-applied-jobs",
    component: ViewAppliedJobs,
    meta: { requiresAuth: true },
  },
  {
    path: "/payment-success",
    name: "PaymentSuccess",
    component: PaymentSuccess,
    meta: { requiresAuth: true },
  },
  {
    path: "/coin-payment-success",
    name: "CoinPaymentSuccess",
    component: CoinPaymentSuccess,
    meta: { requiresAuth: true },
  },
  {
    path: "/payment-failed",
    name: "PaymentFailed",
    component: PaymentFailed,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/social-posts",
    name: "social-posts",
    component: SocialPosts,
    meta: { requiresAuth: true },
  },
  {
    path: "/coin-swap",
    name: "coin-swap",
    component: CoinSwap,
    meta: { requiresAuth: true },
  },
  {
    path: "/publish-ad",
    name: "publish-ad",
    component: PublishAd,
    meta: { requiresAuth: true },
  },
  {
    path: "/my-ads",
    name: "my-ads",
    component: MyAds,
    meta: { requiresAuth: true },
  },
  {
    path: "/my-projects",
    name: "my-projects",
    component: MyProjects,
    meta: { requiresAuth: true },
  },
  {
    path: "/create-wallet",
    name: "create-wallet",
    component: Wallet,
    meta: { requiresAuth: true },
  },
  {
    path: "/import-wallet",
    name: "import-wallet",
    component: ImportWallet,
    meta: { requiresAuth: true },
  },
  {
    path: "/node-status-reward",
    name: "node-status-reward",
    component: NodeStatusReward,
    meta: { requiresAuth: true },
  },
  {
    path: "/pending-claims",
    name: "pending-claims",
    component: PendingClaims,
    meta: { requiresAuth: true },
  },
  {
    path: "/transfer-wallet",
    name: "transfer-wallet",
    component: TransferWallet,
    meta: { requiresAuth: true },
  },
  {
    path: "/my-referrals",
    name: "my-referrals",
    component: MyReferrals,
    meta: { requiresAuth: true },
  },
  {
    path: "/active-wallet",
    name: "active-wallet",
    component: ActivateWallet,
    meta: { requiresAuth: true },
  },
  {
    path: "/buy-token",
    name: "buy-token",
    component: BuyToken,
    meta: { requiresAuth: true },
  },
  {
    path: "/buy-coin",
    name: "buy-coin",
    component: BuyCoins,
    meta: { requiresAuth: true },
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePassword,
    meta: { requiresAuth: true },
  },
  {
    path: "/validate-assets",
    name: "validate-assets",
    component: ValidateAssets,
    meta: { requiresAuth: true },
  },
  {
    path: "/approve-wallet",
    name: "approve-wallet",
    component: ApproveWallet,
    meta: { requiresAuth: true },
  },
  {
    path: "/manage-accounts",
    name: "manage-accounts",
    component: ManageAccounts,
    meta: { requiresAuth: true },
  },
  {
    path: "/manage-invitation",
    name: "manage-invitation",
    component: ManageInvitation,
    meta: { requiresAuth: true },
  },
  {
    path: "/data-dex-uploads",
    name: "data-dex-uploads",
    component: DataDexUploads,
    meta: { requiresAuth: true },
  },
  {
    path: "/manage-partners",
    name: "manage-partners",
    component: ManagePartner,
    meta: { requiresAuth: true },
  },
  {
    path: "/message",
    name: "message",
    component: Message,
    meta: { requiresAuth: true },
  },
  {
    path: "/my-rewards",
    name: "my-rewards",
    component: MyRewards,
    meta: { requiresAuth: true },
  },
  {
    path: "/callback",
    name: "Callback",
    component: Callback,
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorPage,
  },
  {
    path: "/marketplace-jobsubcategory",
    name: "marketplace-jobsubcategory",
    component: MarketplaceJobsubcategory,
  },
  {
    path: "/marketplace-jobs",
    name: "marketplace-jobs",
    component: MarketplaceJobs,
  },
  {
    path: "/community-projects",
    name: "community-projects",
    component: CommunityProjects,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: Contact,
  },
  {
    path: "/ref/:id",
    name: "ref",
    component: Ref,
    meta: { requiresAuth: true },
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 };
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          path: "/auth",
          query: { redirect: to.fullPath },
        });
      });
  } else {
    next();
  }
});

router.beforeEach((To, From, next) => {
  NProgress.start();
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
