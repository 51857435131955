<template>
  <DashboardLayout>
    <div class="container">
      <h2 class="mb-3">Node Status and Reward</h2>
      <div class="card">
        <div class="card-body table-responsive">
          <table class="table table-hovarable" id="tblNode">
            <thead>
              <tr>
                <th>User</th>
                <th>Country</th>
                <th>Node Id</th>
                <th>Uptime / No of files</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(node, index) in nodeData" :key="index">
                <td>
                  {{ node.userName }}
                  <br />
                  <small>{{ node.email }}</small>
                </td>

                <td>{{ node.country }}</td>
                <td>{{ trimNode(node.nodeId, 20) }}</td>
                <td>
                  <i class="fas fa-clock fas-width"></i>
                  {{ minutesToHours(node.totalUpTime) }}
                  <br />
                  <i class="fas fa-file fas-width"></i> {{ node.numFiles }}
                </td>
                <td>
                  <button
                    class="btn btn-success btn-block btn-sm"
                    @click="rewardHistory(node.email)"
                  >
                    Reward History
                  </button>

                  <button class="btn btn-secondary btn-block btn-sm">
                    Pay Reward
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <button
      class="btn btn-secondary hidden"
      data-toggle="modal"
      data-target="#rewardsModal"
      id="btnViewRewards"
    >
      Rewards
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="rewardsModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body responsive-sm">
            <table class="table table-hovarable" id="tblReward">
              <thead>
                <tr>
                  <th>Reward Date</th>
                  <th>From Email</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="reward in rewards" :key="reward.id">
                  <td>{{ reward.trxDate }}</td>
                  <td>{{ reward.email }}</td>
                  <td>{{ reward.amount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "NodeStatusReward",
  data: () => ({
    email: "",
    nodeData: [],
    rewards: [],
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    this.email = localStorage.email;

    this.getNodeData();
  },
  methods: {
    getNodeData() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/node-stats/?email=all",
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.nodeData = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          $("#tblNode").DataTable();
          loader.hide();
        });
    },
    rewardHistory(email) {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/my-rewards/?email=dest&destEmail=" +
            email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);

          this.rewards = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
          $("#btnViewRewards").trigger("click");
        });
    },
    bytesToMegabytes(bytes) {
      return (bytes / (1024 * 1024)).toFixed(2);
    },
    minutesToHours(minutes) {
      var hours = Math.floor(minutes / 60);
      var remainingMinutes = minutes % 60;

      if (hours > 0) {
        return hours + " Hrs " + remainingMinutes.toFixed() + " Min";
      } else {
        return remainingMinutes.toFixed() + " Min";
      }
    },
    trimNode(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      } else {
        return text;
      }
    },
  },
};
</script>

<style scoped>
#tblNode {
  width: 100%;
}
#tblNode td,
th {
  vertical-align: middle;
  text-align: left;
}

#tblReward {
  width: 100%;
}
#tblReward td,
th {
  vertical-align: middle;
  text-align: left;
}

.fas-width {
  width: 20px;
}

.hidden {
  display: none;
}
</style>
