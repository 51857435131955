<template>
  <DashboardLayout>
    <div class="container">
      <div class="card" id="jstreeDivCard">
        <div class="card-header">
          <strong>தமிழ் மின் ஆவண தகவல் பகுப்பு</strong>
          <br />
          <small>Tamil Digital Data Classification</small>
        </div>
        <div class="card-body">
          <label for="PeerId" class="form-label"
            >Our Master Node (Peer Connection String)</label
          >
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Our Node connection string"
              value="/ip4/52.74.47.2/tcp/4001/p2p/12D3KooWR6PtoaHyoQZ6tJmjEdGHCNA4LMXctrvnph4DNYYLPprb"
              id="txtOutPeerIdTxtBox"
              readonly
            />
            <button class="input-group-text" id="btnPeerIdCopy">Copy</button>
          </div>
          <br />
          <div class="row">
            <!-- Col 1 -->
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-3">
              <strong>Select your favourite to replicate content</strong>
              <br />
              <br />
              <div id="jstree" class="jstree" name="jstree"></div>
            </div>
            <!-- Col 1 -->

            <!-- Col 2 -->
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="mt-2 text-center">
                <strong>My PIN List </strong> <br />
                <small>
                  (Files from the following category will be downloaded to your
                  node.)
                </small>
                <div class="table-responsive">
                  <table
                    class="table table-hover text-left"
                    style="width: 100%"
                  >
                    <thead class="thead-dark">
                      <tr>
                        <th class="text-center">SN</th>
                        <th>Date Added</th>
                        <th>Cat</th>
                        <th>Sub Cat</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(pinlist, index) in pinLists" :key="index">
                        <td class="text-center">{{ index + 1 }}</td>
                        <td>{{ pinlist.dateAdded }}</td>
                        <td>{{ pinlist.mainCategory }}</td>
                        <td>{{ pinlist.subCategory }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- Col 2 -->
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="ipfsDataModal"
      tabindex="-1"
      aria-labelledby="ipfsDataModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">தகவல் பகுப்பு - Data Classification</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <input type="hidden" id="txtSelectedCatId" value="0" />
            <input type="hidden" id="txtSelectedSubCatId" value="0" />

            <div class="container table-responsive">
              <table class="table table-striped" style="width: 100%">
                <thead>
                  <tr>
                    <th style="width: 30%">Node Id</th>
                    <th style="width: 30%">File Id</th>
                    <th style="width: 20%">Maintain By</th>
                    <th style="width: 20%">Country</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(asset, index) in assets" :key="index">
                    <td style="width: 30%">{{ asset.nodeId }}</td>
                    <td style="width: 30%">
                      <input
                        type="text"
                        class="form-control"
                        :value="asset.cid"
                        :id="'cid' + index"
                      />
                      <a :href="asset.url" target="_blank">
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                      &nbsp;
                      <i class="far fa-copy copyCid" :data-id="index"></i>
                    </td>
                    <td style="width: 20%">{{ asset.email }}</td>
                    <td style="width: 20%">{{ asset.country }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" @click="addPinList">
              <i class="fas fa-thumbtack"></i> Add To Pin List
            </button>

            <button
              type="button"
              class="btn btn-secondary btnCloseModal"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "data-dex",
  data() {
    return {
      user: {},
      accessToken: "",
      email: "",
      updateMsg: false,
      updating: false,
      fullPage: false,
      nodeId: "",
      nodes: [],
      pinLists: [],
      subClassification: 0,
      mainClassification: 0,
      assets: [],

      selectedAssestData: {
        catId: 0,
        subCatId: 0,
      },
    };
  },
  components: {
    DashboardLayout,
  },
  mounted() {
    this.email = localStorage.email;
    this.getNodeInfo();
    this.getPinlists();

    this.$nextTick(() => {
      $.getJSON("ipfs.json", function(json) {
        dataTree(json); //Data Tree View
        addOptions(json); //IPFS SELECT OPTIONS
      });
    });

    var inline = this;
    $("#ipfsDataModal").on("shown.bs.modal", function(e) {
      var catId = $(e.relatedTarget).attr("data-catId");
      var subCatId = $(e.relatedTarget).attr("data-subCatId");
      inline.getAssestInfo(catId, subCatId);

      inline.selectedAssestData.catId = catId;
      inline.selectedAssestData.subCatId = subCatId;
    });
  },
  methods: {
    saveNodeId() {
      let loader = this.$loading.show();

      this.updating = true;
      var today = new Date();

      if (this.nodeId == "") {
        loader.hide();
        $.alert({
          title: "Alert!",
          content: "Node id must needed.",
          icon: "fa fa-exclamation-triangle",
          buttons: {
            okay: {
              text: "Okay",
              btnClass: "btn-red",
            },
          },
        });
        return false;
      }

      if (this.mainClassification == "" || this.mainClassification == 0) {
        loader.hide();
        $.alert({
          title: "Alert!",
          content: "Please Select Main Classification",
          icon: "fa fa-exclamation-triangle",
          buttons: {
            okay: {
              text: "Okay",
              btnClass: "btn-red",
            },
          },
        });
        return false;
      }

      if (this.subClassification == "" || this.subClassification == 0) {
        loader.hide();
        $.alert({
          title: "Alert!",
          content: "Please Select Sub Classification",
          icon: "fa fa-exclamation-triangle",
          buttons: {
            okay: {
              text: "Okay",
              btnClass: "btn-red",
            },
          },
        });
        return false;
      }

      const dataBody = {
        registerDate: today.toLocaleDateString("en-US"),
        email: this.email,
        peerId: this.nodeId,
        catId: this.mainClassification,
        subCatId: this.subClassification,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      // using JSON.stringify( to convert dataBody object to json
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/ipfs",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.updateMsg = true;
        })
        .catch((error) => {
          //console.log(error);
          this.updateMsg = false;
        })
        .finally(() => {
          loader.hide();
          this.getNodeInfo();
        });
    },
    getNodeInfo() {
      let loader = this.$loading.show();
      // GET request using axios with async/await
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .get(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/assets/by-user/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);

          for (let i = 0; i < response.data.length; i++) {
            var catId = response.data[i]["catId"];
            var subCatId = response.data[i]["subCatId"];
            const ipfsData = JSON.parse(localStorage.getItem("ipfsData"));
            for (let x = 0; x < ipfsData.length; x++) {
              var mainCatId = ipfsData[x]["id"];
              if (mainCatId == catId) {
                const ipfsSubCat = ipfsData[x]["nodes"];
                response.data[i]["catId"] = ipfsData[x]["text"].split("|")[0];

                for (let y = 1; y < ipfsSubCat.length; y++) {
                  if (subCatId == ipfsSubCat[y]["id"]) {
                    response.data[i]["subCatId"] = ipfsSubCat[y]["text"];
                  }
                }
              }
            }
          }

          this.nodes = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getAssestInfo(catId, subCatId) {
      let loader = this.$loading.show();

      var inline = this;
      $.getJSON("https://static.TamilData.org/assets.json", function(json) {
        const sortAsset = Array();
        $.each(json, function(index, item) {
          if (item.catId == catId && item.subCatId == subCatId) {
            item["url"] = "https://gateway.ipfs.io/ipfs/" + item.cid;
            sortAsset.push(item);
          }
        });
        inline.assets = sortAsset;
        //console.log(inline.assets);
      });

      loader.hide();
    },
    addPinList() {
      var today = new Date();

      const addedBy = this.email;
      const dateAdded = today.toLocaleDateString("en-US");
      const catId = this.selectedAssestData.catId;
      const subCatId = this.selectedAssestData.subCatId;

      $(".btnCloseModal").trigger("click");
      let loader = this.$loading.show();

      const dataBody = {
        addedBy: addedBy,
        dateAdded: dateAdded,
        catId: catId,
        subCatId: subCatId,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .post(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/pin-list",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);

          Swal.fire({
            title: "Successfully Added",
            icon: "success",
          }).then((result) => {
            this.getPinlists();
          });
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getPinlists() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/pin-list/?addedBy=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);

          const ipfsData = JSON.parse(localStorage.getItem("ipfsData"));
          const assests = response.data;
          const finalAssests = [];

          $.each(assests, function(key, value) {
            var categoryId = value.catId;
            var subCategoryId = value.subCatId;

            const category = getCategoryName(
              ipfsData,
              categoryId,
              subCategoryId
            );

            value["mainCategory"] = category["mainCategory"];
            value["subCategory"] = category["subCategory"];

            finalAssests.push(value);
          });

          this.pinLists = finalAssests;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};

//Data Tree view Function
function dataTree(json) {
  var html = "<ul style='list-style-type: none;'>";
  $.each(json, function(index, item) {
    ////console.log(item);
    html +=
      "<li> <strong> <i class='fas fa-folder-open'></i> " +
      item.text +
      "</strong>";
    html += "<ul style='list-style-type: none;'>";
    $.each(item.nodes, function(index1, item1) {
      html +=
        "<li> <i class='far fa-folder'></i> " +
        item1.text +
        " <a href='#' class='no-decoration text-danger' data-toggle='modal' data-target='#ipfsDataModal' data-catId='" +
        item.id +
        "' data-subCatId='" +
        item1.id +
        "'><i class='fas fa-expand-alt'></i></a></li>";
    });
    html += "</ul>";
    html += "<br>";
    html += "</li>";
  });
  html += "</ul>";

  $("#jstree").append(html);
}
//Data Tree view Function

//Options Added Function
function addOptions(ipfsData) {
  const arrayLength = ipfsData.length;
  localStorage.setItem("ipfsData", JSON.stringify(ipfsData));
  var ipfsOptions = "<option value='0' hidden>Choose...</option>";
  for (let i = 0; i < arrayLength; i++) {
    var optionData = ipfsData[i]["text"].split("|")[0];
    var optionValue = ipfsData[i]["id"];
    ipfsOptions +=
      "<option value='" + optionValue + "'>" + optionData + "</option>";
  }
  $("#dropMainCat").html(ipfsOptions);
}
//Options Added Function

function getCategoryName(jsonData, mainCategoryId, subCategoryId) {
  let mainCategoryText = "";
  let subCategoryText = "";
  for (let mainCategory of jsonData) {
    if (mainCategory.id == mainCategoryId) {
      mainCategoryText = mainCategory.text;
      mainCategoryText = mainCategoryText.split("|")[0];

      for (let subCategory of mainCategory.nodes) {
        if (subCategory.id == subCategoryId) {
          subCategoryText = subCategory.text;
          break;
        }
      }

      break;
    }
  }

  return {
    mainCategory: mainCategoryText,
    subCategory: subCategoryText,
  };
}

//Main cat change function
$("body").on("change", "#dropMainCat", function() {
  var catId = $("#dropMainCat").val();
  const ipfsData = JSON.parse(localStorage.getItem("ipfsData"));
  const arrayLength = ipfsData.length;
  var ipfsSubOptions = "<option value='0' hidden>Choose...</option>";
  for (let i = 0; i < arrayLength; i++) {
    var mainCatId = ipfsData[i]["id"];
    if (mainCatId == catId) {
      const ipfsSubCat = ipfsData[i]["nodes"];
      const subArrayLength = ipfsSubCat.length;

      for (let i = 1; i < subArrayLength; i++) {
        var subOptionData = ipfsSubCat[i]["text"];
        var subOptionValue = ipfsSubCat[i]["id"];
        ipfsSubOptions +=
          "<option value='" +
          subOptionValue +
          "'>" +
          subOptionData +
          "</option>";
      }
    }
  }
  $("#dropSubCat").html(ipfsSubOptions);
});
//Main cat change function

$("body").on("click", "#btnPeerIdCopy", function() {
  console.log("copy");
  $("#txtOutPeerIdTxtBox").select();
  document.execCommand("copy");
});

$("body").on("click", ".copyCid", function() {
  var id = $(this).attr("data-id");
  $("#cid" + id).select();
  document.execCommand("copy");
});
</script>

<style scoped>
.card-body {
  text-align: left;
}
.input-group-text {
  background-color: rgba(179, 179, 179, 0.993);
}
.break-text {
  word-wrap: break-word;
}
.no-decoration {
  color: brown;
}
.copyCid {
  cursor: pointer;
}
.table td {
  vertical-align: middle;
}
</style>
