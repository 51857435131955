<template>
  <div class="container-fluid">
    <h4 class="mt-5 mb-3">
      🙏 உங்கள் வரவு நல்வரவாகட்டும். <br />
      Welcome all Tamil language lovers from all over the world.
    </h4>

    <div class="row">
      <!-- Col 1 -->
      <div class="col-sm-12 col-md-6 mb-3">
        <!--Sign Up Card-->
        <div class="card mt-5 mb-3" v-show="!signupConfirmation">
          <div class="card-header">
            <h4>
              புதிய கணக்கு தொடங்குதல்
              <br />
              Register new account
            </h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="register">
              <div class="form-floating mb-3">
                <input
                  type="email"
                  class="form-control"
                  v-model="email"
                  placeholder="Email address..."
                  autocomplete="off"
                  required
                />
                <label for="floatingInput">Email address</label>
              </div>

              <div class="form-floating mb-3">
                <input
                  type="password"
                  class="form-control"
                  v-model="password"
                  placeholder="password..."
                  autocomplete="off"
                  required
                />
                <label for="floatingPassword">
                  Password
                  <small>(Minimum 8 characters)</small>
                </label>
              </div>

              <div class="form-action">
                <button class="btn btn-warning text-white w-100">
                  Register
                </button>

                <router-link to="/login" class="btn btn-secondary w-100 mt-2">
                  Back to Sign In
                </router-link>
              </div>
            </form>
          </div>
        </div>
        <!--Sign Up Card-->

        <!--Confirmation Card-->
        <div class="card mb-3" v-show="signupConfirmation">
          <div class="card-header">
            <h4>Confirm Sign Up</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="confirmSignUp">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="confirmCode"
                  placeholder="Enter Confirmation Code..."
                />
                <label for="floatingInput">Confirmation Code</label>
              </div>

              <div class="form-action">
                <button class="btn btn-warning text-white w-100">
                  Confirm
                </button>

                <router-link to="/login" class="btn btn-secondary w-100 mt-2">
                  Back to Sign In
                </router-link>
              </div>
            </form>

            <div class="mt-3">
              <div class="alert alert-success fade show" role="alert">
                உங்கள் கணக்கு ஆரம்பிக்க இன்னும் ஓர் படிமுறை மீதம் இருக்கிறது.
                உங்கள் மின்னஞ்சலை திறந்து, உறுதிப்படுத்தல் குறியீட்டை எடுத்து
                மேலே பதிவிடவும்.

                <br />
                <br />

                Please check your email for confirmation code. Enter your
                confirmation code above to finish account creation.
              </div>
            </div>
          </div>
        </div>
        <!--Confirmation Card-->
      </div>
      <!-- Col 1 -->

      <!-- Col 2 -->
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="card mt-5 mb-5">
          <div class="card-header">
            <h5>
              📈 தமிழ் மின் நாணயம் 📈 <br />
             TAMIL Data Decentralization Project 
            </h5>
          </div>
          <div class="card-body card-justify">
            <p>
              செம்மொழிகளுள் ஒன்றாகிய தமிழ்மொழி, எக்காலத்திற்கும் உகந்த மொழியாக
              வளர்ந்துள்ளது. இதே தனித் தன்மையில், எதிர்காலத்திலும் தொடர்ந்து
              வளர்ந்து, சிறப்பும் செழிப்பும் நிறைந்த மொழியாகத் திகழ வேண்டும்
              என்பதையும் கருத்தில் கொண்டு தமிழ் மொழியின் மேம்பாட்டிற்கும்
              வளர்ச்சிக்கும் உறுதுணையாக விளங்கி, சிறப்பாகச் செயல்படும் தமிழ்
              அமைப்புகளையும், தனிநபர்களையும், தமிழ் ஆர்வலர்களையும் ஒன்று
              திரட்டி,மொழி வளர்ச்சிக்கு வலுவூட்டும் நடவடிக்கைகளைத் தமிழ்ச்
              சமூகத்துடன் ஒன்றிணைந்து தமிழ் மொழியை கட்டச்சங்கிலி (Blockchain)
              தொழினுட்பத்திலும் இடம் பெற்று மிளிரச் செய்வதே TamilData.org
              செயல்திட்டமாகும்.
              <br />
              <br />
              இணையப்பக்கம் => https://TamilData.org
              <br />
              தந்தி => https://t.me/TamilData.org
            </p>
          </div>
        </div>
      </div>
      <!-- Col 2 -->
    </div>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "AuthWithSlots",
  data() {
    return {
      email: "",
      password: "",
      confirmCode: "",
      signupConfirmation: false,
    };
  },
  methods: {
    async register() {
      let loader = this.$loading.show();
      try {
        await Auth.signUp({
          username: this.email,
          password: this.password,
        });
        this.signupConfirmation = true;
        loader.hide();
      } catch (error) {
        loader.hide();

        if (error.code == "UsernameExistsException") {
          Swal.fire({
            html:
              "<strong>மன்னிக்கவும்!</strong> <br> உங்களுக்கு TamilData.org கணக்கு ஏற்கனவே உள்ளது. <br> <br> <strong>Sorry!</strong> <br> Your account already exists.",
            icon: "error",
          });
          return false;
        }

        if (error.code == "InvalidPasswordException") {
          Swal.fire({
            html:
              "<strong>மன்னிக்கவும்!</strong> <br> கடவுச்சொல் குறைந்தது 8 எழுத்துகள் கொண்டதாக இருத்தல் வேண்டும் <br> <br> <strong>Sorry!</strong> <br> Minimum password length 8 characters.",
            icon: "error",
          });
          return false;
        }
      }
    },
    async confirmSignUp() {
      let loader = this.$loading.show();

      var username = this.email;
      var authCode = this.confirmCode;
      try {
        await Auth.confirmSignUp(username, authCode);
        loader.hide();
        this.$router.push("/login");
      } catch (error) {
        loader.hide();

        if (error.code == "CodeMismatchException") {
          Swal.fire({
            html:
              "தவறான உறுதிப்படுத்தல் குறியீடு!</strong> <br> சரியான உறுதிப்படுத்தல் குறியீட்டை உள்ளிடவும் <br> <br> <strong>Invalid confirmation code!</strong> <br> Please enter correct confirmation code.",
            icon: "error",
          });
          return false;
        }
      }
    },
  },
};
</script>

<style scoped>
.card-justify {
  text-align: justify;
}
</style>
