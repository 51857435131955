<template>
  <DashboardLayout>
    <div class="container">
      <!-- Coin Img -->
      <div class="row">
        <div>
          <a href="#" @click="changeWalleTextusdt">
            <img
              src="../assets/images/usdtcoin.png"
              class="card-img pl-2"
              alt="..."
              id="imgUsdt"
            />
          </a>
          <a href="#" @click="changeWalleTextxlm">
            <img
              src="../assets/images/xlm-coin.png"
              class="card-img pl-2"
              alt="..."
              id="imgXlm"
            />
          </a>
          <a href="#" @click="changeWalleTextxrp">
            <img
              src="../assets/images/xrp-coin.png"
              class="card-img pl-2 active-img"
              alt="..."
              id="imgXrp"
            />
          </a>
          <a href="#" @click="changeWalleTextethereum">
            <img
              src="../assets/images/ethereum-coin.jpg"
              class="card-img pl-2"
              alt="..."
              id="imgEthereum"
            />
          </a>
        </div>
      </div>
      <!-- Coin Img -->

      <!--Success Msg-->
      <div v-show="updateMsg" class="mt-3">
        <div class="alert alert-success fade show" role="alert">
          <i class="fas fa-check-circle"></i> &nbsp;
          <strong>Success!</strong> Wallet Address has been updated.
        </div>
      </div>
      <!--Success Msg-->

      <!-- Wallet Card -->
      <div class="mt-3">
        <div class="card mb-2">
          <div class="card-body">
            <div class="mb-3">
              <label for="walletAddress" class="form-label">
                Enter Your {{ WalletText }} Wallet Address
              </label>
              <input
                type="text"
                class="form-control"
                id="walletAddress"
                v-model="walletAddress"
                autocomplete="off"
              />
            </div>
            <div class="mb-3">
              <label for="transferAmount" class="form-label">
                Tranfer Amount
              </label>
              <input
                type="number"
                class="form-control"
                id="transferAmount"
                v-model="transferAmount"
                autocomplete="off"
              />
            </div>

            <div class="mb-3 float-right">
              <button class="btn btn-danger" type="button" @click="saveWallet">
                Save
              </button>
            </div>

            <div class="mb-3">
              <label for="walletAddress" class="form-label">
                Please send {{ WalletText }} to our official address
              </label>

              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="officialWalletAddress"
                  readonly
                  id="txtOffcialAddress"
                />
                <a class="input-group-text" id="btnOffcialAddCopy">Copy</a>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left">
              <button
                class="btn btn-warning"
                type="button"
                @click="getTranferStatus"
              >
                Get Transfer Status
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Wallet Card -->

      <!--Table Card-->
      <div class="card mt-3">
        <div class="card-body">
          <table class="table table-hover">
            <thead class="thead-dark">
              <tr>
                <th class="text-center">Date Time</th>
                <th class="text-right">Amount</th>
                <th class="text-center">Currency</th>
                <th class="text-center">Wallet Address</th>
                <th class="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="tranfer in tranfers" :key="tranfer.id">
                <td class="text-center">{{ tranfer.registerDate }}</td>
                <td class="text-right">{{ tranfer.amount }}</td>
                <td class="text-center">{{ tranfer.walletCurrency }}</td>
                <td class="text-center">{{ tranfer.walletAddress }}</td>
                <td class="text-center">{{ tranfer.trxStatus }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--Table Card-->
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

export default {
  name: "coin-swap",
  data() {
    return {
      accessToken: "",
      email: "",
      walletAddress: "",
      updateMsg: false,
      updating: false,
      WalletText: "XRP",
      officialWalletAddress: "rGs469WHAXNWa3ELDemrCYe5aJniwna85a", //Default XRP
      transferAmount: 0,
      tranfers: [],
    };
  },
  components: {
    DashboardLayout,
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getWalletInfo();
    }
  },
  methods: {
    saveWallet() {
      let loader = this.$loading.show();

      if (this.walletAddress == "") {
        loader.hide();
        $.alert({
          title: "Alert!",
          content: "Wallet address can't be Empty.",
          icon: "fa fa-exclamation-triangle",
          animation: "scale",
          closeAnimation: "scale",
          buttons: {
            okay: {
              text: "Okay",
              btnClass: "btn-blue",
            },
          },
        });
        return false;
      }

      if (this.transferAmount <= 0 || this.transferAmount == "") {
        loader.hide();
        $.alert({
          title: "Alert!",
          content: "Amount can't be less than <em>OR</em> equal '0'.",
          icon: "fa fa-exclamation-triangle",
          animation: "scale",
          closeAnimation: "scale",
          buttons: {
            okay: {
              text: "Okay",
              btnClass: "btn-blue",
            },
          },
        });
        return false;
      }

      var today = new Date();
      const dataBody = {
        registerDate: today.toLocaleString("en-US"),
        email: this.email,
        walletAddress: this.walletAddress,
        walletCurrency: this.WalletText,
        amount: this.transferAmount,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/add-swap",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            this.updateMsg = true;
            this.walletAddress = "";
          } else {
            this.updateMsg = false;
          }
        })
        .catch((error) => {
          //console.log(error.response);
          this.updateMsg = false;
        })
        .finally(() => {
          this.getWalletInfo();
          loader.hide();
        });
    },
    getWalletInfo() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/add-swap/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          this.tranfers = response.data;
          //console.log(response);
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    changeWalleTextusdt() {
      this.WalletText = "USDT";
      this.updateMsg = false;
      this.officialWalletAddress = "0xa1D9690325730F0D37FaA0D5C00aDC1577De478b";
      this.transferAmount = 0;
      this.walletAddress = "";
      $(".card-img").removeClass("active-img");
      $("#imgUsdt").addClass("active-img");
    },
    changeWalleTextxlm() {
      this.WalletText = "XLM";
      this.updateMsg = false;
      this.officialWalletAddress =
        "GA4UQIBIYRWWPMI2XQL3UJESBRFOHJKQFJLAQPJKVCPPHYPY475AYQYY";
      this.transferAmount = 0;
      this.walletAddress = "";
      $(".card-img").removeClass("active-img");
      $("#imgXlm").addClass("active-img");
    },
    changeWalleTextxrp() {
      this.WalletText = "XRP";
      this.updateMsg = false;
      this.officialWalletAddress = "rGs469WHAXNWa3ELDemrCYe5aJniwna85a";
      this.transferAmount = 0;
      this.walletAddress = "";
      $(".card-img").removeClass("active-img");
      $("#imgXrp").addClass("active-img");
    },
    changeWalleTextethereum() {
      this.WalletText = "Ethereum";
      this.updateMsg = false;
      this.officialWalletAddress = "0x4ddeD66D69d7B310dD4506d257e58678f2C59754";
      this.transferAmount = 0;
      this.walletAddress = "";
      $(".card-img").removeClass("active-img");
      $("#imgEthereum").addClass("active-img");
    },
  },
};

$("body").on("click", "#btnOffcialAddCopy", function() {
  $("#txtOffcialAddress").select();
  document.execCommand("copy");
});
</script>

<style scoped>
.card-img {
  max-width: 70px;
  opacity: 0.3;
}
.pl-2 {
  padding-left: 10px;
}
.card-body {
  text-align: left;
}
.float-right {
  text-align: right;
}
.float-left {
  text-align: left;
}
.input-group-text {
  background-color: rgb(179, 194, 194);
  cursor: pointer;
  text-decoration: none;
}
.active-img {
  opacity: 1;
}

.card-img:hover {
  opacity: 1;
}
</style>
