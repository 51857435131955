<template>
  <DashboardLayout>
    <div class="container">
      <h5>
        Welcome <br />
        {{ fullName }}
      </h5>

      <div class="mt-2 mb-2">
        <img :src="profilePicture" id="profile-image" alt="Profile Picture" />
        <input
          id="profilePictureUpload"
          type="file"
          accept="image/jpeg"
          @change="onFileChange"
        />
        <div v-show="uploadProfilePicBtn" class="mt-3">
          <button
            class="btn btn-warning text-white mb-2 btn-sm"
            @click="uploadImage"
          >
            Upload image
          </button>
          <br />
          <button class="btn btn-danger btn-sm" @click="removeImage">
            Close
          </button>
        </div>
      </div>

      <form>
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="fullName"
            v-model="fullName"
            placeholder="Enter Your Name..."
          />
          <label for="fullName">Name</label>
        </div>

        <div class="form-floating mb-3">
          <input
            type="number"
            class="form-control"
            id="mobile"
            aria-describedby="mobileHelp"
            v-model="mobile"
            placeholder="Enter Your Mobile Number..."
          />
          <label for="mobile">
            Mobile &nbsp;
            <span class="float-right">
              <small class="small-xl">
                <i class="fas fa-info-circle"></i> We'll never share your mobile
                number with anyone else.
              </small>
            </span>
          </label>
        </div>

        <div class="form-floating mb-3">
          <input
            type="email"
            class="form-control"
            id="email"
            aria-describedby="emailHelp"
            v-model="email"
            placeholder="Enter Your Email..."
          />
          <label for="email">
            Email address &nbsp;
            <span class="float-right">
              <small class="small-xl">
                <i class="fas fa-info-circle"></i> We'll never share your email
                with anyone else.
              </small>
            </span>
          </label>
        </div>

        <div class="form-floating mb-3">
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="selectedmotherTongue"
          >
            <option
              v-for="motherTongue in motherTongues"
              v-bind:key="motherTongue.id"
              :value="motherTongue.name"
            >
              {{ motherTongue.name }}
            </option>
          </select>
          <label for="motherTongue">Mother Tongue</label>
        </div>

        <div class="form-floating mb-3">
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="selectedCountryBirth"
          >
            <option
              v-for="country in countries"
              v-bind:key="country.id"
              :value="country.name"
            >
              {{ country.name }}
            </option>
          </select>
          <label for="countryBirth">Country of birth</label>
        </div>

        <div class="form-floating mb-3">
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="selectedCountryResidence"
          >
            <option
              v-for="country in countries"
              v-bind:key="country.id"
              :value="country.name"
            >
              {{ country.name }}
            </option>
          </select>
          <label for="countryResidence">Country of residence</label>
        </div>

        <div class="form-floating mb-3">
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="selectedReligion"
          >
            <option
              v-for="religion in religions"
              v-bind:key="religion.id"
              :value="religion.name"
            >
              {{ religion.name }}
            </option>
          </select>
          <label for="religion">Religion</label>
        </div>

        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="telegramUserName"
            v-model="telegramUserName"
            placeholder="Enter Telegram Your Name..."
          />
          <label for="telegramUserName">Telegram Username</label>
        </div>

        <div class="form-floating mb-3">
          <textarea
            class="form-control"
            id="userAddress"
            v-model="address"
            placeholder="Enter your address..."
          />
          <label for="telegramUserName">Address</label>
        </div>

        <div class="mb-3">
          <button
            class="btn btn-warning text-white btn-lg btn-block"
            type="button"
            @click="updateProfile"
            id="btnUpdateProfile"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import $ from "jquery";
import defaultProfilePic from "../assets/images/sample-profile.png";
const MAX_IMAGE_SIZE = 1000000;
const API_ENDPOINT =
  "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/upload-media";

export default {
  name: "profile",
  data() {
    return {
      user: {},
      accessToken: "",
      fullName: "",
      telegramUserName: "",
      motherTongue: "",
      email: "",
      countryBirth: "",
      countryResidence: "",
      religion: "",
      mobile: "",
      address: "",
      profilePicture: defaultProfilePic,
      uploadProfilePicBtn: false,
      selectedReligion: "Tamilism",
      religions: [
        { id: 1, name: "Tamilism" },
        { id: 2, name: "Hinduism" },
        { id: 3, name: "Islam" },
        { id: 4, name: "Catholic" },
        { id: 5, name: "Athiest" },
        { id: 6, name: "Christian" },
        { id: 7, name: "Others" },
      ],
      selectedCountryBirth: "India",
      selectedCountryResidence: "India",
      countries: [
        { id: 1, name: "India" },
        { id: 2, name: "Srilanka" },
        { id: 3, name: "Malaysia" },
        { id: 4, name: "United States" },
        { id: 5, name: "France" },
        { id: 6, name: "Australia" },
        { id: 7, name: "Switzerland" },
        { id: 8, name: "Singapore" },
        { id: 9, name: "Denmark" },
        { id: 10, name: "United Arab Emirates" },
        { id: 11, name: "Italy" },
        { id: 12, name: "Germany" },
        { id: 13, name: "United Kingdom" },
        { id: 14, name: "Canada" },
        { id: 15, name: "Others" },
      ],
      selectedmotherTongue: "Tamil",
      motherTongues: [
        { id: 1, name: "Tamil" },
        { id: 2, name: "English" },
        { id: 3, name: "Others" },
      ],
      fullPage: false,
    };
  },
  mounted() {
    this.email = localStorage.email;
    this.getInfo();
  },
  components: {
    DashboardLayout,
  },
  methods: {
    updateProfile: function() {
      let loader = this.$loading.show();

      var parts = this.profilePicture.split("?v=");
      var newUrl = parts[0];

      var today = new Date();
      const dataBody = {
        registerDate: today.toLocaleDateString("en-US"),
        email: this.email,
        fullName: this.fullName,
        mobile: this.mobile,
        countryBirth: this.selectedCountryBirth,
        countryResidence: this.selectedCountryResidence,
        motherTongue: this.selectedmotherTongue,
        religion: this.selectedReligion,
        telegramUserName: this.telegramUserName,
        address: this.address,
        profileImage: newUrl,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .post(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/update-profile",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          var htmlMsg =
            "சுயவிவங்கள் புதுப்பிக்கப்பட்டது. <br /> <br /> profile details has been updated.";

          Swal.fire({
            title: "Success",
            icon: "success",
            html: htmlMsg,
          }).then((result) => {
            this.$router.push("/profile");
          });
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getInfo() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/update-profile/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);

          this.email = response.data[0].email;
          this.fullName = response.data[0].fullName;
          this.mobile = response.data[0].mobile;
          this.selectedReligion = response.data[0].religion;
          this.selectedmotherTongue = response.data[0].motherTongue;
          this.selectedCountryResidence = response.data[0].countryResidence;
          this.selectedCountryBirth = response.data[0].countryBirth;
          this.telegramUserName = response.data[0].telegramUserName;
          this.address = response.data[0].address;

          //Check Have Upload Profile Picture
          if (
            response.data[0].profileImage &&
            response.data[0].profileImage != "-"
          ) {
            this.profilePicture =
              response.data[0].profileImage + "?v=" + Math.random();
          }
          //Check Have Upload Profile Picture
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let loader = this.$loading.show();
      let reader = new FileReader();
      reader.onload = (e) => {
        console.log("length: ", e.target.result.includes("data:image/jpeg"));
        if (!e.target.result.includes("data:image/jpeg")) {
          return alert("Wrong file type - JPG only.");
        }
        if (e.target.result.length > MAX_IMAGE_SIZE) {
          return alert("Image is loo large - 1Mb maximum");
        }
        this.profilePicture = e.target.result;
        this.uploadProfilePicBtn = true;
      };
      reader.readAsDataURL(file);
      loader.hide();
    },
    removeImage: function(e) {
      console.log("Remove clicked");
      this.profilePicture = defaultProfilePic;
      this.uploadProfilePicBtn = false;
    },
    uploadImage: async function(e) {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // Get the presigned URL
      const response = await axios({
        method: "GET",
        url: API_ENDPOINT,
        headers: headers,
      });
      console.log("Response: ", response.data);

      let binary = atob(this.profilePicture.split(",")[1]);
      let array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      let blobData = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
      console.log("Uploading to: ", response.data.uploadURL);
      const result = await fetch(response.data.uploadURL, {
        method: "PUT",
        body: blobData,
      });
      console.log("Result: ", result);

      if (result.status == 200) {
        this.uploadImageData(response.data.photoFilename);
      }
      loader.hide();
    },
    uploadImageData(fileName) {
      let loader = this.$loading.show();

      var path =
        "https://s3.ap-southeast-1.amazonaws.com/static.TamilData.org/" +
        fileName;

      const dataBody = {
        email: this.email,
        profileImage: path,
      };

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/update-referral/profile-image",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((responseData) => {
          //console.log(responseData);
          this.uploadProfilePicBtn = false;

          var htmlMsg =
            "சுயவிவரப் படம் புதுப்பிக்கப்பட்டது. <br /> <br /> profile picture has been updated";

          Swal.fire({
            title: "Success",
            icon: "success",
            html: htmlMsg,
          }).then((result) => {});
        })
        .catch((error) => {
          //console.log(error.response);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};

$("body").on("click", "#profile-image", function() {
  console.log("file selection open");
  $("#profilePictureUpload").trigger("click");
});
</script>

<style scoped>
.small-xl {
  font-size: 10px;
  color: rgb(194, 194, 194);
}
#profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #ddd;
  padding: 5px;
  cursor: pointer;
}
#profilePictureUpload {
  display: none;
}
</style>
