<template>
  <DashboardLayout>
    <div class="container">
      <div class="card">
        <div class="card-body">
          <div class="group">
            <label for="walletName">Source Account Private Key</label>
            <div class="form-group input-group">
              <input
                type="password"
                class="form-control"
                id="sourceAccPrivateKey"
                placeholder="Enter Account Private Key"
                autocomplete="off"
                v-model="privateKey"
              />
              <div class="input-group-prepend">
                <span
                  class="input-group-text required"
                  id="inputGroup-sizing-default"
                >
                  <a href="#" type="button" id="togglePassword">
                    <i class="far fa-eye"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div class="group">
            <label for="walletName">Tranfer Amount</label>
            <div class="form-group input-group">
              <input
                type="number"
                class="form-control"
                id="destTranferAmount"
                placeholder="Enter Tranfer Amount"
                autocomplete="off"
                v-model="tranferAmount"
              />
            </div>
          </div>
          <div class="text-center mt-3 mb-3">
            <i class="fas fa-arrow-circle-down fa-3x"></i>
          </div>

          <div class="group">
            <label for="walletName">Dest Public Key</label>
            <div class="form-group input-group">
              <input
                type="text"
                class="form-control"
                id="destAcPublicKey"
                placeholder="Enter Dest Public Key"
                autocomplete="off"
                v-model="destAcPublicKey"
              />
            </div>
          </div>

          <div class="group">
            <label for="walletName">Dest Email</label>
            <div class="form-group input-group">
              <input
                type="text"
                class="form-control"
                id="destEmail"
                placeholder="Enter Dest Email"
                autocomplete="off"
                v-model="destEmail"
              />
            </div>
          </div>
        </div>
      </div>

      <!--warning  Msg-->
      <div v-show="lowBalanceWrnMsg">
        <div class="alert alert-warning fade show mt-3 mb-3" role="alert">
          <i class="fas fa-exclamation-triangle"></i> &nbsp;
          <strong>Failed !</strong> Wallet balance is Low.
        </div>
      </div>
      <!--warning  Msg-->

      <div class="form-action mt-2 mb-2">
        <button
          class="btn btn-danger btn-block"
          type="button"
          @click="tranferTamil"
          id="btnAmountTransfer"
        >
          <img src="@/assets/images/coin.png" class="img-coin" />
          Transfer
        </button>
      </div>

      <!--Success Msg-->
      <div v-show="tranferSuccessMsg">
        <div class="alert alert-success fade show" role="alert">
          <i class="fas fa-check-circle"></i> &nbsp;
          <strong>வெற்றி!</strong> பணப்பை விவரங்கள் புதுப்பிக்கப்பட்டது. <br />
          <br />
          <i class="fas fa-check-circle"></i> &nbsp;
          <strong>Success!</strong> amount transfered.
        </div>
      </div>
      <!--Success Msg-->

      <!--Error Msg-->
      <div v-show="tranferErrorMsg">
        <div class="alert alert-danger fade show" role="alert">
          <i class="fas fa-exclamation-triangle"></i> &nbsp;
          <strong>Payment transaction failed.</strong>
        </div>
      </div>
      <!--Error Msg-->

      <!--Transfer History-->
      <div class="card mt-4">
        <div class="card-header">
          <h5>Transfer History</h5>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Date</th>
                <th>Destination Email</th>
                <th>Reward Type</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="trans in transHistory" :key="trans.id">
                <td>{{ trans.trxDate }}</td>
                <td>{{ trans.destinationEmail }}</td>
                <td>{{ trans.rewardType }}</td>
                <td>{{ trans.amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--Transfer History-->
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

export default {
  name: "wallet",
  data: () => ({
    walletName: "",
    email: "",
    walletAddress: "",
    privateKey: "",
    dateCreated: "",
    balance: "00.00",
    destAcPublicKey: "",
    destEmail: "",
    tranferAmount: "",
    lowBalanceWrnMsg: false,
    tranferSuccessMsg: false,
    tranferErrorMsg: false,
    transHistory: [],
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getWalletInfo();
      this.getTransferHistory();
    }
  },
  methods: {
    getWalletInfo() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // GET wallet info using axios
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.walletName = response.data[0].walletName;
          this.dateCreated = response.data[0].dateCreated;
          this.walletAddress = response.data[0].publicKey;
          //this.privateKey = response.data[0].privateKey;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          this.getBalance();
          loader.hide();
        });
    },
    getTransferHistory() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // GET wallet info using axios
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/claimmable-balance/cognito/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          this.transHistory = response.data;
          //console.log(response);
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getBalance() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      var address = this.walletAddress;
      axios
        .get("https://horizon.stellar.org/accounts/" + address, {
          headers: headers,
        })
        .then((response) => {
          //console.log(response);
          const balances = response.data.balances;
          balances.forEach((entry) => {
            if (entry.asset_code == "TAMIL") {
              //console.log(parseFloat(entry.balance).toFixed(2));
              this.balance = parseFloat(entry.balance).toFixed(2);
            }

            if (this.balance > 0) {
              this.lowBalanceWrnMsg = false;
              $("#btnAmountTransfer").removeAttr("disabled");
            } else {
              this.lowBalanceWrnMsg = true;
              $("#btnAmountTransfer").attr("disabled", "disabled");
            }
          });
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    tranferTamil() {
      this.lowBalanceWrnMsg = false;
      this.tranferErrorMsg = false;
      this.tranferSuccessMsg = false;

      if (this.balance < this.tranferAmount) {
        this.lowBalanceWrnMsg = true;
        return false;
      }

      let loader = this.$loading.show();

      const dataBody = {
        sourceAcPrivateKey: this.privateKey,
        claimmantPublicKey: this.destAcPublicKey,
        destinationEmail: this.destEmail,
        amount: this.tranferAmount,
        rewardType: "transfer",
      };
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      const dataBodyJson = JSON.stringify(dataBody);
      //console.log(dataBodyJson);
      axios
        .post(
          ` https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/claimmable-balance/cognito`,
          dataBodyJson,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.tranferSuccessMsg = true;
        })
        .catch((error) => {
          //console.log(error);
          this.tranferErrorMsg = true;
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};

$("body").on("click", "#togglePassword", function() {
  var type = $("#sourceAccPrivateKey").attr("type");
  if (type == "text") {
    $("#sourceAccPrivateKey").attr("type", "password");
    $("#togglePassword").html("<i class='far fa-eye'></i>");
  } else {
    $("#sourceAccPrivateKey").attr("type", "text");
    $("#togglePassword").html("<i class='far fa-eye-slash'></i>");
  }
});
</script>

<style scoped>
.card-body {
  text-align: left;
}
.form-group {
  text-align: left;
}
.btn-light {
  background-color: rgba(255, 255, 255, 0.664);
}
.text-right {
  text-align: right;
}
.break-text {
  word-wrap: break-word;
}
.note-wallet-text {
  color: red;
  font-weight: bold;
}
.alert a {
  text-decoration: none;
  font-weight: bold;
}
.group label {
  font-size: 15px;
  margin-left: 5px;
  color: rgb(105, 102, 102);
}
.img-coin {
  width: 25px;
}
</style>
