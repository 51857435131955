<template>
  <DashboardLayout>
    <div class="container">
      <div class="card">
        <div class="card-body">
          <table class="table table-hovarable" id="tblPendingClaims">
            <thead>
              <tr>
                <th>Transaction Date</th>
                <th>Reward Type</th>
                <th>Amount</th>
                <th>Claim Id</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="claim in pendingClaims" :key="claim.id">
                <td>{{ claim.trxDate }}</td>
                <td>{{ claim.rewardType }}</td>
                <td>{{ claim.amount }}</td>
                <td>
                  <input
                    type="text"
                    :value="claim.claimId"
                    class="form-control"
                  />
                </td>
                <td>
                  <a
                    href="https://stellarterm.com/account/"
                    target="_blank"
                    class="btn btn-danger"
                  >
                    <img src="@/assets/images/coin.png" class="img-coin" />
                    Claim Now
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "wallet",
  data: () => ({
    walletName: "",
    email: "",
    publicKey: "",
    walletAddress: "",
    privateKey: "",
    pendingClaims: [],
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getClaimData();
    }
  },
  methods: {
    getClaimData() {
      let loader = this.$loading.show();
      // GET request using axios with async/await
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/claimmable-balance/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.pendingClaims = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          $("#tblPendingClaims").DataTable();
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
#tblPendingClaims {
  width: 100%;
}
#tblPendingClaims td {
  width: 20%;
}
.img-coin {
  width: 25px;
}
</style>
