<template>
  <div>
    <DashboardLayout> Referrer ID: {{ referralId }} </DashboardLayout>
  </div>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//import { Auth } from "aws-amplify";

export default {
  name: "profile",
  data() {
    return {
      user: {},
      referralId: "",
      email: "",
    };
  },
  components: {
    DashboardLayout,
  },
  mounted() {
    console.log("came");
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
    }

    if (localStorage.refId === undefined) {
      this.$router.push("/profile");
    } else {
      this.referralId = localStorage.refId;
      this.updateReferral();
    }
  },
  methods: {
    updateReferral: function() {
      let loader = this.$loading.show();

      // decrypt
      const refEmail = window.atob(this.referralId);
      var today = new Date();

      // POST request using axios with set headers
      const dataBody = {
        registerDate: today.toLocaleDateString("en-US"),
        email: this.email,
        referrerEmail: refEmail,
      };
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // using JSON.stringify( to convert dataBody object to json
      axios
        .post(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/update-referral",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          localStorage.removeItem("refId");
          this.$router.push("/profile");
        })
        .catch((error) => {
          //console.log(error);
          this.$router.push("/profile");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
