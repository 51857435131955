<template>
  <DashboardLayout>
    <div class="container">
      <div
        class="list-group list-group-horizontal-xl"
        id="list-tab"
        role="tablist"
      >
        <a
          class="list-group-item list-group-item-action active"
          href="#facebookTab"
          data-toggle="tab"
        >
          Facebook
        </a>
        <a
          class="list-group-item list-group-item-action"
          href="#twitterTab"
          data-toggle="tab"
        >
          Twitter
        </a>
        <a
          class="list-group-item list-group-item-action"
          href="#telegramTab"
          data-toggle="tab"
        >
          Telegram/WhatsApp
        </a>
      </div>

      <hr />

      <div class="tab-content clearfix" id="nav-tabContent">
        <!-- facebook -->
        <div class="tab-pane fade active show" id="facebookTab">
          <div class="row">
            <div
              class="col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-2"
              v-for="(post, index) in facebookPosts"
              :key="`fruit-${index}`"
            >
              <div class="card border-warning mb-3">
                <div class="card-body">
                  <div :id="'fb' + index">
                    {{ post }}
                    <br />
                    <br />
                    Join Now {{ refUrl }}
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    class="btn btn-sm btn-light postCopyDiv"
                    :data-id="index"
                    data-type="fb"
                  >
                    <i class="fas fa-copy"></i> Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Twitter -->
        <div class="tab-pane fade" id="twitterTab">
          <div class="row">
            <div
              class="col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-2"
              v-for="(post, index) in twitterPosts"
              :key="`fruit-${index}`"
            >
              <div class="card border-warning mb-3">
                <div class="card-body">
                  <div :id="'twitter' + index">
                    {{ post }}
                    <br />
                    <br />
                    Join Now {{ refUrl }}
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    class="btn btn-sm btn-light postCopyDiv"
                    :data-id="index"
                    data-type="twitter"
                  >
                    <i class="fas fa-copy"></i> Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- WhatApp/Telegram -->
        <div class="tab-pane fade" id="telegramTab">
          <div class="row">
            <div
              class="col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-2"
              v-for="(post, index) in waTelegramPosts"
              :key="`fruit-${index}`"
            >
              <div class="card border-warning mb-3">
                <div class="card-body">
                  <div :id="'whatsApp' + index">
                    {{ post }}
                    <br />
                    <br />
                    Join Now {{ refUrl }}
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    class="btn btn-sm btn-light postCopyDiv"
                    :data-id="index"
                    data-type="whatsApp"
                  >
                    <i class="fas fa-copy"></i> Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import $ from "jquery";

export default {
  name: "wallet",
  data: () => ({
    email: "",
    data: "",
    refUrl: "",
    facebookPosts: [
      "First Crypto utility token created and dedicated for love of Tamil Language.",
      "Empowering Tamil Language and Tamil Tech startups to grow further than they could alone.",
      "The TAMIL Token is the world's first Blockchain based technology dedicated for the preservation and love of the world's oldest living classical language.",
      "Tamil Token helps to digitilize and decentralize Tamil Language assets. It pays content publishers for their content, node operators and validators for enabling and powering decentralized network.",
      "Our current mission is to build , Support and empower World Wide Tamil Community and Tamil Language through Crypto Token Ecosystem to fulfill the basic needs of each Tamil member.",
      "Ready to get involved in innovating and decentralizing Tamil language? Join TAMIL Token movement.",
    ],
    twitterPosts: [
      "First Crypto utility token created and dedicated for love of Tamil Language.",
      "Empowering Tamil Language and Tamil Tech startups to grow further than they could alone.",
      "The TAMIL Token is the world's first Blockchain based technology dedicated for the preservation and love of the world's oldest living classical language.",
      "Tamil Token helps to digitilize and decentralize Tamil Language assets. It pays content publishers for their content, node operators and validators for enabling and powering decentralized network.",
      "Our current mission is to build , Support and empower World Wide Tamil Community and Tamil Language through Crypto Token Ecosystem to fulfill the basic needs of each Tamil member.",
      "Ready to get involved in innovating and decentralizing Tamil language? Join TAMIL Token movement.",
    ],
    waTelegramPosts: [
      "First Crypto utility token created and dedicated for love of Tamil Language.",
      "Empowering Tamil Language and Tamil Tech startups to grow further than they could alone.",
      "The TAMIL Token is the world's first Blockchain based technology dedicated for the preservation and love of the world's oldest living classical language.",
      "Tamil Token helps to digitilize and decentralize Tamil Language assets. It pays content publishers for their content, node operators and validators for enabling and powering decentralized network.",
      "Our current mission is to build , Support and empower World Wide Tamil Community and Tamil Language through Crypto Token Ecosystem to fulfill the basic needs of each Tamil member.",
      "Ready to get involved in innovating and decentralizing Tamil language? Join TAMIL Token movement.",
    ],

    referralLink: "myreflInk",
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    this.email = this.$store.getters.getUserEmail;
    // to decrypt window.atob( encodedString );
    this.refUrl = "https://app.TamilData.org/ref/" + window.btoa(this.email);
  },
  methods: {
    copyTestingCode() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        alert("Testing code was copied " + msg);
      } catch (err) {
        alert("Oops, unable to copy");
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};

//Copy to cliboard
$("body").on("click", ".postCopyDiv", function() {
  console.log("copying");
  var type = $(this).attr("data-type");
  var id = $(this).attr("data-id");
  $("#" + type + id).select();

  var range = document.createRange();
  range.selectNode(document.getElementById(type + id));
  window.getSelection().removeAllRanges(); // clear current selection
  window.getSelection().addRange(range); // to select text
  document.execCommand("copy");
  //window.getSelection().removeAllRanges(); // to deselect
});
//Copy to cliboard
</script>
<style scoped>
.list-group-item.active {
  color: #fff;
  background-color: #d50000;
  border-color: #d50000;
}
.postCopyDiv {
  cursor: pointer;
}

.card {
  height: 100%;
}
</style>
