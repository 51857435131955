<template>
  <DashboardLayout>
    <div class="container">
      <form>
        <div class="mb-3">
          <p>
            உங்கள் பரிந்துரையால் வாங்கப்பட்ட முதல் தமிழ் மெய் நிகர் நாணயத்தின்
            பரிவர்த்தனையில் 10% விழுக்காடு தமிழ் மெய் நிகர் நாணயம் உங்கள்
            சேமிப்பானுக்கு (Wallet) அனுப்பப்படும்.
          </p>

          <p>
            For the first purchase of TAMIl Token made by your referral, You
            will receive 10% of the TAMIL Token purchased.
          </p>

          <p><strong>உங்கள் பரிந்துரை இணைப்பு (Your Referral Link)</strong></p>

          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="referralLink"
              v-model="refUrl"
              readonly
            />
            <a class="input-group-text" id="myRefCopyBtn">Copy</a>
          </div>
        </div>
      </form>

      <div class="table-responsive">
        <table class="table table-hover">
          <thead class="thead-dark">
            <tr>
              <th>Id</th>
              <th>Email (இணைந்தவரின் மின்னஞ்சல்)</th>
              <th>Register Date</th>
              <th>Reward</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="referral in referrals" :key="referral.referrerEmail">
              <td>{{ referral.id.split("-")[0] }}</td>
              <td>{{ referral.email }}</td>
              <td>{{ referral.registerDate }}</td>
              <td>0 <img src="@/assets/logo.png" alt="TAMIL" width="40" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";
import { Auth } from "aws-amplify";
import $ from "jquery";

export default {
  name: "MyReferrals",
  data() {
    return {
      user: {},
      email: "",
      refUrl: "",
      referrals: [],
      fullPage: false,
    };
  },
  components: {
    DashboardLayout,
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getRefferals();
    }
  },
  methods: {
    getRefferals() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // GET request using axios with async/await
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/update-referral/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          this.referrals = response.data;
          //console.log(response);
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
      // to decrypt window.atob( encodedString );
      this.refUrl =
        "https://app.TamilData.org/ref/" + window.btoa(this.email);
    },
  },
};

$("body").on("click", "#myRefCopyBtn", function() {
  console.log("copy");
  $("#referralLink").select();
  document.execCommand("copy");
});
</script>

<style scoped>
.input-group-text {
  background-color: rgb(179, 194, 194);
  cursor: pointer;
  text-decoration: none;
}
</style>
