<template>
  <DashboardLayout>
    <div class="container">
      <!-- Cards Row-->
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 pb-3">
          <div class="card dashboard-card border-secondary mb-3">
            <img
              src="../assets/images/validator2.jpg"
              class="card-img-top"
              alt="#"
              style="height: 200px"
            />
            <div class="card-body text-secondary">
              <p class="card-text">Validator</p>

              <router-link class="btn btn-warning" to="/validate-assets"
                >Manage</router-link
              >
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 pb-3">
          <div class="card dashboard-card border-secondary mb-3">
            <img
              src="../assets/images/dashboard/socialmedia.jpg"
              class="card-img-top"
              alt="#"
              style="height: 200px"
            />
            <div class="card-body text-secondary">
              <p class="card-text">Social Media Posts</p>

              <router-link class="btn btn-warning" to="/social-posting"
                >Share Now</router-link
              >
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 pb-3">
          <div class="card dashboard-card border-secondary mb-3">
            <img
              src="../assets/images/dashboard/whitepaper.png?t=2"
              class="card-img-top"
              alt="#"
              style="height: 200px"
            />
            <div class="card-body text-secondary">
              <p class="card-text">White Paper</p>
              <a href="/whitepaper.pdf" target="_blank" class="btn btn-warning">
                Read
              </a>
            </div>
          </div>
        </div>
        <!--
        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 pb-3">
          <div class="card border-secondary mb-3">
            <img
              src="../assets/images/dashboard/voting.png"
              class="card-img-top"
              alt="#"
              style="height: 200px"
            />
            <div class="card-body text-secondary">
              <strong><p class="card-title">செயல்திட்ட வாக்களிப்பு</p></strong>
              <p class="card-text">Project Voting</p>
              <router-link class="btn btn-warning" to="/community-projects">
                Vote Now
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 pb-3">
          <div class="card border-secondary mb-3">
            <img
              src="../assets/images/dashboard/project-update.png"
              class="card-img-top"
              alt="#"
              style="height: 200px"
            />
            <div class="card-body text-secondary">
              <strong><p class="card-title">செயல்திட்ட அறிக்கை</p></strong>
              <p class="card-text">Project Updates</p>
              <router-link class="btn btn-warning" to="/my-projects">
                Status
              </router-link>
            </div>
          </div>
        </div>
-->
        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 pb-3">
          <div class="card dashboard-card border-secondary mb-3">
            <img
              src="../assets/images/dashboard/dex-storage-home.jpg"
              class="card-img-top"
              alt="#"
              style="height: 200px"
            />
            <div class="card-body text-secondary">
              <p class="card-text">Decentralization of Tamil digital assets</p>
              <router-link class="btn btn-warning" to="/download-node">
                Join Now
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->

      <!--My Assests-->
      <div class="card mt-2">
        <div class="card-body">
          <h3>My Uploads</h3>
          <div class="table-responsive">
            <table class="table table-hovarable" id="tblValidateAssetes">
              <thead>
                <tr>
                  <th>Uploaded Date</th>
                  <th>File ID</th>
                  <th>Category</th>
                  <th>Sub Category</th>
                  <th>Files</th>
                  <th>Approve</th>
                  <th>Reject</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="asset in assets" :key="asset.id">
                  <td>{{ asset.registerDate }}</td>
                  <td>{{ asset.cid }}</td>
                  <td>{{ asset.mainCategory }}</td>
                  <td>{{ asset.subCategory }}</td>
                  <td>
                    <a
                      class="btn btn-secondary btn-sm"
                      :href="
                        'https://s3.ap-southeast-1.amazonaws.com/static.TamilData.org/' +
                          asset.cid +
                          '.pdf'
                      "
                      target="_blank"
                    >
                      View File
                    </a>
                  </td>
                  <td>
                    <span class="badge badge-success"
                      >{{ asset.approveCount }} Approves</span
                    >
                  </td>
                  <td>
                    <span class="badge badge-danger"
                      >{{ asset.rejectCount }} Rejects</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!--My Assests-->
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "Dashboard",
  components: {
    DashboardLayout,
  },
  data() {
    return {
      loading: false,
      assets: [],
      ipfsData: [],
    };
  },
  beforeMount() {
    this.email = localStorage.email;

    this.getProfileInfo();
  },
  mounted() {
    this.getCategoryData();
    this.getValidateAssetes();
  },
  methods: {
    getValidateAssetes() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/assets/by-user/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          const ipfsData = JSON.parse(localStorage.getItem("ipfsData"));
          const assests = response.data;
          const finalAssests = [];

          $.each(assests, function(key, value) {
            var categoryId = value.catId;
            var subCategoryId = value.subCatId;

            const category = getCategoryName(
              ipfsData,
              categoryId,
              subCategoryId
            );

            value["mainCategory"] = category["mainCategory"];
            value["subCategory"] = category["subCategory"];

            finalAssests.push(value);
          });

          this.assets = finalAssests;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          $("#tblValidateAssetes").DataTable();
          loader.hide();
        });
    },
    getCategoryData() {
      $.getJSON("ipfs.json", function(json) {
        localStorage.setItem("ipfsData", JSON.stringify(json));
      });
    },
    getProfileInfo() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/update-profile/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          const profiles = response.data;

          if (profiles.length == 0) {
            this.$router.push("/profile");
          }
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};

function getCategoryName(jsonData, mainCategoryId, subCategoryId) {
  let mainCategoryText = "";
  let subCategoryText = "";
  for (let mainCategory of jsonData) {
    if (mainCategory.id == mainCategoryId) {
      mainCategoryText = mainCategory.text;
      mainCategoryText = mainCategoryText.split("|")[0];

      for (let subCategory of mainCategory.nodes) {
        if (subCategory.id == subCategoryId) {
          subCategoryText = subCategory.text;
          break;
        }
      }

      break;
    }
  }

  return {
    mainCategory: mainCategoryText,
    subCategory: subCategoryText,
  };
}
</script>

<style scoped>
.btn-warning {
  color: #fff;
  background-color: #ff9c01;
}
.dashboard-card {
  height: 100%;
}

#tblValidateAssetes {
  width: 100%;
}
#tblValidateAssetes td {
  vertical-align: middle;
}
</style>
