<template>
  <DashboardLayout>
    <div class="container">
      <div class="row">
        <div
          class="col-sm-12 col-md-4 col-lg-4 m-2"
          v-for="ad in ads"
          :key="ad.id"
        >
          <div class="card border-left-danger">
            <div class="card-body">
              <p class="adUrl">
                <strong>Ad . </strong>
                <span>
                  {{ ad.displayUrl }}
                </span>
              </p>
              <h3 class="titleAds">{{ ad.title }}</h3>
              <p class="adDesc">{{ ad.adDesc }}</p>

              <hr />

              <h5 class="contactAds">Contact Us</h5>
              <p class="coNoAds">
                <i class="fas fa-phone-alt"></i> {{ ad.contactNo }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

export default {
  name: "data-dex",
  data() {
    return {
      email: "",
      ads: [],
    };
  },
  components: {
    DashboardLayout,
  },
  mounted() {
    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getAdInfo();
    }
  },
  methods: {
    getAdInfo() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/ads/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.ads = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.card-body {
  text-align: left;
  width: 100%;
}
.adUrl {
  color: rgb(82, 79, 79);
}
.titleAds {
  color: blue;
}
.adDesc {
  text-align: justify;
}
.contactAds {
  color: blue;
}
.coNoAds {
  color: rgb(82, 79, 79);
}
</style>
