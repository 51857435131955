import { createStore } from 'vuex'

export default createStore({
  state: {
    token:  '',
    email: null
  },
  getters: {

    getAccessToken(state){
      
      return state.token
    },


    getUserEmail(state){
      
      return state.email
    }

    
  
  },

  mutations: {  // = setting and updating the state // mutation take (state , user define payload)
    SET_ACCESSS_TOKEN(state, accessToken) {
      state.accessToken = accessToken
    },

    SET_EMAIL(state,email){
      state.email=email
    },
    
    
  },
  actions: {
    
  },
  modules: {
  }
})
