<template>
  <DashboardLayout>
    <div class="row">
      <!-- Col 1 -->
      <div class="col-sm-12 col-md-6 mb-2">
        <div class="card shadow">
          <div class="card-header">
            <strong>New Social Posts</strong>
          </div>
          <div class="card-body text-left">
            <div class="form-group mb-3">
              <label for="Target Social Media">Target Social Media</label>
              <select
                class="form-select"
                v-model="newSocialPost.targetSocialMedia"
              >
                <option value="0" hidden selected>
                  Choose Target Social Media
                </option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Tik Tok">Tik Tok</option>
                <option value="Reddit">Reddit</option>
                <option value="Artical">Artical</option>
                <option value="Telegram">Telegram</option>
              </select>
            </div>

            <div class="form-group mb-3">
              <label for="Body">Body</label>
              <textarea
                class="form-control"
                v-model="newSocialPost.body"
                placeholder="Enter Your Social Media Post Body"
                rows="10"
              ></textarea>
            </div>

            <div class="mb-3">
              <button class="btn btn-danger btn-block" @click="postSocialPost">
                Post
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Col 1 -->

      <!-- Col 2 -->
      <div class="col-sm-12 col-md-6 mb-2">
        <div class="card shadow">
          <div class="card-header">
            <strong>My Social Posts</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div
                v-for="(post, index) in socialPosts"
                :key="index"
                class="col-12"
              >
                <div class="card mb-3">
                  <div class="card-body">
                    <span>Target Social Media</span>
                    <p class="text-bold">
                      <span v-html="socialIcons[post.targetSocialMedia]"></span>
                      {{ post.targetSocialMedia }}
                    </p>
                    <hr />
                    <p class="card-text" :id="'socialPost' + index">
                      {{ post.body }}
                    </p>
                  </div>
                  <div
                    class="card-footer text-center copy-social-post"
                    @click="copyPost(index)"
                  >
                    <i class="fas fa-copy"></i> Copy
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Col 2 -->
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import $ from "jquery";

export default {
  name: "my-projects",
  data() {
    return {
      email: "",
      newSocialPost: {
        targetSocialMedia: "",
        body: "",
      },
      socialPosts: [],
      socialIcons: {
        Facebook: '<i class="fab fa-facebook-square"></i>',
        Instagram: '<i class="fab fa-instagram-square"></i>',
        "Tik Tok": '<i class="fab fa-tiktok"></i>',
        Reddit: '<i class="fab fa-reddit-square"></i>',
        Artical: '<i class="fas fa-newspaper"></i>',
        Telegram: '<i class="fab fa-telegram"></i>',
      },
    };
  },
  mounted() {
    this.email = localStorage.email;

    this.getAllPosts();
  },
  components: {
    DashboardLayout,
  },
  methods: {
    postSocialPost() {
      const socialPostData = this.newSocialPost;

      //Validation
      if (socialPostData.targetSocialMedia == "") {
        Swal.fire({
          title: "Warning",
          text: "Please Select Social Media Target",
          icon: "error",
        });

        return false;
      }

      if (socialPostData.body == "") {
        Swal.fire({
          title: "Warning",
          text: "Body is must needed.",
          icon: "error",
        });

        return false;
      }
      //Validation

      let loader = this.$loading.show();

      var today = new Date();
      socialPostData.registerDate = today.toLocaleDateString("en-US");
      socialPostData.email = this.email;

      const dataBody = socialPostData;
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .post(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/social",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);

          Swal.fire({
            title: "Success",
            icon: "success",
          }).then((result) => {
            let loader = this.$loading.show();

            location.reload();
          });
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getAllPosts() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/social/?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          this.socialPosts = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    copyPost(index) {
      $("#socialPost" + index).select();

      var range = document.createRange();
      range.selectNode(document.getElementById("socialPost" + index));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");

      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style scoped>
.text-bold {
  font-weight: bold;
}

.copy-social-post {
  cursor: pointer;
}
</style>
