<template>
  <DashboardLayout>
    <div class="container">
      <div v-if="!image">
        <h2>Select an image</h2>
        <input class="form-control" type="file" @change="onFileChange" />
      </div>
      <div v-else>
        <div class="card">
          <div class="card-body">
            <img :src="image" width="300" class="img-thumbnail" />
          </div>
          <div class="card-footer">
            <button
              class="btn btn-danger"
              v-if="!uploadURL"
              @click="removeImage"
            >
              Remove image
            </button>
            <button
              class="btn btn-warning"
              v-if="!uploadURL"
              @click="uploadImage"
            >
              Upload image
            </button>
          </div>
        </div>
      </div>

      <!--Success Msg-->
      <div v-show="updateMsg" class="mb-3 mt-2">
        <div class="alert alert-success fade show" role="alert">
          <i class="fas fa-check-circle"></i> &nbsp;
          <strong>வெற்றி!</strong> உங்கள் படம் பதிவேற்றப்பட்டது.

          <br />
          <br />
          <i class="fas fa-check-circle"></i> &nbsp;
          <strong>Success!</strong> your image has been uploaded.
          <br />
          <button
            class="btn btn-sm btn-warning mt-4"
            @click="this.$router.go()"
          >
            Click to upload another image
          </button>
        </div>
      </div>
      <!--Success Msg-->

      <div class="card mt-3" v-show="viewImages">
        <div class="card-header">Uploaded Images</div>
        <div class="card-body">
          <div class="row">
            <div
              v-for="image in filterImages"
              :key="image.id"
              class="col-sm-12 col-md-4 col-lg-4 col-xl-4"
            >
              <div class="card bg-light card-border mb-2 card-img">
                <div class="card-body">
                  <img :src="image.path" class="img-thumbnail" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//import { Auth } from "aws-amplify";

const MAX_IMAGE_SIZE = 1000000;

/* ENTER YOUR ENDPOINT HERE
   FILES UPLOADED TO MY ENDPOINT ARE AUTOMATICALLY DELETED EVERY FEW HOURS */

const API_ENDPOINT =
  "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/upload-media";

export default {
  name: "upload-media",
  props: {
    email: String,
    uploadFor: String,
  },
  data() {
    return {
      accessToken: "",
      email: "",
      updateMsg: false,
      image: "",
      uploadURL: "",
      mediaId: "8080",
      mediaType: "media",
      images: [],
      viewImages: false,
    };
  },
  computed: {
    filterImages() {
      return this.images.filter(
        (image) =>
          image.mediaType.includes(this.mediaType) &&
          image.mediaId.includes(this.mediaId)
      );
    },
  },
  mounted() {
    var query = this.$route.query;
    if (Object.keys(query).length > 0) {
      //console.log(query);
      this.mediaId = query.mediaId;
      this.mediaType = query.mediaType;
    }

    if (localStorage.email === undefined) {
      alert("not sign in");
    } else {
      this.email = localStorage.email;
      this.getAllImages();
    }
  },
  components: {
    DashboardLayout,
  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let loader = this.$loading.show();
      // var image = new Image()
      let reader = new FileReader();
      reader.onload = (e) => {
        console.log("length: ", e.target.result.includes("data:image/jpeg"));
        if (!e.target.result.includes("data:image/jpeg")) {
          return alert("Wrong file type - JPG only.");
        }
        if (e.target.result.length > MAX_IMAGE_SIZE) {
          return alert("Image is loo large - 1Mb maximum");
        }
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
      loader.hide();
    },
    removeImage: function(e) {
      console.log("Remove clicked");
      this.image = "";
    },
    uploadImage: async function(e) {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      // Get the presigned URL
      const response = await axios({
        method: "GET",
        url: API_ENDPOINT,
        headers: headers,
      });
      console.log("Response: ", response.data);

      let binary = atob(this.image.split(",")[1]);
      let array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      let blobData = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
      console.log("Uploading to: ", response.data.uploadURL);
      const result = await fetch(response.data.uploadURL, {
        method: "PUT",
        body: blobData,
      });
      console.log("Result: ", result);

      if (result.status == 200) {
        this.uploadImageData(response.data.photoFilename);
        ////console.log(response.data.photoFilename);
      }
      // Final URL for the user doesn't need the query string params
      this.uploadURL = response.data.uploadURL.split("?")[0];

      loader.hide();
    },
    uploadImageData(fileName) {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      var today = new Date();
      var path =
        "https://s3.ap-southeast-1.amazonaws.com/static.TamilData.org/" +
        fileName;
      const dataBody = {
        registerDate: today.toLocaleDateString("en-US"),
        email: this.email,
        mediaType: this.mediaType,
        fileName: fileName,
        mediaId: this.mediaId,
        path: path,
      };
      //console.log(dataBody);
      // using JSON.stringify( to convert dataBody object to json
      axios
        .post(
          " https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/upload-media",
          dataBody,
          {
            headers: headers,
          }
        )
        .then((responseData) => {
          //console.log(responseData);
          this.updateMsg = true;
        })
        .catch((error) => {
          //console.log(error.response);
          this.updateMsg = false;
        })
        .finally(() => {
          this.getAllImages();
          loader.hide();
        });
    },
    getAllImages() {
      let loader = this.$loading.show();
      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      // GET request using axios with async/await
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/upload-media/uploads?email=" +
            this.email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          this.images = response.data;
          //console.log(response.data);
          if (response.data.length > 0) {
            this.viewImages = true;
          } else {
            this.viewImages = false;
          }
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.card-img {
  height: 100%;
}
</style>
