<template>
  <DashboardLayout>
    <div class="container">
      <div class="row">
        <!-- Windows Download -->
        <div class="col-md-6 p-4">
          <div class="card text-center">
            <div class="card-body">
              <img
                src="@/assets/images/windows-logo.png"
                alt="Windows Logo"
                class="system-logo"
              />
              <h2 class="my-3 word-break">Download for Windows</h2>
              <a
                href="https://s3.ap-southeast-1.amazonaws.com/static.TamilData.org/downloads/Tamil Data Decentralization-app-win32-x64.zip"
                class="btn btn-primary btn-block"
                target="_blank"
              >
                Download Now
              </a>
            </div>
          </div>
        </div>

        <!-- macOS Download -->
        <div class="col-md-6 p-4">
          <div class="card text-center">
            <div class="card-body">
              <img
                src="@/assets/images/mac-os-logo.png"
                alt="macOS Logo"
                class="system-logo"
              />
              <h2 class="mb-3 word-break">Download for macOS</h2>
              <a href="#" class="btn btn-primary btn-block">Download Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

export default {
  name: "wallet",
  data: () => ({
    email: "",
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    this.email = localStorage.email;
  },
};
</script>

<style scoped>
.card {
  border: none;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  height: 100%;
}

.card:hover {
  transform: translateY(-10px);
}

.card img {
  border-radius: 15px 15px 0 0;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.system-logo {
  width: 100%;
  height: auto;
}

.text-center {
  text-align: center !important;
  align-items: center;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
</style>
