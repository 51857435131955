<template>
  <DashboardLayout>
    <div class="container">
      <h2 class="mb-3">Showing list of all user accounts</h2>
      <div class="card">
        <div class="card-body table-responsive">
          <table class="table table-hovarable" id="tblAccounts">
            <thead>
              <tr>
                <th>Register Date</th>
                <th>Account Name</th>
                <th>Email</th>
                <th>Country</th>
                <th>Wallet Status</th>
                <th>XLM</th>
                <th>Tamil Crypto</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account, index) in accounts" :key="index">
                <td>{{ account.registerDate }}</td>
                <td>{{ account.fullName }}</td>
                <td>{{ account.email }}</td>
                <td>{{ account.countryBirth }}</td>
                <td :id="'walletStatus' + index"></td>
                <td :id="'xlmBalance' + index"></td>
                <td :id="'tamilBalance' + index"></td>
                <td>
                  <button
                    class="btn btn-success btn-block btn-sm"
                    @click="getWalletInfo(index, account.email)"
                  >
                    Show Balance
                  </button>
                  <button
                    class="btn btn-secondary btn-block btn-sm"
                    @click="nodeStatus(account.email)"
                  >
                    Node Status
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import axios from "axios";

//Swal Alerts
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "accounts",
  data: () => ({
    email: "",
    accounts: [],
  }),
  components: {
    DashboardLayout,
  },
  mounted() {
    this.email = localStorage.email;

    this.getAllAccounts();
  },
  methods: {
    getAllAccounts() {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/users/?email=all",
          {
            headers: headers,
          }
        )
        .then((response) => {
          ////console.log(response);
          this.accounts = response.data;
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          $("#tblAccounts").DataTable();
          loader.hide();
        });
    },
    getWalletInfo(index, accountEmail) {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/create-wallet/?email=" +
            accountEmail,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          const wallets = response.data;

          if (wallets.length > 0) {
            const walletAddress = wallets[0].publicKey;
            var activationStatus = wallets[0].activationStatus;
            var walletStatus = wallets[0].walletStatus;

            if (walletStatus == "approved") {
              if (activationStatus == "pending") {
                $("#walletStatus" + index).html(
                  "<span class='badge badge-warning text-white'>Wallet is not Activated</span>"
                );
              } else {
                $("#walletStatus" + index).html(
                  "<span class='badge badge-success'>Wallet is Actived</span>"
                );
              }
            } else {
              $("#walletStatus" + index).html(
                "<span class='badge badge-danger'>Wallet is not Approved</span>"
              );
            }

            var trimWalletAddress = trimTextAndAddEllipsis(walletAddress, 15);
            var accountLink =
              "https://stellar.expert/explorer/public/account/" + walletAddress;

            $("#walletStatus" + index).append(
              `<br><a href='${accountLink}' target='_blank'>${trimWalletAddress}</a>`
            );

            this.showBalance(index, walletAddress);
          } else {
            $("#walletStatus" + index).html(
              "<span class='badge badge-danger'>Wallet is not found</span>"
            );
          }
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    showBalance(index, walletAddress) {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      axios
        .get("https://horizon.stellar.org/accounts/" + walletAddress, {
          headers: headers,
        })
        .then((response) => {
          const balances = response.data.balances;
          //console.log(balances);

          let nativeBalance = null;
          let TamilBalance = null;

          balances.forEach((item) => {
            if (item.asset_type === "native") {
              nativeBalance = item.balance;
            }

            if (item.asset_code === "TAMIL") {
              TamilBalance = item.balance;
            }
          });

          $("#xlmBalance" + index).html(parseFloat(nativeBalance).toFixed(2));
          $("#tamilBalance" + index).html(parseFloat(TamilBalance).toFixed(2));
        })
        .catch(function(error) {
          //console.log(error);
          Swal.fire({
            title: "Error",
            text: "Balance Getting Failed.",
            icon: "error",
          });
        })
        .finally(() => {
          loader.hide();
        });
    },
    nodeStatus(email) {
      let loader = this.$loading.show();

      const headers = {
        Authorization: "Bearer " + localStorage.token,
      };

      //email = "kavashgar@gmail.com";
      axios
        .get(
          "https://58p9hgeo1e.execute-api.ap-southeast-1.amazonaws.com/TamilData.org-prod/node-stats/?email=" +
            email,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log(response);
          const nodeData = response.data;

          if (nodeData.length > 0) {
            var nodeStatusText = "<h2>Node Information</h2>";

            nodeData.forEach((node, index) => {
              var fileCount = node.numFiles;
              var registerDate = node.registerDate;
              var totalFileSize = bytesToMegabytes(node.totalFileSize);
              var totalUpTime = minutesToHours(node.totalUpTime);
              index = index + 1;

              nodeStatusText += `<hr>
                                  <p><strong>Register Date:</strong> ${registerDate}</p>
                                  <p><strong>Number of Files:</strong> ${fileCount}</p>
                                  <p><strong>Total File Size:</strong> ${totalFileSize} MB</p>
                                  <p><strong>Total Up Time:</strong> ${totalUpTime}</p>
                                    `;
            });

            Swal.fire({
              html: nodeStatusText,
              icon: "success",
            });
          } else {
            Swal.fire({
              text: "Node Data Not Found",
              icon: "warning",
            });
          }
        })
        .catch(function(error) {
          //console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};

function trimTextAndAddEllipsis(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  } else {
    return text;
  }
}

function bytesToMegabytes(bytes) {
  return (bytes / (1024 * 1024)).toFixed(2);
}

function minutesToHours(minutes) {
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = minutes % 60;

  if (hours > 0) {
    return hours + " Hrs " + remainingMinutes.toFixed() + " Min";
  } else {
    return remainingMinutes.toFixed() + " Min";
  }
}
</script>

<style scoped>
#tblAccounts {
  width: 100%;
}
#tblAccounts td,
th {
  vertical-align: middle;
  text-align: left;
}
</style>
