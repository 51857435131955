<template>
  <div>
    <MarketplaceLayout>
      <br />
      <div class="list-group">
        <router-link
          class="list-group-item list-group-item-action"
          to="/marketplace"
          :class="
            activeClass(
              'marketplace',
              'marketplace-jobsubcategory',
              'marketplace-jobs'
            )
          "
        >
          Jobs
        </router-link>
      </div>
    </MarketplaceLayout>
  </div>
</template>

<script>
import MarketplaceLayout from "../layouts/MarketplaceLayout";

export default {
  name: "MarketplaceMenuDesktop",
  data: () => ({
    data: "",
  }),
  components: {
    MarketplaceLayout,
  },
  methods: {
    activeClass: function (...names) {
      // below solution to apply active class based on name of route , name in touter index.js must match
      for (let name of names) {
        if (name == this.$route.name) return "active";
      }
    },
  },
};
</script>

<style scoped>
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #d50000;
  border-color: #d50000;
}
.list-group-item {
  min-width: 170px;
}
.sign-out-btn {
  background-color: #ff9900;
  color: #fff;
}
</style>
